import { Link } from "react-router-dom";
import styled from "styled-components";

import { Category as CategoryType } from "api/generated/data-contracts";
import { default as TooltipBase } from "components/base/Tooltip";
import { getCategoryProgress } from "components/pages/Dashboard/utils";

import { default as CategoryBase } from "./Category";

const Category = styled(CategoryBase)({});

const Base = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: theme.spacing[4],
}));

const Tooltip = styled(TooltipBase)(({ theme }) => ({
  maxWidth: 160,
  padding: theme.spacing[3],
  textAlign: "center",
}));

export interface Props {
  categories: CategoryType[];
  tweenDuration?: number;
}

const CategoryGrid = ({ categories, tweenDuration = 0 }: Props) => {
  const categoriesProgress = categories.map(getCategoryProgress);

  // This represents the next category that the user can complete.
  // If it is -1, then the quiz is complete.
  const nextCategoryToCompleteIndex = categoriesProgress.findIndex(
    (total) => total !== 1,
  );

  const isQuizComplete = nextCategoryToCompleteIndex === -1;

  const showIntro = categoriesProgress[nextCategoryToCompleteIndex] === 0;

  return (
    <Base>
      {categories.map((category, index) => {
        return (
          <Tooltip
            key={category.key}
            offset={-10}
            anchor={
              !isQuizComplete && index <= nextCategoryToCompleteIndex ? (
                <Link to={`/category/${category.key}`}>
                  <Category
                    tweenDuration={tweenDuration}
                    categoryTheme={category.categoryTheme}
                    title={category.title}
                    percent={categoriesProgress[index]}
                    css={{ opacity: 1.0 }}
                  />
                </Link>
              ) : (
                <Category
                  tweenDuration={tweenDuration}
                  categoryTheme={category.categoryTheme}
                  title={category.title}
                  percent={categoriesProgress[index]}
                  css={isQuizComplete ? { opacity: 1.0 } : { opacity: 0.4 }}
                />
              )
            }
            open={
              showIntro &&
              index === nextCategoryToCompleteIndex &&
              !!category.introText
            }
            isStatic={true}
          >
            {category.introText}
          </Tooltip>
        );
      })}
    </Base>
  );
};

export default CategoryGrid;

import React, { createContext, useContext, useEffect, useState } from "react";

type ContextType = {
  id?: string;
  updateId: (id: string) => void;
  reset: () => void;
};

export const AssessmentProviderContext = createContext<ContextType>({
  id: undefined,
  updateId: () => {},
  reset: () => {},
});

export const useAssessmentContext = () => useContext(AssessmentProviderContext);

const ASSESSMENT_ID_STORAGE_KEY = "lowerAssessmentId";

const AssessmentProvider = ({ children }: { children?: React.ReactNode }) => {
  const [id, setId] = useState<string | undefined>(() => {
    const assessmentId =
      localStorage.getItem(ASSESSMENT_ID_STORAGE_KEY) ?? undefined;
    return assessmentId;
  });
  useEffect(() => {
    if (id) {
      localStorage.setItem(ASSESSMENT_ID_STORAGE_KEY, id);
    }
  }, [id]);

  const handleUpdate = (newId: string) => {
    setId(newId);
  };

  const handleReset = () => {
    setId(undefined);
    localStorage.removeItem(ASSESSMENT_ID_STORAGE_KEY);
  };
  return (
    <AssessmentProviderContext.Provider
      value={{ id: id, updateId: handleUpdate, reset: handleReset }}
    >
      {children}
    </AssessmentProviderContext.Provider>
  );
};

export default AssessmentProvider;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Category, Question, Quiz, ScoreGradeRange } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Quizzes<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name QuizzesCreate
   * @request POST:/quizzes
   * @response `201` `any` Success
   */
  quizzesCreate = (data: Quiz, params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/quizzes`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name CategoriesDelete
   * @request DELETE:/quizzes/{quizKey}/categories/{categoryKey}
   * @response `200` `void` Success
   */
  categoriesDelete = (
    quizKey: string,
    categoryKey: string,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/quizzes/${quizKey}/categories/${categoryKey}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name CategoriesDetail
   * @request GET:/quizzes/{quizKey}/categories/{categoryKey}
   * @response `200` `Category` Success
   */
  categoriesDetail = (
    quizKey: string,
    categoryKey: string,
    params: RequestParams = {},
  ) =>
    this.request<Category, any>({
      path: `/quizzes/${quizKey}/categories/${categoryKey}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name CategoriesUpdate
   * @request PUT:/quizzes/{quizKey}/categories/{categoryKey}
   * @response `200` `any` Success
   */
  categoriesUpdate = (
    quizKey: string,
    categoryKey: string,
    data: Category,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/quizzes/${quizKey}/categories/${categoryKey}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name CategoriesCreate
   * @request POST:/quizzes/{quizKey}/categories
   * @response `201` `any` Success
   */
  categoriesCreate = (
    quizKey: string,
    data: Category,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/quizzes/${quizKey}/categories`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name QuizzesDelete
   * @request DELETE:/quizzes/{quizKey}
   * @response `200` `void` Success
   */
  quizzesDelete = (quizKey: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/quizzes/${quizKey}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name QuizzesUpdate
   * @request PUT:/quizzes/{quizKey}
   * @response `200` `any` Success
   */
  quizzesUpdate = (quizKey: string, data: Quiz, params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/quizzes/${quizKey}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name QuestionsDelete
   * @request DELETE:/quizzes/{quizKey}/questions/{questionKey}
   * @response `200` `void` Success
   */
  questionsDelete = (
    quizKey: string,
    questionKey: string,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/quizzes/${quizKey}/questions/${questionKey}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name QuestionsDetail
   * @request GET:/quizzes/{quizKey}/questions/{questionKey}
   * @response `200` `Question` Success
   */
  questionsDetail = (
    quizKey: string,
    questionKey: string,
    params: RequestParams = {},
  ) =>
    this.request<Question, any>({
      path: `/quizzes/${quizKey}/questions/${questionKey}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name QuestionsUpdate
   * @request PUT:/quizzes/{quizKey}/questions/{questionKey}
   * @response `200` `any` Success
   */
  questionsUpdate = (
    quizKey: string,
    questionKey: string,
    data: Question,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/quizzes/${quizKey}/questions/${questionKey}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name ScoreGradeRangesDetail
   * @request GET:/quizzes/{quizKey}/scoreGradeRanges
   * @response `200` `(ScoreGradeRange)[]` Success
   */
  scoreGradeRangesDetail = (quizKey: string, params: RequestParams = {}) =>
    this.request<ScoreGradeRange[], any>({
      path: `/quizzes/${quizKey}/scoreGradeRanges`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name ScoreGradeDelete
   * @request DELETE:/quizzes/{quizKey}/scoreGrade/{scoreGrade}
   * @response `200` `void` Success
   */
  scoreGradeDelete = (
    quizKey: string,
    scoreGrade: string,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/quizzes/${quizKey}/scoreGrade/${scoreGrade}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name ScoreGradeDetail
   * @request GET:/quizzes/{quizKey}/scoreGrade/{scoreGrade}
   * @response `200` `ScoreGradeRange` Success
   */
  scoreGradeDetail = (
    quizKey: string,
    scoreGrade: string,
    params: RequestParams = {},
  ) =>
    this.request<ScoreGradeRange, any>({
      path: `/quizzes/${quizKey}/scoreGrade/${scoreGrade}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags QuizzesApi
   * @name ScoreGradeUpdate
   * @request PUT:/quizzes/{quizKey}/scoreGrade/{scoreGrade}
   * @response `200` `any` Success
   */
  scoreGradeUpdate = (
    quizKey: string,
    scoreGrade: string,
    data: ScoreGradeRange,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/quizzes/${quizKey}/scoreGrade/${scoreGrade}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}

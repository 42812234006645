import { Assessments } from "./generated/Assessments";
import { QuizSelector } from "./generated/QuizSelector";
import { Quizzes } from "./generated/Quizzes";

type SecurityDataType = { token: string };

const AssessmentsAPI = new Assessments<SecurityDataType>({
  baseURL: process.env.REACT_APP_API_URL_BASE,
  securityWorker: (securityData: SecurityDataType | null) => {
    if (securityData) {
      return {
        headers: {
          ["Authorization"]: securityData.token,
        },
      };
    }
  },
});

const QuizzesAPI = new Quizzes<{}>({
  baseURL: process.env.REACT_APP_API_URL_BASE,
});

const QuizSelectorAPI = new QuizSelector<{}>({
  baseURL: process.env.REACT_APP_API_URL_BASE,
});

export default {
  assessments: AssessmentsAPI,
  quizSelector: QuizSelectorAPI,
  quizzes: QuizzesAPI,
};

interface Props {
  className?: string;
  fill: string;
}

const Indicator = ({ className, fill }: Props) => (
  <div className={className}>
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99958 9.2682C6.55532 10.0377 5.44468 10.0377 5.00042 9.2682L0.937399 2.23083C0.493143 1.46135 1.04846 0.499512 1.93697 0.499512L10.063 0.499512C10.9515 0.499512 11.5069 1.46135 11.0626 2.23083L6.99958 9.2682Z"
        fill={fill}
      />
    </svg>
  </div>
);

export default Indicator;

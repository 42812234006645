import React, { useState } from "react";
import styled from "styled-components";

import { Answer, QuestionMultipleChoice } from "api/generated/data-contracts";
import { Select as SelectBase } from "components/base";
import AdvanceButton from "components/pages/CategoryQuiz/components/AdvanceButton";
import QuestionContainer from "components/pages/CategoryQuiz/components/QuestionContainer";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import QuestionError from "components/pages/CategoryQuiz/components/QuestionError";
import QuestionText from "components/pages/CategoryQuiz/components/QuestionText";
import {
  defaultChoiceKeys,
  mapChoicesToOptions,
} from "components/pages/CategoryQuiz/utils";
import {
  MultipleChoiceAnswerType,
  MultipleChoiceSingleAnswerQuestionType,
} from "types/api-extensions";

const Select = styled(SelectBase)(({ theme }) => ({
  marginTop: 40,
  [theme.media.above.sm]: {
    marginTop: 60,
  },
}));

interface BaseProps {
  onSubmit: () => void;
  className?: string;
  text: string;
  description?: string | null;
  value?: string;
  choices: QuestionMultipleChoice[];
  includeImages?: boolean | null;
  onChange: (value: string) => void;
  error?: string;
  isSubmitting?: boolean;
  footer?: React.ReactNode;
}
/**
 * This Question component breaks some of the conventions in the other
 * Question components. The default export still uses the same `question`
 * `answer` props as the others, but we also expose a version of this
 * component that is not specific to the question object type. This is
 * so it can be reused for the quiz selector question before you get started
 * with the quiz. The quizSelector endpoint return data in a slightly different
 * format, so we abstract away from the Question and Answer types.
 */
export const MultipleChoiceSingleAnswerQuestionBase = ({
  onSubmit,
  className,
  text,
  description,
  value,
  choices,
  onChange,
  error,
  includeImages,
  isSubmitting,
  footer,
}: BaseProps) => {
  return (
    <QuestionContainer>
      <QuestionContent>
        <form
          id="question-form"
          onSubmit={(e) => {
            onSubmit();
            e.preventDefault();
          }}
          className={className}
        >
          <QuestionText html={text} description={description} />
          <Select
            value={value}
            multiple={false}
            options={mapChoicesToOptions(choices, false, !!includeImages)}
            onChange={onChange}
          />
          {error && <QuestionError>{error}</QuestionError>}
        </form>
      </QuestionContent>
      <AdvanceButton
        form="question-form"
        type="submit"
        loading={isSubmitting}
        sticky={!!footer}
      />
      {footer}
    </QuestionContainer>
  );
};

export interface Props {
  question: MultipleChoiceSingleAnswerQuestionType;
  answer?: MultipleChoiceAnswerType;
  onSubmit: (answer: Answer) => void;
  isSubmitting?: boolean;
  className?: string;
  footer?: React.ReactNode;
}

const MultipleChoiceSingleAnswerQuestion = ({
  question,
  answer,
  onSubmit,
  isSubmitting,
  className,
  footer,
}: Props) => {
  const { choices, includeImages } = question.multipleChoiceConfiguration;
  const [error, setError] = useState<string | undefined>();
  const [value, setValue] = useState<string | undefined>(
    answer?.answerMultipleChoice?.[0] ?? defaultChoiceKeys(choices)[0],
  );

  const { text, description } = question;

  const handleSubmit = () => {
    // Check config validations
    // is Required
    if (!value || value.length === 0) {
      setError("Required");
      return;
    }

    onSubmit({ answerMultipleChoice: [value] });
  };

  const handleChange = (value: string) => {
    setValue(value);
  };
  return (
    <MultipleChoiceSingleAnswerQuestionBase
      onSubmit={handleSubmit}
      className={className}
      text={text}
      description={description}
      value={value}
      choices={choices}
      includeImages={includeImages}
      onChange={handleChange}
      error={error}
      isSubmitting={isSubmitting}
      footer={footer}
    />
  );
};

export default MultipleChoiceSingleAnswerQuestion;

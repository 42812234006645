import { useEffect, useState } from "react";
import { usePreviousDistinct } from "react-use";

import { AssessmentDetails } from "api/generated/data-contracts";
import { Transition } from "components/base";
import AssociatedFieldsRichText from "components/partials/AssociatedFieldsRichText";
import { typography } from "utils/style";
import { buildTransition } from "utils/style/transition";

const fadeUpAndFade = (delayMs: number) =>
  buildTransition({
    enter: {
      transition: "opacity 300ms ease, transform 350ms ease",
    },
    leave: {
      transition: "opacity 300ms ease, transform 350ms ease",
      transitionDelay: `${delayMs}ms`,
    },
    enterFrom: {
      opacity: 0,
      transform: `translate3d(0, 16px, 0)`,
    },
    enterTo: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
    leaveFrom: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
    leaveTo: {
      opacity: 0,
    },
  });

export interface Props {
  assessment: AssessmentDetails;
  progress: number;
  delayUpdate?: number;
}

/**
 * Render correct menu title from the backend depending on the current
 * progress of the user. This component also supports a delayed update
 * transition feature, which is useful when coordinating the updated
 * dashboard animation which occurs after a user completes a category
 * quiz.
 */
const Title = ({
  assessment,
  progress,
  /**
   * The amount of time, in milliseconds, before updating the title.
   */
  delayUpdate = 0,
  ...rest
}: Props) => {
  let title: string;

  if (progress >= 0.25 && progress < 0.75) {
    title = assessment.menuTitleQuizInProgress || "";
  } else if (progress >= 0.75 && progress < 1) {
    title = assessment.menuTitleQuizAlmostComplete || "";
  } else if (progress === 1) {
    title = assessment.menuTitleQuizComplete || "";
  } else {
    title = assessment.menuTitleQuizIntro || "";
  }
  const prevTitle = usePreviousDistinct(title);

  const [renderTitle, setRenderTitle] = useState(title);
  useEffect(() => {
    if (prevTitle) {
      setRenderTitle(prevTitle);
    }
  }, [prevTitle]);

  return (
    <Transition
      show={renderTitle === title}
      afterLeave={() => setRenderTitle(title)}
      css={fadeUpAndFade(delayUpdate)}
    >
      <AssociatedFieldsRichText
        html={renderTitle}
        css={typography({ variant: "heading2" })}
        {...rest}
      />
    </Transition>
  );
};

export default Title;

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import {
  Answer,
  AssessmentDetails,
  CreateAssessmentDetailsCommand,
  PurchaseAssessmentSummary,
  QuizTypeEnum,
  RefinanceAssessmentSummary,
} from "api/generated/data-contracts";
import reportError from "utils/reportError";

import client from "./client";
import transformToAnswerMap from "./utils/transformToAnswerMap";
import updateCachedAssessment from "./utils/updateCachedAssessment";

const cache_keys = {
  ASSESSMENT: "ASSESSMENT",
};

export const useQueryAssessment = <T>(
  id: string = "",
  options?: Omit<
    UseQueryOptions<AssessmentDetails, Error, T>,
    "enabled" | "staleTime"
  >,
) => {
  return useQuery<AssessmentDetails, Error, T>(
    [cache_keys.ASSESSMENT, id],
    async () => {
      const response = await client.assessments.assessmentsDetail(id);
      return response.data;
    },
    {
      ...options,
      enabled: !!id,
      staleTime:
        process.env.REACT_APP_DISABLE_ASSESSMENT_CACHING === "true"
          ? 0
          : // By setting this to Infinity, we are saying that the assessment query should only get re-fetched
            // when we manually invalidate it.
            Infinity,
    },
  );
};

/**
 * Returns a data map of associated fields to answers. Unanswered questions
 * are not defined.
 * ```
 * {
 *   "FirstNameEnum": "Chris",
 *   "LastNameEnum": "Lower",
 * }
 * ```
 */
export const useQueryAssociatedFields = (assessmentId: string = "") =>
  useQueryAssessment(assessmentId, {
    select: transformToAnswerMap,
  });

export const useQueryCategories = (assessmentId: string = "") =>
  useQueryAssessment(assessmentId, { select: (data) => data.categories });

export const useQueryQuizSelector = () => {
  return useQuery(
    "quizSelector",
    async () => await client.quizSelector.quizSelectorList(),
  );
};

export const useScoreGradeRanges = (assessmentId: string) => {
  return useQuery("scoreGradeRanges", async () => {
    const response = await client.assessments.scoreGradeRangesDetail(
      assessmentId,
    );
    return response.data;
  });
};

type AssessmentSummary<T> = T extends "Purchase"
  ? PurchaseAssessmentSummary
  : RefinanceAssessmentSummary;

export function useAssessmentSummary<T extends QuizTypeEnum>(
  assessmentId: string,
  type: T,
): UseQueryResult<AssessmentSummary<T>> {
  return useQuery("assessmentSummary", async () => {
    if (type === "Purchase") {
      const response = await client.assessments.purchaseSummaryDetail(
        assessmentId,
      );

      return response.data;
    } else {
      const response = await client.assessments.refinanceSummaryDetail(
        assessmentId,
      );

      return response.data;
    }
  });
}

// Mutations

export const useMutationAssessment = () => {
  return useMutation(
    ({ quizKey, utmSource, utmMedium }: CreateAssessmentDetailsCommand) =>
      client.assessments.assessmentsCreate({ quizKey, utmSource, utmMedium }),
  );
};

export const useMutationQuestionResponse = (
  assessmentId: string = "",
  categoryKey: string = "",
  questionKey: string = "",
) => {
  const queryClient = useQueryClient();
  const invalidateAssessmentQuery = () =>
    queryClient.invalidateQueries([cache_keys.ASSESSMENT, assessmentId]);
  return useMutation(
    (data: Answer) =>
      client.assessments.questionsAnswerUpdate(assessmentId, questionKey, data),
    {
      onSuccess: (_response, answer) => {
        const cachedAssessment = queryClient.getQueryData<AssessmentDetails>([
          cache_keys.ASSESSMENT,
          assessmentId,
        ]);
        if (
          !cachedAssessment ||
          process.env.REACT_APP_DISABLE_ASSESSMENT_CACHING === "true"
        ) {
          invalidateAssessmentQuery();
          return;
        }
        try {
          const updatedAssessment = updateCachedAssessment(
            cachedAssessment,
            categoryKey,
            questionKey,
            answer,
          );
          queryClient.setQueryData(
            [cache_keys.ASSESSMENT, assessmentId],
            updatedAssessment,
          );
        } catch (e) {
          // cache update did not work. Report error and
          // invalidate assessment query, which has the
          // effect of refetching the assessment with
          // the updated answer. The app should work
          // fine, just more calls to the api.
          if (e instanceof Error) {
            reportError(e.message);
          }

          invalidateAssessmentQuery();
        }
      },
    },
  );
};

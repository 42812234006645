import React from "react";
import styled from "styled-components";

import {
  PurchaseAssessmentSummary,
  QuizTypeEnum,
  RefinanceAssessmentSummary,
  ScoreGradeRange,
} from "api/generated/data-contracts";
import { useAssessmentSummary, useScoreGradeRanges } from "api/hooks";
import {
  GenericError as GenericErrorBase,
  LegalNofication,
  Loader as LoaderBase,
  Score,
  Tooltip,
} from "components/base";
import { Email, Info } from "components/base/Icon";
import { useAssessmentContext } from "providers/AssessmentProvider";
import reportError from "utils/reportError";
import { getScoreGradeRange } from "utils/scoreGradeRange";
import { layout, typography } from "utils/style";

import client from "../../../api/client";
import PurchaseResults from "./PurchaseResults";
import RefinanceResults from "./RefinanceResults";

const ScoreBase = styled.div({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginBottom: 60,
  overflow: "hidden",
});

type SendMyResultsButtonProps = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const SendMyResultsButton = styled.button<SendMyResultsButtonProps>`
  all: unset,
  cursor: pointer,
`;

const Loader = styled(LoaderBase)(layout.flexCenter, layout.fullHeight, {
  margin: "auto",
});

const GenericError = styled(GenericErrorBase)(
  layout.flexCenter,
  layout.fullHeight,
  {
    padding: 0,
    textAlign: "center",
  },
);

interface BaseProps {
  scoreGrades: ScoreGradeRange[];
  type: QuizTypeEnum;
}

interface PurchaseProps extends BaseProps {
  summary: PurchaseAssessmentSummary;
  type: "Purchase";
}

interface RefinanceProps extends BaseProps {
  summary: RefinanceAssessmentSummary;
  type: "Refinance";
}

export type Props = PurchaseProps | RefinanceProps;

export const Results = (props: Props) => {
  const score = props.summary.totalScore ? props.summary.totalScore / 100 : 0;
  const { id: assessmentId } = useAssessmentContext();
  const scoreGradeRange = getScoreGradeRange(score, props.scoreGrades);

  if (score < 0) {
    reportError("Score returned a negative result.");
    return <GenericError />;
  }

  if (!scoreGradeRange) {
    reportError("Error retrieving score grade ranges.");
    return <GenericError />;
  }

  const scoreGradeName = scoreGradeRange.gradeName ?? "";
  const scoreGradeTitle = scoreGradeRange.title ?? "Home Readiness Score";
  const scoreGradeColor = scoreGradeRange.gradeColor ?? "black";

  return (
    <div className={"results-container"}>
      <ScoreBase>
        <Score score={score} scoreGrades={props.scoreGrades} />
        <div
          css={[
            typography({ variant: "heading2" }),
            {
              marginBottom: 8,
              textAlign: "center",
              color: scoreGradeColor,
            },
          ]}
        >
          {scoreGradeName}
        </div>
        <div
          css={[
            { display: "flex", alignItems: "center", marginBottom: 16 },
            typography({ variant: "heading4" }),
          ]}
        >
          {scoreGradeTitle}
          <Tooltip
            anchor={
              // @ts-ignore This works, but TypeScript is not happy.
              <Tooltip.Anchor css={{ marginLeft: 8, marginTop: 4 }}>
                <Info size={20} color="primaryBlue" />
              </Tooltip.Anchor>
            }
          >
            A measurement (out of 100) of how close you are to buy a home within
            your range of preferences.
          </Tooltip>
        </div>
        <div
          css={[
            { maxWidth: 327, opacity: 0.6, textAlign: "center" },
            typography({ variant: "bodyLarge" }),
          ]}
        >
          {scoreGradeRange.description}
        </div>
        <SendMyResultsButton
          onClick={async () =>
            assessmentId &&
            client.assessments.emailOnlySubmissionCreate(assessmentId)
          }
          css={[
            typography({ variant: "callToAction", color: "primaryBlue" }),
            {
              display: "flex",
              alignItems: "center",
              textTransform: "uppercase",
              marginTop: 22,
              cursor: "pointer",
            },
          ]}
        >
          {scoreGradeRange.saveResultsButtonText}
          <Email css={{ marginLeft: 4 }} size={14} />
        </SendMyResultsButton>
      </ScoreBase>
      {props.type === "Purchase" ? (
        <PurchaseResults
          scoreGradeRange={scoreGradeRange}
          summary={props.summary}
        />
      ) : (
        <RefinanceResults summary={props.summary} />
      )}
      <LegalNofication />
    </div>
  );
};

interface ContainerProps {
  quizType: QuizTypeEnum;
}

const ResultsContainer = ({ quizType }: ContainerProps) => {
  const { id: assessmentId } = useAssessmentContext();
  const {
    data: summary,
    error: summaryError,
    status: summaryStatus,
  } = useAssessmentSummary(assessmentId!, quizType);
  const {
    data: scoreGrades,
    error: scoreGradeError,
    status: scoreGradeStatus,
  } = useScoreGradeRanges(assessmentId!);

  if (summaryStatus === "loading" || scoreGradeStatus === "loading") {
    return <Loader />;
  }

  if (
    summaryStatus === "error" ||
    scoreGradeStatus === "error" ||
    !summary ||
    !scoreGrades
  ) {
    if (summaryError) {
      reportError(JSON.stringify(summaryError));
    }

    if (scoreGradeError) {
      reportError(JSON.stringify(scoreGradeError));
    }

    return <GenericError />;
  }

  return (
    <Results type={quizType} scoreGrades={scoreGrades} summary={summary} />
  );
};

export default ResultsContainer;

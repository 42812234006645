import TextAreaAutosizeBase from "react-textarea-autosize";
import styled from "styled-components";

import { input } from "utils/style/input";

const TextAreaAutosize = styled(TextAreaAutosizeBase)(
  input({ variant: "question" }),
  {
    resize: "none",
    flex: 1,
    // needing for ios Chrome
    padding: 0,
  },
);

export default TextAreaAutosize;

import { transparentize } from "polished";

// Primary
const darkBlue = "#001CDB";
const turquoiseBlue = "#4BF4CE";
const stratos = "#00032F";
const mischka = "#D4D4DF";

// Neutral
const black = "#000000";
const codGray = "#1A1A1A";
const white = "#FFFFFF";
const porcelain = "#F6F7F8";
const hawkesBlue = "#E7EAFE";

// Secondary
const green = "#00D200";
const purple = "#5300CC";
const yellow = "#F6BD41";
const blue = "#0064FF";
const pink = "#B51BCD";
const red = "#EB4747";
const apple = "#4CA530";

// Transparencies
const transparentPrimaryBlue = transparentize(0.9, darkBlue);
const transparentGreen = transparentize(0.9, green);
const transparentPurple = transparentize(0.9, purple);
const transparentYellow = transparentize(0.9, yellow);
const transparentBlue = transparentize(0.9, blue);
const transparentPink = transparentize(0.9, pink);
const transparentText = transparentize(0.5, codGray);
const transparentRed = transparentize(0.9, red);

export const colors = {
  primaryBlue: darkBlue,
  accentTeal: turquoiseBlue,
  midnightBlue: stratos,
  lightGrey: mischka,
  black,
  white,
  text: codGray,
  offWhite: porcelain,
  lightBlue: hawkesBlue,
  green,
  darkGreen: apple,
  purple,
  yellow,
  blue,
  pink,
  red,
  transparentPrimaryBlue,
  transparentText,
  transparentGreen,
  transparentPurple,
  transparentYellow,
  transparentBlue,
  transparentPink,
  transparentRed,
} as const;

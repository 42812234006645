import React, { useState } from "react";
import styled from "styled-components";

import { Answer } from "api/generated/data-contracts";
import { Slider as SliderBase } from "components/base";
import AdvanceButton from "components/pages/CategoryQuiz/components/AdvanceButton";
import QuestionContainer from "components/pages/CategoryQuiz/components/QuestionContainer";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import QuestionText from "components/pages/CategoryQuiz/components/QuestionText";
import { SliderAnswerType, SliderQuestionType } from "types/api-extensions";

const Slider = styled(SliderBase)(({ theme }) => ({
  marginTop: 165,
  [theme.media.above.sm]: {
    marginTop: 260,
  },
  [theme.media.above.lg]: {
    marginTop: 140,
  },
}));

export interface Props {
  question: SliderQuestionType;
  answer?: SliderAnswerType;
  onSubmit: (answer: Answer) => void;
  isSubmitting?: boolean;
  className?: string;
  footer?: React.ReactNode;
}

const SliderQuestion = ({
  question,
  answer,
  onSubmit,
  isSubmitting,
  className,
  footer,
}: Props) => {
  const { minAmount, maxAmount, stepSize, unitLabel, defaultValue } =
    question.sliderConfiguration;
  const [value, setValue] = useState<number>(
    answer?.answerSlider ?? defaultValue ?? minAmount,
  );

  const handleSubmit = async () => {
    onSubmit({ answerSlider: value });
  };

  const handleChange = (val: number) => {
    setValue(val);
  };
  return (
    <QuestionContainer>
      <QuestionContent>
        <form
          id="question-form"
          onSubmit={(e) => {
            handleSubmit();
            e.preventDefault();
          }}
          className={className}
        >
          <QuestionText
            html={question.text}
            description={question.description}
          />
          <Slider
            ref={(ref) => {
              // The underlying react slider component has an issue where
              // the thumb does not get rendered in the correctly location if
              // the parent is initially not displayed via css. This is what our
              // transition is doing. This is the current solution that works,
              // but likely calls `handleResize` too much (on dragging). Revisit
              // if the issue causes performance issues.
              // Also: https://github.com/zillow/react-slider/issues/184
              if (ref?.handleResize) {
                ref.handleResize();
              }
            }}
            valueSuffix={unitLabel ?? undefined}
            value={value}
            onChange={handleChange}
            min={minAmount ?? 0}
            max={maxAmount ?? 0}
            step={stepSize ?? undefined}
          />
        </form>
      </QuestionContent>
      <AdvanceButton
        form="question-form"
        type="submit"
        loading={isSubmitting}
        sticky={!!footer}
      />
      {footer}
    </QuestionContainer>
  );
};

export default SliderQuestion;

import { useState } from "react";
import styled from "styled-components";

import { Transition } from "components/base";
import Modal from "components/base/Modal";
import AdvanceButtonBase from "components/pages/CategoryQuiz/components/AdvanceButton";
import Footer from "components/pages/CategoryQuiz/components/Footer";
import AssociatedFieldsRichText from "components/partials/AssociatedFieldsRichText";
import useTimeoutState from "hooks/useTimeoutState";
import { ActionableInterstitialStep } from "types/api-extensions";
import { layout } from "utils/style";
import { fadeUp } from "utils/style/transition";

const Base = styled(Transition)(
  layout.flexVertical,
  layout.fullHeight,
  ({ theme }) => ({
    paddingTop: 80,
    [theme.media.above.sm]: {
      flexDirection: "row",
      padding: 0,
    },
  }),
);

const ContentContainer = styled.div(
  layout.paddingHorizontal(6),
  ({ theme }) => ({
    flex: 1,
    [theme.media.above.sm]: {
      flex: "none",
      paddingLeft: 48,
      paddingTop: 48,
      width: "45%",
    },

    [theme.media.above.lg]: {
      paddingLeft: 80,
      paddingTop: 56,
    },
  }),
);

const AdvanceButton = styled(AdvanceButtonBase)(({ theme }) => ({
  "@media (max-width: 350px)": {
    bottom: 145,
  },
  bottom: 170,
  [theme.media.above.sm]: {
    left: 48,
    bottom: 48,
    right: "inherit",
  },
  [theme.media.above.lg]: {
    left: 90,
    bottom: 100,
    right: "inherit",
  },
}));

export interface Props {
  onDone: () => void;
  /**
   * Fired when the user closes the modal as opposed to clicking the advance button
   */
  onClose?: () => void;
  step: ActionableInterstitialStep;
}

const ActionableInterstitial = ({
  step,
  onClose = () => {},
  onDone,
}: Props) => {
  const [show, setShow] = useState(true);
  const [actionTaken, setActionTaken] = useState<"advance" | "close">(
    "advance",
  );
  const tick = useTimeoutState(0, false, true);
  const handleTransitionLeave = () => {
    if (actionTaken === "advance") {
      onDone();
    } else {
      onClose();
    }
  };

  const handleCloseClick = () => {
    setActionTaken("close");
    setShow(false);
  };
  const handleAdvanceClick = () => {
    setActionTaken("advance");
    setShow(false);
  };
  return (
    <Modal
      isOpen={show && tick}
      onClose={handleCloseClick}
      onCloseFinish={handleTransitionLeave}
    >
      <Base show={true} appear={true}>
        <ContentContainer>
          <Transition.Child css={fadeUp(300, 400, 400)}>
            <AssociatedFieldsRichText
              html={step.interstitialScreen.body || ""}
            />
          </Transition.Child>
        </ContentContainer>
        <Footer
          title={step.footerConfiguration?.title || ""}
          body={step.footerConfiguration?.body || ""}
          animationDelay={400}
        />
        <Transition.Child css={[fadeUp(800, 400, 400)]}>
          <AdvanceButton
            sticky={false}
            onClick={handleAdvanceClick}
            aria-label="Next"
          />
        </Transition.Child>
      </Base>
    </Modal>
  );
};

export default ActionableInterstitial;

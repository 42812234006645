import { layout, typography } from "utils/style";

export interface Props {
  notificationText?: string;
  className?: string;
}

const DEFAULT_MSG =
  "*This quiz is for estimation and illustrative purposes to help you through your purchase or refinance process. The results of this quiz is not a lending commitment from Lower.com.";

const LegalNofication = ({
  className,
  notificationText = DEFAULT_MSG,
}: Props) => (
  <div
    className={className}
    css={[
      layout.paddingVertical(6),
      typography({ color: "text", variant: "body" }),
      {
        opacity: 0.6,
      },
    ]}
  >
    {notificationText}
  </div>
);

export default LegalNofication;

import styled from "styled-components";

import { Answer } from "api/generated/data-contracts";
import { Button } from "components/base";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import QuestionText from "components/pages/CategoryQuiz/components/QuestionText";
import { BooleanAnswerType, BooleanQuestionType } from "types/api-extensions";
import { layout } from "utils/style";

const ButtonGroup = styled.div(
  layout.spacedChildrenVertical(3),
  ({ theme }) => ({
    ...layout.flexVertical,
    ...layout.padding(6)({ theme }),
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    [theme.media.above.sm]: {
      ...layout.padding(0)({ theme }),
      position: "relative",
      marginTop: 75,
      maxWidth: 330,
    },
  }),
);

export interface Props {
  question: BooleanQuestionType;
  answer?: BooleanAnswerType;
  onSubmit: (answer: Answer) => void;
  isSubmitting?: boolean;
  className?: string;
}

/**
 * Questions handle mutations and validations
 * Mutation stores in cache. onSubmit called when API
 * called successfully
 */
const BooleanQuestion = ({ question, onSubmit, className }: Props) => {
  const { truthyOptionText, falsyOptionText } = question.booleanConfiguration;

  const handleSubmit = (value: boolean) => {
    onSubmit({ answerboolean: value });
  };

  return (
    <QuestionContent className={className}>
      <QuestionText html={question.text} description={question.description} />
      <ButtonGroup css={layout.spacedChildrenVertical(3)}>
        <Button onClick={() => handleSubmit(true)}>{truthyOptionText}</Button>
        <Button variant="secondary" onClick={() => handleSubmit(false)}>
          {falsyOptionText}
        </Button>
      </ButtonGroup>
    </QuestionContent>
  );
};

export default BooleanQuestion;

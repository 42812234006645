import { RadioGroup, Switch as SwitchBase } from "@headlessui/react";
import styled from "styled-components";

import { Check } from "components/base/Icon";
import { typography } from "utils/style";
import { VariantPerBreakpoint } from "utils/style/typography";

// We require these components to be Styled Components for styling children elements.
export const RadioGroupOption = styled(RadioGroup.Option)({
  [`&:focus`]: {
    outline: "none",
  },
});

export const Switch = styled(SwitchBase)({
  [`&:focus`]: {
    outline: "none",
  },
});

export interface Option {
  body?: string;
  imgSrc?: string;
  title: string;
  type?: "check" | "radio";
  value: string;
}

export interface Props extends Option {
  checked?: boolean;
}

const Base = styled.div(({ theme }) => ({
  cursor: "pointer",
  marginBottom: theme.spacing[6],
  transition: "opacity 100ms ease-in-out",
}));

const CheckboxContainer = styled.div(({ theme }) => ({
  position: "relative",
  marginRight: theme.spacing[4],
}));

interface CheckboxProps {
  checked?: boolean;
  hasImage?: boolean;
}

const Checkbox = styled.div<CheckboxProps>(({ checked, hasImage, theme }) => ({
  width: 28,
  height: 28,
  backgroundColor: checked ? theme.colors.green : theme.colors.white,
  borderColor: checked ? theme.colors.green : theme.colors.lightGrey,
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: "50%",
  color: theme.colors.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 28,
  minHeight: 28,
  position: hasImage ? "absolute" : "static",
  top: hasImage ? -5 : 0,
  right: hasImage ? -5 : 0,
  transition:
    "background-color 100ms ease-in-out, border-color 100ms ease-in-out, color 100ms ease-in-out",

  [`${RadioGroupOption}:hover &&, ${Switch}:hover &&`]: {
    backgroundColor: checked ? theme.colors.darkGreen : theme.colors.offWhite,
    borderColor: checked ? theme.colors.darkGreen : theme.colors.lightGrey,
    color: checked ? theme.colors.white : theme.colors.offWhite,
  },

  [`${RadioGroupOption}:focus &&, ${Switch}:focus &&`]: {
    borderColor: theme.colors.primaryBlue,
  },
}));

const Circle = styled.div({
  width: 10,
  height: 10,
  borderRadius: 10,
  backgroundColor: "currentColor",
});

const Image = styled.img(({ theme }) => ({
  borderRadius: 16,
  width: 76,
  height: 76,
  objectFit: "cover",

  [theme.media.above.sm]: {
    width: 88,
    height: 88,
  },

  [theme.media.above.md]: {
    borderRadius: 24,
  },
}));

const titleTypography: VariantPerBreakpoint = {
  sm: "heading3",
  md: "heading2",
};

const Title = styled.h2(
  typography({ variant: titleTypography }),
  ({ theme }) => ({
    marginBottom: theme.spacing[2],
    textAlign: "left",
    transition: "color 100ms ease-in-out",

    [`${RadioGroupOption}:focus &&, ${Switch}:focus &&`]: {
      color: theme.colors.primaryBlue,
    },
  }),
);

const Body = styled.p(
  typography({ color: "midnightBlue", variant: "bodyLarge" }),
  {
    textAlign: "left",
    opacity: 0.6,
    transition: "opacity 100ms ease-in-out",

    [`${RadioGroupOption}:hover &&, ${Switch}:hover &&`]: {
      opacity: 1.0,
    },
  },
);

const SelectOption = ({
  body,
  checked = false,
  imgSrc,
  title,
  type,
  ...rest
}: Props) => {
  const hasImage = imgSrc !== undefined && imgSrc !== "";

  return (
    <Base {...rest}>
      <div
        css={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <CheckboxContainer>
          {hasImage && <Image src={imgSrc} alt="" role="presentation" />}
          <Checkbox checked={checked} hasImage={hasImage}>
            {type === "radio" ? <Circle /> : <Check size={18} />}
          </Checkbox>
        </CheckboxContainer>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Title>{title}</Title>
          <Body>{body}</Body>
        </div>
      </div>
    </Base>
  );
};

SelectOption.Checkbox = Checkbox;

export default SelectOption;

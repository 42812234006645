import { forwardRef } from "react";

import { useQueryAssociatedFields } from "api/hooks";
import { RichText } from "components/base";
import { useAssessmentContext } from "providers/AssessmentProvider";

export interface Props {
  html: string;
  className?: string;
}

/**
 * Applies the associated field answers from the assessment as the template values in
 * RichText.
 */
const AssociatedFieldsRichText = forwardRef<HTMLDivElement, Props>(
  ({ html, className }: Props, ref) => {
    const { id: assessmentId } = useAssessmentContext();
    const { data: associatedFields } = useQueryAssociatedFields(assessmentId);
    return (
      <RichText
        ref={ref}
        templateValues={associatedFields}
        html={html}
        className={className}
      />
    );
  },
);

export default AssociatedFieldsRichText;

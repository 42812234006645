import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import Toast, { ToastVariants } from "components/base/Toast";
import Transition from "components/base/Transition";
import { transition } from "utils/style";

interface ToastConfig {
  title: string;
  body: string;
  variant: ToastVariants;

  // How long the Toast will remain on screen in ms.
  // Defaults to 6000ms.
  ttl?: number;
}

type ContextValue = {
  showToast: (config: ToastConfig) => void;
};

const ToastProviderContext = createContext<ContextValue>({
  showToast: () => null,
});

export const useToast = () => useContext(ToastProviderContext);

const ToastContainer = styled.div(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  paddingBottom: theme.spacing[4],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  pointerEvents: "none",
  "& > *": {
    pointerEvents: "all",
    cursor: "pointer",
  },
}));

// The ToastProvider provides a hook to show a toast inside the application.
// Example usage:
// --
// import { useToast } from "providers/ToastProvider";
//
// const MyFuncComponent = () => {
//   const { showToast } = useToast();
//   showToast({ title: "My toast", body: "Toast body", variant: "success", ttl: 6000 })
// }
//
// Accessibility notes:
// Toasts are announced on screen from the `aria-live` prop that the
// ToastContainer has. It is assumed screen reader users will hear the
// announcement and proceed. It is considered best practice not to add
// any actions to a Toast as that can be confusing for screen readers
// and users.
// More information:
// https://www.scottohara.me/blog/2019/07/08/a-toast-to-a11y-toasts.html
const ToastProvider = ({ children }: { children?: React.ReactNode }) => {
  // List of all toasts. We show one toast at a time.
  const [toasts, setToasts] = useState<ToastConfig[]>([]);

  // Controls if the current toast is visible or not.
  const [isShown, setIsShown] = useState<boolean>(false);

  const timeoutRef = useRef<number>();

  const showToast = (config: ToastConfig) => {
    setToasts([...toasts, config]);
  };

  // We only show one toast at a time. Any new toasts will be displayed
  // after the current one expires.
  const currentToast = toasts[0];

  // This effect will show the current toast when it's available, and expire
  // it after a certain amount of time. This will trigger the `Transition`'s
  // `afterLeave` prop, which then removes the toast from the list. This will
  // populate currentToast with the next toast in the list (if there is one).
  useLayoutEffect(() => {
    if (currentToast) {
      setIsShown(true);

      timeoutRef.current = window.setTimeout(() => {
        setIsShown(false);
      }, currentToast.ttl || 6000);
    }
  }, [currentToast]);

  const handleToastClick = () => {
    setIsShown(false);

    window.clearTimeout(timeoutRef.current);
  };

  const handleLeaveAnimation = () => {
    setToasts(toasts.slice(1));
  };

  return (
    <ToastProviderContext.Provider value={{ showToast }}>
      {children}
      {createPortal(
        <ToastContainer
          aria-live="assertive"
          onClick={handleToastClick}
          role="status"
        >
          <Transition
            afterLeave={handleLeaveAnimation}
            css={[transition.fadeDown]}
            show={isShown}
          >
            {currentToast && <Toast {...currentToast} />}
          </Transition>
        </ToastContainer>,
        document.body,
      )}
    </ToastProviderContext.Provider>
  );
};

export default ToastProvider;

import { darken, transparentize } from "polished";

import { Theme } from "theme/default";
import { typography } from "utils/style";

export const buttonVariants = [
  "primary",
  "secondary",
  "circle",
  "circleSecondary",
] as const;

export type ButtonVariants = typeof buttonVariants[number];

export const getButtonBoxShadow = ({ theme }: Theme) =>
  `0px 14px 36px ${transparentize(0.85, theme.colors.midnightBlue)}`;

/**
 * This button utility accepts a variant and produces a style object to be used
 * with Styled Components.
 *
 * This is primarily used to apply a "button look" to elements other than <button>
 * tags, as we have a Button component already.
 *
 * For example, this can be applied to an anchor tag:
 * <a href="#" css={button("primary")} />
 *
 * Which will product an anchor tag with a button appearance.
 */
export const button =
  ({ variant }: { variant: ButtonVariants | undefined }) =>
  ({ theme }: Theme) => {
    const baseBoxShadow = getButtonBoxShadow({ theme });
    const baseStyle = {
      ...typography({ variant: "callToAction" })({ theme }),
      borderRadius: 100,
      boxSizing: "border-box",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 240,
      height: 60,
      padding: `${theme.spacing[6]}px ${theme.spacing[8]}px`,
      textAlign: "center",
      textDecoration: "none",
      textTransform: "uppercase",
    };

    const primaryStyle = {
      backgroundColor: theme.colors.primaryBlue,
      border: "none",
      color: theme.colors.white,

      "&:not(:disabled)": {
        boxShadow: baseBoxShadow,

        "&:hover, &.hover": {
          backgroundColor: darken(0.14, theme.colors.primaryBlue),
        },

        "&:active, &.active": {
          color: transparentize(0.4, theme.colors.white),
          boxShadow: "none",
        },

        "&:focus, &.focus": {
          boxShadow: `0 0 0 4px ${theme.colors.blue}, ${baseBoxShadow}`,
          outline: 0,
        },
      },

      "&:disabled": {
        backgroundColor: theme.colors.lightGrey,
        cursor: "not-allowed",
      },
    };

    const secondaryStyle = {
      backgroundColor: theme.colors.white,
      borderColor: theme.colors.lightGrey,
      borderStyle: "solid",
      borderWidth: 1,

      "&:not(:disabled)": {
        "&:hover, &.hover": {
          borderColor: darken(0.15, theme.colors.lightGrey),
        },

        "&:active, &.active": {
          borderColor: theme.colors.primaryBlue,
          color: theme.colors.primaryBlue,
        },

        "&:focus, &.focus": {
          borderColor: theme.colors.blue,
          boxShadow: `0 0 0 1px ${theme.colors.blue}`,
          outline: 0,
        },
      },

      "&:disabled": {
        color: theme.colors.lightGrey,
        cursor: "not-allowed",
      },
    };

    const circleStyle = {
      width: 48,
      height: 48,
      padding: 0,
      minWidth: 0,
    };

    switch (variant) {
      case "primary": {
        return {
          ...baseStyle,
          ...primaryStyle,
        };
      }

      case "secondary": {
        return {
          ...baseStyle,
          ...secondaryStyle,
        };
      }

      case "circle": {
        return {
          ...baseStyle,
          ...primaryStyle,
          ...circleStyle,
        };
      }

      case "circleSecondary": {
        return {
          ...baseStyle,
          ...secondaryStyle,
          ...circleStyle,
        };
      }

      default: {
        return {};
      }
    }
  };

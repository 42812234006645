import { AssessmentDetails } from "api/generated/data-contracts";

export type AssociateFieldAnswers = Record<
  string,
  string | string[] | number | boolean | undefined
>;

/**
 * Transforms an assessment to a data map, where keys
 * are the associatedField values of questions and values
 * are the answer to said question. Note that only a subset
 * of questions have associatedFields.
 *
 * This is particulary useful when needing to interpolate
 * assocaiated field references in rich text that come from
 * the backend.
 * E.g. "<h1>Hey, {{FirstNameEnum}}</h1>"
 */
const transformToAnswerMap = (data: AssessmentDetails) => {
  const steps = data?.categories?.flatMap((cat) => cat?.steps);
  const associatedFieldMap: AssociateFieldAnswers = {};
  steps?.forEach((val) => {
    if (val.question?.associatedField && val.answer) {
      // The answer will only have one answer field, but
      // at this point we don't know which one, so we check
      // for all that make sense.
      if (val.answer.answerText) {
        associatedFieldMap[val.question?.associatedField] =
          val.answer.answerText;
      } else if (val.answer.answerNumeric) {
        associatedFieldMap[val.question?.associatedField] =
          val.answer.answerNumeric;
      } else if (
        val.answer.answerMultipleChoice &&
        val.answer.answerMultipleChoice?.length === 1
      ) {
        associatedFieldMap[val.question?.associatedField] =
          val.answer.answerMultipleChoice[0];
      } else if (
        val.answer.answerMultipleChoice &&
        val.answer.answerMultipleChoice?.length > 1
      ) {
        associatedFieldMap[val.question?.associatedField] =
          val.answer.answerMultipleChoice;
      } else if (val.answer.answerSlider) {
        associatedFieldMap[val.question?.associatedField] =
          val.answer.answerSlider;
      } else if (val.answer.answerboolean) {
        associatedFieldMap[val.question?.associatedField] =
          val.answer.answerboolean;
      }
    }
  });
  return associatedFieldMap;
};

export default transformToAnswerMap;

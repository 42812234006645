import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useWindowWidth } from "@react-hook/window-size";
import styled from "styled-components";

import Card, {
  CardStack,
  CardState,
  Direction,
  ReactTinderCardAPI,
} from "components/base/SwipeMultiSelect/Card";
import Intro from "components/base/SwipeMultiSelect/Intro";
import Transition from "components/base/Transition";
import useTimeoutState from "hooks/useTimeoutState";
import { layout, transition, typography } from "utils/style";

import { AcceptButton, RejectButton } from "./Button";
import FeedbackToast from "./FeedbackToast";

const ButtonGroup = styled.div(layout.flexCenterHorizontal, ({ theme }) => ({
  ...layout.spacedChildrenHorizontal(2)({ theme }),
  marginTop: theme.spacing[8],
  [theme.media.above.sm]: {
    ...layout.spacedChildrenHorizontal(6)({ theme }),
    marginTop: 100,
  },
  [theme.media.above.lg]: {
    marginTop: 88,
  },
}));

const ActionMessageContainer = styled.div(
  layout.flexCenterHorizontal,
  ({ theme }) => ({
    marginTop: theme.spacing[8],
    [theme.media.above.sm]: {
      marginTop: theme.spacing[8],
    },
    [theme.media.above.lg]: {
      marginTop: theme.spacing[10],
    },
  }),
);
interface Option {
  label: string;
  value: string;
  imgSrc: string;
}
export interface Props {
  options: Option[];
  /**
   * Triggered when the accepted values change.
   */
  onChange?: (values: string[]) => void;
  /**
   * Triggered on when all the cards have been accepted or rejected.
   */
  onComplete?: (values: string[]) => void;
  /**
   * The message to be communicated to the user when the accept action is taken
   */
  acceptedMeaning?: string;
  /**
   * The message to be communicated to the user when the reject action is taken
   */
  rejectedMeaning?: string;
  /**
   * Whether to show a intro screen, before showing the swipable cards
   */
  showIntro?: boolean;
  className?: string;
}
interface SwipeState {
  currentIndex: number;
  pendingCompleteIndex?: number;
  acceptedValues: string[];
  lastResponse?: SwipeResponse;
}

interface SwipeRightAction {
  type: "swipeRight";
  payload: string;
}

interface SwipeLeftAction {
  type: "swipeLeft";
}
interface UpdateAction {
  type: "update";
  payload: SwipeState;
}

interface UndoAction {
  type: "undo";
}

interface PendingAction {
  type: "pending";
  payload?: number;
}

type Action =
  | UpdateAction
  | SwipeLeftAction
  | SwipeRightAction
  | UndoAction
  | PendingAction;

const reducer = (state: SwipeState, action: Action): SwipeState => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        ...action.payload,
      };
    case "swipeRight": {
      return {
        ...state,
        pendingCompleteIndex: undefined,
        lastResponse: "accepted",
        currentIndex: state.currentIndex - 1,
        acceptedValues: [...state.acceptedValues, action.payload],
      };
    }
    case "swipeLeft": {
      return {
        ...state,
        pendingCompleteIndex: undefined,
        lastResponse: "rejected",
        currentIndex: state.currentIndex - 1,
      };
    }
    case "undo": {
      return {
        ...state,
        acceptedValues:
          state.lastResponse === "accepted"
            ? state.acceptedValues.slice(0, state.acceptedValues.length - 1)
            : state.acceptedValues,
        lastResponse: undefined,
        pendingCompleteIndex: undefined,
        currentIndex: state.currentIndex + 1,
      };
    }
    case "pending": {
      return {
        ...state,
        pendingCompleteIndex: action.payload,
      };
    }
  }
};

type SwipeResponse = "accepted" | "rejected";

/**
 * A tinder like multi select uncontrolled component that allows you to 'accept' or 'reject' a set of options
 * depending on which way you swipe. Swiping right, or dragging the card 10% of the viewport width,
 * will trigger the `onChange` callback with the current array of accepted values. Clicking "Undo"
 * will also trigger the `onChange` callback if the last selected was 'accepted'. When all cards
 * have been swiped, the `onComplete` callback will be triggered. Presses the accept or reject buttons
 * have similar effect.
 */
const SwipeMultiSelect = ({
  options,
  onChange,
  onComplete,
  acceptedMeaning = "Important",
  rejectedMeaning = "Not Important",
  className,
}: Omit<Props, "showIntro">) => {
  const [
    { currentIndex, pendingCompleteIndex, acceptedValues, lastResponse },
    dispatch,
  ] = useReducer(reducer, {
    currentIndex: options.length - 1,
    pendingCompleteIndex: undefined,
    acceptedValues: [],
    lastResponse: undefined,
  });

  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    onChange && onChange(acceptedValues);
    if (currentIndex < 0) {
      onComplete && onComplete(acceptedValues);
    }
  }, [acceptedValues, currentIndex, onComplete, onChange]);

  // React Tinder Card exposes some internally functionality like `restoreCard` and `swipe`
  // uses references. You can think of these refs as a way of controlling cards.
  const childRefs = useMemo(
    () =>
      Array(options.length)
        .fill(undefined)
        .map(() => React.createRef<ReactTinderCardAPI>()),
    [options.length],
  );

  // set last direction and decrease current index
  const swiped = (direction: Direction, index: number) => {
    if (direction === "right") {
      dispatch({
        type: "swipeRight",
        payload: options[index].value,
      });
    } else {
      dispatch({
        type: "swipeLeft",
      });
    }

    currentIndexRef.current = index - 1;
  };

  const getState = (index: number): CardState => {
    if (index > currentIndex) {
      return "complete";
    } else if (index === pendingCompleteIndex) {
      return "pendingComplete";
    } else if (index === currentIndex) {
      return "active";
    } else if (
      index === currentIndex - 1 &&
      pendingCompleteIndex !== undefined
    ) {
      return "pendingActive";
    } else if (index === currentIndex - 1) {
      return "next";
    } else {
      return "queued";
    }
  };

  const outOfFrame = (dir: Direction, idx: number) => {
    swiped(dir, idx);
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx]?.current?.restoreCard();
  };

  const swipe = async (dir: Direction) => {
    if (childRefs && currentIndex >= 0 && currentIndex < options.length) {
      await childRefs[currentIndex]?.current?.swipe(dir); // Swipe the card!
    }
  };

  // increase current index and show card
  const goBack = async () => {
    if (!(currentIndex < options.length - 1)) return;
    dispatch({ type: "undo" });
    currentIndexRef.current = currentIndex + 1;

    await childRefs[currentIndexRef.current]?.current?.restoreCard();
  };
  return (
    <div
      css={[layout.flexVertical, { alignItems: "center" }]}
      className={className}
    >
      <CardStack>
        {options.map((option, index) => (
          <Card
            key={option.value}
            ref={childRefs[index]}
            onSwipe={() => dispatch({ type: "pending", payload: index })}
            onCardLeftScreen={(dir) => outOfFrame(dir, index)}
            swipeThreshold={windowWidth ? windowWidth * 0.1 : 300}
            onSwipeRequirementFulfilled={() => {
              dispatch({ type: "pending", payload: index });
            }}
            onSwipeRequirementUnfulfilled={() => {
              dispatch({ type: "pending", payload: undefined });
            }}
            state={getState(index)}
            rotateMultiplier={0}
            imgSrc={option.imgSrc}
            label={option.label}
          />
        ))}
      </CardStack>
      <ButtonGroup>
        <RejectButton
          aria-label={`Mark as ${rejectedMeaning}`}
          disabled={pendingCompleteIndex !== undefined}
          onClick={() => {
            swipe("left");
          }}
        />
        <AcceptButton
          aria-label={`Mark as ${acceptedMeaning}`}
          disabled={pendingCompleteIndex !== undefined}
          onClick={() => {
            swipe("right");
          }}
        />
      </ButtonGroup>
      <ActionMessageContainer>
        <FeedbackToast
          key={currentIndex}
          show={!!lastResponse}
          message={
            lastResponse === "accepted" ? acceptedMeaning : rejectedMeaning
          }
        />
        {!!lastResponse && (
          <button
            css={[
              typography({ variant: "callToAction", color: "primaryBlue" }),
              layout.padding(2),
            ]}
            onClick={() => goBack()}
          >
            UNDO
          </button>
        )}
      </ActionMessageContainer>
    </div>
  );
};

const SwipeMultiSelectWithIntro = ({
  showIntro = true,
  acceptedMeaning = "Important",
  rejectedMeaning = "Not Important",
  ...rest
}: Props) => {
  const showCards = useTimeoutState(3000, false, true);
  const [introComplete, setIntroComplete] = useState(false);
  return (
    <>
      <Transition
        show={showIntro && !showCards}
        afterLeave={() => setIntroComplete(true)}
        css={transition.fade()}
      >
        <Intro
          acceptedMeaning={acceptedMeaning}
          rejectedMeaning={rejectedMeaning}
          isMobile={isMobile}
        />
      </Transition>
      <Transition
        show={!showIntro || (showIntro && showCards && introComplete)}
        css={[transition.fade(), { overflow: "hidden" }]}
      >
        <SwipeMultiSelect
          acceptedMeaning={acceptedMeaning}
          rejectedMeaning={rejectedMeaning}
          {...rest}
        />
      </Transition>
    </>
  );
};

export default SwipeMultiSelectWithIntro;

import React, { useState } from "react";
import styled from "styled-components";

import { Answer } from "api/generated/data-contracts";
import { Select as SelectBase } from "components/base";
import AdvanceButton from "components/pages/CategoryQuiz/components/AdvanceButton";
import QuestionContainer from "components/pages/CategoryQuiz/components/QuestionContainer";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import QuestionError from "components/pages/CategoryQuiz/components/QuestionError";
import QuestionText from "components/pages/CategoryQuiz/components/QuestionText";
import {
  defaultChoiceKeys,
  mapChoicesToOptions,
} from "components/pages/CategoryQuiz/utils";
import {
  MultipleChoiceAnswerType,
  MultipleChoiceMultiAnswerQuestionType,
} from "types/api-extensions";
import { typography } from "utils/style";

const Select = styled(SelectBase)(({ theme }) => ({
  marginTop: 40,
  [theme.media.above.sm]: {
    marginTop: 60,
  },
}));

export interface Props {
  question: MultipleChoiceMultiAnswerQuestionType;
  answer?: MultipleChoiceAnswerType;
  onSubmit: (answer: Answer) => void;
  isSubmitting?: boolean;
  className?: string;
  footer?: React.ReactNode;
}

const MultipleChoiceMultiAnswerQuestion = ({
  question,
  answer,
  onSubmit,
  isSubmitting,
  className,
  footer,
}: Props) => {
  const { choices, includeImages } = question.multipleChoiceConfiguration;
  const [error, setError] = useState<string | undefined>();
  const [values, setValues] = useState<string[]>(
    answer?.answerMultipleChoice ?? defaultChoiceKeys(choices),
  );

  const handleSubmit = async () => {
    // Check config validations
    // is Required
    if (!values || values.length === 0) {
      setError("Required");
      return;
    }

    onSubmit({ answerMultipleChoice: values });
  };

  const handleChange = (value: string, checked: boolean) => {
    if (checked) {
      setValues(values ? [...values, value] : []);
    } else {
      setValues(values ? values.filter((val) => val !== value) : []);
    }
  };
  return (
    <QuestionContainer>
      <QuestionContent>
        <form
          id="question-form"
          onSubmit={(e) => {
            handleSubmit();
            e.preventDefault();
          }}
          className={className}
        >
          <QuestionText
            html={question.text}
            description={question.description}
          />
          <div
            css={[
              typography({ variant: "bodyLarge", color: "transparentText" }),
              { marginTop: 8 },
            ]}
          >
            Select all that apply
          </div>
          <Select
            value={values}
            multiple={true}
            options={mapChoicesToOptions(choices, true, !!includeImages)}
            onChange={handleChange}
          />
          {error && <QuestionError>{error}</QuestionError>}
        </form>
      </QuestionContent>
      <AdvanceButton
        form="question-form"
        type="submit"
        loading={isSubmitting}
        sticky={!!footer}
      />
      {footer}
    </QuestionContainer>
  );
};

export default MultipleChoiceMultiAnswerQuestion;

import { CategoryThemeEnum } from "api/generated/data-contracts";
import * as Icons from "components/base/Icon";

import { colors } from "./colors";

interface ThemeObject {
  icon: keyof typeof Icons;
  primaryColor: string;
  secondaryColor: string;
}

type CategoryTheme = {
  [key in CategoryThemeEnum]: ThemeObject;
};

export const categoryTheme: CategoryTheme = {
  AboutYellowEnum: {
    icon: "User",
    primaryColor: colors.yellow,
    secondaryColor: colors.transparentYellow,
  },
  FinanceGreenEnum: {
    icon: "AlignBottom",
    primaryColor: colors.green,
    secondaryColor: colors.transparentGreen,
  },
  LifePurpleEnum: {
    icon: "Circles",
    primaryColor: colors.purple,
    secondaryColor: colors.transparentPurple,
  },
  HomePurpleEnum: {
    icon: "House",
    primaryColor: colors.purple,
    secondaryColor: colors.transparentPurple,
  },
  FutureRedEnum: {
    icon: "Heart",
    primaryColor: colors.red,
    secondaryColor: colors.transparentRed,
  },
};

import styled from "styled-components";

import { Theme } from "theme/default";
import { layout, typography } from "utils/style";

type PillVariants = "green" | "purple" | "blue" | "pink";

const getPillStyles =
  (variant: PillVariants) =>
  ({ theme }: Theme) => {
    switch (variant) {
      case "green": {
        return {
          ...typography({ variant: "callToAction", color: "green" })({ theme }),
          backgroundColor: theme.colors.transparentGreen,
        };
      }

      case "purple": {
        return {
          ...typography({ variant: "callToAction", color: "purple" })({
            theme,
          }),
          backgroundColor: theme.colors.transparentPurple,
        };
      }

      case "blue": {
        return {
          ...typography({ variant: "callToAction", color: "primaryBlue" })({
            theme,
          }),
          backgroundColor: theme.colors.transparentPrimaryBlue,
        };
      }

      case "pink": {
        return {
          ...typography({ variant: "callToAction", color: "pink" })({
            theme,
          }),
          backgroundColor: theme.colors.transparentPink,
        };
      }
    }
  };

const Base = styled.div<{ variant: PillVariants }>(
  layout.padding(2, 3),
  ({ theme, variant }) => ({
    ...getPillStyles(variant)({ theme }),
    borderRadius: 100,
    textTransform: "uppercase",
    width: "max-content",
    height: 26,
    boxSizing: "border-box",
  }),
);

export interface Props {
  children: string;
  variant: PillVariants;
  className?: string;
}

const Pill = ({ children, variant, className }: Props) => {
  return (
    <Base className={className} variant={variant}>
      {children}
    </Base>
  );
};

export default Pill;

import { Player } from "@lottiefiles/react-lottie-player";
import styled, { keyframes } from "styled-components";

import throbberJson from "assets/loaders/throbber.json";
import { ReactComponent as Throbber } from "assets/svgs/Throbber.svg";
import { Colors } from "theme/default";
import { layout } from "utils/style";

export interface Props {
  color?: Colors;
  size?: number;
  variant?: "throbber" | "simple";
  className?: string;
}

/**
 * We are using string template literals here to work with `keyframes`. It does not
 * play well with style objects.
 */
const rotate = keyframes`
  100% {
      transform: rotate(360deg)
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Spinner = styled.div<Props>`
  animation: ${rotate} 2s linear infinite;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  & circle {
    stroke: ${({ color, theme }) =>
      color ? theme.colors[color] : `currentColor`};
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

const Loader = ({
  color,
  size = 240,
  variant = "throbber",
  className,
}: Props) => {
  switch (variant) {
    case "throbber": {
      return (
        <div
          css={{ width: size, height: size }}
          className={className}
          data-snapshot="hide"
        >
          <Player autoplay loop src={throbberJson} />
          <span css={layout.visuallyHidden}>Loading...</span>
        </div>
      );
    }

    case "simple": {
      return (
        <Spinner color={color} size={size} className={className}>
          <Throbber />
          <span css={layout.visuallyHidden}>Loading...</span>
        </Spinner>
      );
    }
  }
};

export default Loader;

import React from "react";

import Loader from "components/base/Loader";
import { button, ButtonVariants } from "utils/style/button";

export interface Props extends React.ComponentPropsWithoutRef<"button"> {
  disabled?: boolean;

  /**
   * Replaces `children` with a loading indicator when `true`.
   */
  loading?: boolean;

  /**
   * Controls how the button visually renders.
   *
   * @default "primary"
   */
  variant?: ButtonVariants;

  className?: string;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      disabled = false,
      loading = false,
      variant = "primary",
      onClick,
      className,
      ...rest
    }: Props,
    ref,
  ) => {
    if (loading && (variant === "circle" || variant === "circleSecondary")) {
      return (
        <Loader
          className={className}
          color="primaryBlue"
          size={48}
          variant="simple"
        />
      );
    }

    return (
      <button
        {...rest}
        disabled={disabled}
        onClick={loading ? undefined : onClick}
        css={button({ variant })}
        className={className}
        ref={ref}
      >
        {loading ? <Loader size={36} variant="simple" /> : children}
      </button>
    );
  },
);

export default Button;

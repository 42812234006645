import { useState } from "react";
import styled from "styled-components";

import { QuizTypeEnum } from "api/generated/data-contracts";
import { Button, Transition } from "components/base";
import { Close, ExpandLess } from "components/base/Icon";
import PulseButton from "components/base/PulseButton";
import { Results } from "components/pages";
import { useDelayedSpring } from "hooks/useDelayedSpring";
import { layout, typography } from "utils/style";
import { buildTransition, fadeUp } from "utils/style/transition";

import CompletePill from "./CompletePill";

const Base = styled.div<{ isOpen: boolean }>(
  layout.flexVertical,
  layout.fullHeight,
  ({ isOpen, theme }) => ({
    backgroundColor: theme.colors.white,
    borderRadius: 32,
    boxShadow: theme.boxShadows.soft,
    marginTop: isOpen ? theme.spacing[10] : 0,
    marginBottom: isOpen ? 60 : 0,
    paddingTop: theme.spacing[8],
    paddingBottom: isOpen ? 20 : theme.spacing[8],
    paddingLeft: theme.spacing[10],
    paddingRight: theme.spacing[10],
    height: "min-content",
    minHeight: "calc(100vh - 100px)",
    alignSelf: "center",
  }),
);

const Header = styled.div(typography({ variant: "heading5" }), ({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing[6],
}));

const CloseButton = styled(Button)(() => ({
  marginLeft: "auto",
}));

interface FloaterProps {
  translateY?: number;
}

// Note that we are using a `style` attr here because
// the update on `translateY`, if done in the typical
// styed component way triggers a warning that over
// 200 classes were generated.
const Floater = styled.div.attrs(({ translateY = 0 }: FloaterProps) => ({
  style: {
    transform: `translateY(${translateY}px)`,
  },
}))<FloaterProps>(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing[10],
  right: theme.spacing[10],
}));

const ProgressWrapper = styled.div(
  layout.flexCenterHorizontal,
  ({ theme }) => ({
    marginTop: theme.spacing[6],
  }),
);

const resultsReveal = () =>
  buildTransition({
    enterFrom: {
      transform:
        "translate3d(0px, 80px, 0px) scale3d(0.95, 0.95, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    },
    enterTo: {
      transform:
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    },
    leaveFrom: {
      transform:
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    },
    leaveTo: {
      transform:
        "translate3d(0px, 80px, 0px) scale3d(0.95, 0.95, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    },
    enter: {
      transition: "all 100ms cubic-bezier(0.32, 0, 0.67, 0)",
      transformStyle: "preserve-3d",
      willChange: "transform",
    },
    leave: {
      transition: "all 100ms cubic-bezier(0.32, 0, 0.67, 0)",
      transformStyle: "preserve-3d",
      willChange: "transform",
      position: "absolute",
      top: "40px",
    },
  });

const floaterReveal = () =>
  buildTransition({
    enterFrom: {
      transform:
        "translate3d(-40px, -40px, 0px) scale3d(1.05, 1.05, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    },
    enterTo: {
      transform:
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    },
    leaveFrom: {
      transform:
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    },
    leaveTo: {
      transform:
        "translate3d(-20px, -40px, 0px) scale3d(1.05, 1.05, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    },
    enter: {
      transition: "all 100ms linear",
      transformStyle: "preserve-3d",
      willChange: "transform",
      zIndex: 2,
    },
    leave: {
      transition: "all 200ms cubic-bezier(0.32, 0, 0.67, 0)",
      transformStyle: "preserve-3d",
      willChange: "transform",
      zIndex: 2,
    },
  });

export interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  quizType: QuizTypeEnum;
  spring?: boolean;
  springDelay?: number;
  pulse?: boolean;
}

const FloatingResults = ({
  isOpen = false,
  onOpen,
  onClose,
  quizType,
  spring,
  springDelay = 0,
  pulse,
}: Props) => {
  // This state variable is used to help coordinate animations between
  // the "Floater" component disappearing and the results window appearing.
  const [isFloaterOpen, setIsFloaterOpen] = useState(true);

  const springValue = useDelayedSpring({
    enabled: spring,
    delayMs: springDelay,
    // Ideally this would the height of the `Floater` component
    // however, that uses `min-content`. 200 works for now with
    // a bit of buffer.
    startValue: 200,
    endValue: 0,
    tension: 20,
  });

  return (
    <>
      <Transition
        show={isOpen}
        css={resultsReveal()}
        // When this component is unmounted (leaves), we need to reset this variable
        // to show the "Floater" again.
        afterLeave={() => setIsFloaterOpen(true)}
      >
        <Base css={{ width: 500 }} isOpen={isOpen}>
          <Header>
            Your Results
            <CloseButton onClick={onClose} variant="circleSecondary">
              <Close />
            </CloseButton>
          </Header>
          <Transition show={isOpen} appear={true} css={fadeUp(110)}>
            <Results quizType={quizType} />
          </Transition>
        </Base>
      </Transition>
      <Floater translateY={spring ? springValue : 0}>
        <Transition
          show={isFloaterOpen}
          css={[floaterReveal()]}
          // We call `onOpen` here instead of on `PulseButton` to coordinate animations.
          // When `onOpen` is called the view will shift into a 50/50 split of the category grid
          // and results window. This way we can animate the "Floater" disappearing first, then
          // change the view.
          afterLeave={onOpen}
        >
          <Base css={{ width: 300, minHeight: "auto" }} isOpen={false}>
            <div css={layout.flexVertical}>
              <PulseButton
                enabled={pulse}
                onClick={() => setIsFloaterOpen(false)}
              >
                <ExpandLess />
              </PulseButton>
              <ProgressWrapper>
                <span css={typography({ variant: "heading5" })}>
                  Your Results
                </span>
                <div css={{ marginLeft: "auto" }}>
                  <CompletePill spring={spring} springDelay={springDelay} />
                </div>
              </ProgressWrapper>
            </div>
          </Base>
        </Transition>
      </Floater>
    </>
  );
};

export default FloatingResults;

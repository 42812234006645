/**
 * `screenSizes` represent the min-width for each break point
 */
export const breakpoints = {
  sm: { min: 0, max: 767 },
  md: { min: 768, max: 1023 },
  lg: { min: 1024, max: 1439 },
  xl: { min: 1440, max: null },
} as const;

export const media = {
  below: {
    md: `@media (max-width: ${breakpoints.sm.max}px)` as const,
    lg: `@media (max-width: ${breakpoints.md.max}px)` as const,
    xl: `@media (max-width: ${breakpoints.lg.max}px)` as const,
  },
  above: {
    sm: `@media (min-width: ${breakpoints.md.min}px)` as const,
    md: `@media (min-width: ${breakpoints.lg.min}px)` as const,
    lg: `@media (min-width: ${breakpoints.xl.min}px)` as const,
  },
} as const;

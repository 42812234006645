import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import browserHistory from "./browserHistory";

const connectionString = process.env.REACT_APP_APP_INSIGHTS_CONNETION_STRING
  ? process.env.REACT_APP_APP_INSIGHTS_CONNETION_STRING
  : undefined;
const loggingLevelConsole = process.env
  .REACT_APP_APP_INSIGHTS_LOGGING_LEVEL_CONSOLE
  ? parseInt(process.env.REACT_APP_APP_INSIGHTS_LOGGING_LEVEL_CONSOLE)
  : undefined;
const loggingLevelTelemetry = process.env
  .REACT_APP_APP_INSIGHTS_LOGGING_LEVEL_TELEMETRY
  ? parseInt(process.env.REACT_APP_APP_INSIGHTS_LOGGING_LEVEL_TELEMETRY)
  : undefined;
const cookieCfg = (
  process.env.REACT_APP_APP_INSIGHTS_COOKIE_CFG_ENABLED || ""
).match(/true/i)
  ? {
      enabled: true,
      domain: process.env.REACT_APP_APP_INSIGHTS_COOKIE_CFG_DOMAIN
        ? process.env.REACT_APP_APP_INSIGHTS_COOKIE_CFG_DOMAIN
        : undefined,
      path: process.env.REACT_APP_APP_INSIGHTS_COOKIE_CFG_PATH
        ? process.env.REACT_APP_APP_INSIGHTS_COOKIE_CFG_PATH
        : undefined,
    }
  : undefined;
const appId = process.env.REACT_APP_APP_INSIGHTS_APP_ID
  ? process.env.REACT_APP_APP_INSIGHTS_APP_ID
  : undefined;

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString,
    loggingLevelConsole,
    loggingLevelTelemetry,
    cookieCfg,
    appId,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };

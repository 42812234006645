import { Category } from "api/generated/data-contracts";

/**
 * Returns the decimal percent of question steps completed within a category.
 * @param category Category
 * @returns number
 */
export const getCategoryProgress = (category: Category) => {
  return (
    category.steps.filter(
      (step) => step.stepType === "QuestionEnum" && !!step.answer,
    ).length /
    category.steps.filter((step) => step.stepType === "QuestionEnum").length
  );
};

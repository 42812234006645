import styled, { useTheme } from "styled-components";

import * as Icons from "components/base/Icon";
import { CategoryTheme } from "theme/default";
import { layout } from "utils/style";

const CircledIcon = styled.div<{
  size: number;
  background: string;
  color: string;
}>(layout.flexCenter, ({ size, background, color }) => ({
  position: "relative",
  background: background,
  borderRadius: "50%",
  color: color,
  height: size,
  width: size,
}));
const CategoryIcon = ({ categoryTheme }: { categoryTheme: CategoryTheme }) => {
  const theme = useTheme();
  const Icon = Icons[theme.categoryTheme[categoryTheme].icon];
  return (
    <CircledIcon
      background={theme.categoryTheme[categoryTheme].secondaryColor}
      color={theme.categoryTheme[categoryTheme].primaryColor}
      size={74}
    >
      <CircledIcon
        background={theme.colors.white}
        color={theme.categoryTheme[categoryTheme].primaryColor}
        size={32}
        css={{ position: "absolute", top: -8, right: -8 }}
      >
        <Icons.Check size={14} />
      </CircledIcon>
      <Icon size={30} />
    </CircledIcon>
  );
};

export default CategoryIcon;

import {
  Answer,
  InterstitialScreen,
  Question,
  QuestionMultipleChoiceConfiguration,
  Step,
} from "api/generated/data-contracts";

/**
 * While the automatically generated types from swagger are very nice to work with,
 * the typing provided from the API is limited compared to typescript. For example,
 * we may get a `Step` object with a `stepType === 'QuestionEnum'`. We know this
 * object will have a question, but because we also have step types of `InterstitialScreenEnum`,
 * which does not require `question`, `question` is just optional. The same can be
 * said for our various question types and their associated answer types and configuration fields.

 * What the typing here do is narrows the typing in these circumstances, so, for example, we
 * know that a question step that is a short text question will have a `shortTextConfiguration`
 * field and return the answer as `{ answerText: sting}`. More specifically, this is done with
 * type predicate (ie `isShortTextQuestion`), which, when used with a conditional, narrows the
 * type of the step. Furthermore, this will allow us to be explicit in which question types can
 * be passed down to our Question components and also allow us to not have to check if something
 * exists when we know it inherently should.
 */

export type ShortTextQuestionType = Question &
  Required<Pick<Question, "shortTextConfiguration">> & {
    questionType: "ShortTextEnum";
  };
export type ShortTextAnswerType = Required<Pick<Answer, "answerText">>;
export const isShortTextQuestion = (
  step: Step,
): step is {
  question: ShortTextQuestionType;
  answer?: ShortTextAnswerType;
} & Step => {
  return step?.question?.questionType === "ShortTextEnum";
};

export type EmailQuestionType = Question &
  Required<Pick<Question, "emailConfiguration">> & {
    questionType: "EmailEnum";
  };
export const isEmailQuestion = (
  step: Step,
): step is {
  question: EmailQuestionType;
  answer?: ShortTextAnswerType;
} & Step => {
  return step?.question?.questionType === "EmailEnum";
};

export type BooleanQuestionType = Question &
  Required<Pick<Question, "booleanConfiguration">> & {
    questionType: "BooleanEnum";
  };
export type BooleanAnswerType = Required<Pick<Answer, "answerboolean">>;
export const isBooleanQuestion = (
  step: Step,
): step is {
  question: BooleanQuestionType;
  answer?: BooleanAnswerType;
} & Step => {
  return step?.question?.questionType === "BooleanEnum";
};

export type LongTextQuestionType = Question &
  Required<Pick<Question, "longTextConfiguration">> & {
    questionType: "LongTextEnum";
  };
export type LongTextAnswerType = Required<Pick<Answer, "answerText">>;
export const isLongTextQuestion = (
  step: Step,
): step is {
  question: LongTextQuestionType;
  answer?: LongTextAnswerType;
} & Step => {
  return step?.question?.questionType === "LongTextEnum";
};

export type SliderQuestionType = Question &
  Required<Pick<Question, "sliderConfiguration">> & {
    questionType: "SliderEnum";
  };
export type SliderAnswerType = Required<Pick<Answer, "answerSlider">>;
export const isSliderQuestion = (
  step: Step,
): step is {
  question: SliderQuestionType;
  answer?: SliderAnswerType;
} & Step => {
  return step?.question?.questionType === "SliderEnum";
};

export type ZipQuestionType = Question &
  Required<Pick<Question, "zipConfiguration">> & {
    questionType: "ZipEnum";
  };
export type ZipAnswerType = Required<Pick<Answer, "answerText">>;
export const isZipQuestion = (
  step: Step,
): step is {
  question: ZipQuestionType;
  answer?: ZipAnswerType;
} & Step => {
  return step?.question?.questionType === "ZipEnum";
};

export type MultipleChoiceMultiAnswerQuestionType = Question & {
  questionType: "MultipleChoiceEnum";
  multipleChoiceConfiguration: QuestionMultipleChoiceConfiguration & {
    questionChoiceType: "MultiEnum";
  };
};
export type MultipleChoiceAnswerType = Required<
  Pick<Answer, "answerMultipleChoice">
>;
export const isMultipleChoiceMultiAnswerQuestion = (
  step: Step,
): step is {
  question: MultipleChoiceMultiAnswerQuestionType;
  answer?: MultipleChoiceAnswerType;
} & Step => {
  return (
    step?.question?.questionType === "MultipleChoiceEnum" &&
    step?.question?.multipleChoiceConfiguration?.questionChoiceType ===
      "MultiEnum"
  );
};

export type MultipleChoiceSingleAnswerQuestionType = Question & {
  questionType: "MultipleChoiceEnum";
  multipleChoiceConfiguration: QuestionMultipleChoiceConfiguration & {
    questionChoiceType: "SingleEnum";
  };
};

export const isMultipleChoiceSingleAnswerQuestion = (
  step: Step,
): step is {
  question: MultipleChoiceSingleAnswerQuestionType;
  answer?: MultipleChoiceAnswerType;
} & Step => {
  return (
    step?.question?.questionType === "MultipleChoiceEnum" &&
    step?.question?.multipleChoiceConfiguration?.questionChoiceType ===
      "SingleEnum"
  );
};

export type SwipeQuestionType = Question & {
  questionType: "MultipleChoiceEnum";
  multipleChoiceConfiguration: QuestionMultipleChoiceConfiguration & {
    questionChoiceType: "SwipeEnum";
  };
};
export const isSwipeQuestion = (
  step: Step,
): step is {
  question: SwipeQuestionType;
  answer?: MultipleChoiceAnswerType;
} & Step => {
  return (
    step?.question?.questionType === "MultipleChoiceEnum" &&
    step?.question?.multipleChoiceConfiguration?.questionChoiceType ===
      "SwipeEnum"
  );
};

export type ActionableInterstitialStep = Step & {
  stepType: "InterstitialScreenEnum";
  interstitialScreen: InterstitialScreen & {
    actionable: true;
  };
};
export const isActionableInterstitialStep = (
  step: Step,
): step is ActionableInterstitialStep & Step => {
  return (
    step?.stepType === "InterstitialScreenEnum" &&
    step?.interstitialScreen?.actionable === true
  );
};

export type GenericInterstitialStep = Step & {
  stepType: "InterstitialScreenEnum";
  interstitialScreen: InterstitialScreen & {
    actionable: false;
  };
};
export const isGenericInterstitialStep = (
  step: Step,
): step is GenericInterstitialStep & Step => {
  return (
    step?.stepType === "InterstitialScreenEnum" &&
    step?.interstitialScreen?.actionable === false
  );
};

export type NumericQuestionType = Question &
  Required<Pick<Question, "numericConfiguration">> & {
    questionType: "NumericEnum";
  };
export type NumericAnswerType = Required<Pick<Answer, "answerNumeric">>;
export const isNumericQuestion = (
  step: Step,
): step is {
  question: NumericQuestionType;
  answer?: NumericAnswerType;
} & Step => {
  return step?.question?.questionType === "NumericEnum";
};

import { Spacings, Theme } from "theme/default";

type Gap = Spacings;

export const marginVertical =
  (space: Spacings) =>
  ({ theme }: Theme) => ({
    marginTop: theme.spacing[space],
    marginBottom: theme.spacing[space],
  });

export const marginHorizontal =
  (space: Spacings) =>
  ({ theme }: Theme) => ({
    marginLeft: theme.spacing[space],
    marginRight: theme.spacing[space],
  });

export const paddingVertical =
  (space: Spacings) =>
  ({ theme }: Theme) => ({
    paddingTop: theme.spacing[space],
    paddingBottom: theme.spacing[space],
  });

export const paddingHorizontal =
  (space: Spacings) =>
  ({ theme }: Theme) => ({
    paddingLeft: theme.spacing[space],
    paddingRight: theme.spacing[space],
  });

export const spacing =
  (spacingType: "margin" | "padding") =>
  (s1: Spacings, s2?: Spacings, s3?: Spacings, s4?: Spacings) =>
  ({ theme }: Theme) => {
    let spacingLeft, spacingRight, spacingTop, spacingBottom;
    if (s4 !== undefined && s3 !== undefined && s2 !== undefined) {
      spacingTop = theme.spacing[s1];
      spacingRight = theme.spacing[s2];
      spacingBottom = theme.spacing[s3];
      spacingLeft = theme.spacing[s4];
    } else if (s3 !== undefined && s2 !== undefined) {
      spacingTop = theme.spacing[s1];
      spacingBottom = theme.spacing[s3];
      spacingRight = spacingLeft = theme.spacing[s2];
    } else if (s2 !== undefined) {
      spacingTop = spacingBottom = theme.spacing[s1];
      spacingRight = spacingLeft = theme.spacing[s2];
    } else {
      spacingTop =
        spacingRight =
        spacingLeft =
        spacingBottom =
          theme.spacing[s1];
    }
    return {
      [`${spacingType}Top`]: spacingTop,
      [`${spacingType}Right`]: spacingRight,
      [`${spacingType}Bottom`]: spacingBottom,
      [`${spacingType}Left`]: spacingLeft,
    };
  };

export const padding = spacing("padding");
export const margin = spacing("margin");

export const spacedChildrenVertical =
  (gap: Gap = 1) =>
  ({ theme }: Theme) => ({
    "& > :first-child": {
      marginTop: 0,
    },
    "> *": {
      marginTop: gap ? theme.spacing[gap] : theme.spacing[1],
    },
  });

export const spacedChildrenHorizontal =
  (gap: Gap = 1) =>
  ({ theme }: Theme) => ({
    "& > :first-child": {
      marginLeft: 0,
    },
    "> *": {
      marginLeft: gap ? theme.spacing[gap] : theme.spacing[1],
    },
  });

export const flexStart = {
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "flex-start",
};

export const flexCenterHorizontal = {
  display: "flex",
  alignItems: "center",
} as const;

export const flexCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
} as const;

export const flexVertical = {
  display: "flex",
  flexDirection: "column",
} as const;

export const visuallyHidden = {
  clip: "rect(0 0 0 0)",
  height: 0,
  opacity: 0,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: 0,
} as const;

export const fullWidth = {
  width: "100%",
} as const;

export const fullHeight = {
  height: "100%",
} as const;

import styled from "styled-components";

import { ReactComponent as IntroSvg } from "assets/svgs/swiper-intro.svg";
import { typography } from "utils/style";

const Instructions = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 100,
  [theme.media.above.sm]: {
    marginTop: 175,
  },
}));

const Base = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 62,
  [theme.media.above.sm]: {
    marginTop: 80,
  },
}));

export interface Props {
  acceptedMeaning: string;
  rejectedMeaning: string;
  isMobile: boolean;
}

const Intro = ({ acceptedMeaning, rejectedMeaning, isMobile }: Props) => (
  <Base>
    <IntroSvg role="presentation" />
    <Instructions>
      <div css={{ margin: "auto" }}>
        {isMobile ? "Swipe" : "Drag"} Right for{" "}
        <span css={typography({ variant: "bodyBold" })}>{acceptedMeaning}</span>
      </div>
      <div css={{ margin: "auto" }}>
        {isMobile ? "Swipe" : "Drag"} Left for{" "}
        <span css={typography({ variant: "bodyBold" })}>{rejectedMeaning}</span>
      </div>
    </Instructions>
  </Base>
);

export default Intro;

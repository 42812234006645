import { RadioGroup } from "@headlessui/react";
import styled from "styled-components";

import { layout } from "utils/style";

import SelectOption, { Option, RadioGroupOption, Switch } from "./SelectOption";

interface Props {
  options: Option[];
  className?: string;
}

export interface MultiSelectProps extends Props {
  multiple: true;
  onChange: (value: string, checked: boolean) => void;
  value: string[];
}

export interface SingleSelectProps extends Props {
  multiple: false | undefined;
  onChange: (value: string) => void;
  value?: string;
}

const Base = styled.div(layout.flexVertical);

const Select = (props: MultiSelectProps | SingleSelectProps) => {
  if (props.multiple) {
    return (
      <Base className={props.className}>
        {props.options.map((option, index) => (
          <Switch
            checked={props.value.includes(option.value)}
            key={`${option.value}-${index}`}
            onChange={(checked: boolean) =>
              props.onChange(option.value, checked)
            }
          >
            <SelectOption
              body={option.body}
              checked={props.value.includes(option.value)}
              imgSrc={option.imgSrc}
              title={option.title}
              value={option.value}
            />
          </Switch>
        ))}
      </Base>
    );
  }

  return (
    <Base className={props.className}>
      <RadioGroup value={props.value} onChange={props.onChange}>
        {props.options.map((option) => (
          <RadioGroupOption value={option.value} key={option.value}>
            {/* @ts-ignore */}
            {({ checked }) => (
              <SelectOption
                body={option.body}
                checked={checked}
                title={option.title}
                imgSrc={option.imgSrc}
                type="radio"
                value={option.value}
                css={props.value && !checked ? { opacity: 0.6 } : undefined}
              />
            )}
          </RadioGroupOption>
        ))}
      </RadioGroup>
    </Base>
  );
};

export default Select;

import React from "react";
import styled from "styled-components";

import didYouKnowBgLg from "assets/images/DidYouKnowBgLg.svg";
import didYouKnowBgMd from "assets/images/DidYouKnowBgMd.svg";
import didYouKnowBgSm from "assets/images/DidYouKnowBgSm.svg";
import { Transition } from "components/base";
import { layout, typography } from "utils/style";
import { fade, fadeUp } from "utils/style/transition";

const Base = styled.div(layout.flexVertical, ({ theme }) => ({
  alignItems: "center",
  backgroundImage: `url(${didYouKnowBgSm})`,
  backgroundPosition: "top center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",

  bottom: 0,
  height: 294,

  [theme.media.below.md]: {
    marginTop: 24,
    left: 0,
    right: 0,
  },

  [theme.media.above.sm]: {
    position: "absolute",
    marginLeft: "auto",
    backgroundImage: `url(${didYouKnowBgMd})`,
    backgroundPosition: "bottom right",
    right: 0,
    width: 434,
    height: 361,
  },

  [theme.media.above.lg]: {
    backgroundImage: `url(${didYouKnowBgLg})`,
    height: 396,
    width: 521,
  },
}));

const Content = styled.div(layout.flexVertical, ({ theme }) => ({
  alignItems: "center",

  marginTop: 70,
  paddingBottom: 110,

  [theme.media.above.sm]: {
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: theme.spacing[9],
    paddingBottom: 70,
  },
}));

const Title = styled.h3(
  typography({
    variant: "heading6",
    color: "primaryBlue",
  }),
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing[4],

    [theme.media.above.sm]: {
      marginTop: "auto",
    },
  }),
);

const Body = styled.p(
  typography({ variant: { sm: "heading4", md: "heading3", xl: "heading3" } }),
  ({ theme }) => ({
    maxWidth: 260,
    textAlign: "center",

    [theme.media.above.sm]: {},

    [theme.media.above.lg]: {
      maxWidth: 315,
    },
  }),
);

interface Props {
  animationDelay?: number;
}

const FiveStarReviewsBlurb = ({ animationDelay = 0 }: Props) => {
  return (
    <Transition appear={true} show={true} css={fade(animationDelay)}>
      <Base>
        <Content>
          <Transition.Child css={fadeUp(animationDelay + 400)}>
            <Title>25,000+ 5-Star Reviews</Title>
          </Transition.Child>

          <Transition.Child css={fadeUp(animationDelay + 400, 1, 400)}>
            <Body>
              We believe in helping people build wealth through owning and
              optimizing their home.
            </Body>
          </Transition.Child>
        </Content>
      </Base>
    </Transition>
  );
};

export default FiveStarReviewsBlurb;

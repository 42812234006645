import { useState } from "react";
import { useIsomorphicLayoutEffect, usePreviousDistinct } from "react-use";

/**
 * Applies the a linear tweening effect to a number value update.
 * That is, if a number (a prop or state) was 50, and updated to 90,
 * the output of this function periodically update from 50 to 90 over
 * the duration period. Upon initial render, no tweening is applied.
 * Each update of the return val causes a re-render of the component.
 * This function is an adapation of this hook: https://github.com/streamich/react-use/blob/master/docs/useRaf.md
 * @param value
 * @param durationMs
 * @returns tween value
 */
export const useTweenPrevious = (
  value: number,
  ms: number = 1e12,
  delay: number = 0,
): number => {
  const [tweenValue, setTweenValue] = useState<number>(value);
  const prevValue = usePreviousDistinct(value);
  useIsomorphicLayoutEffect(() => {
    let raf: number;
    let timerStop: ReturnType<typeof setTimeout>;
    let start: number;

    const onFrame = () => {
      const percentComplete = Math.min(1, (Date.now() - start) / ms);
      if (prevValue !== undefined) {
        setTweenValue(prevValue + (value - prevValue) * percentComplete);
      }

      loop();
    };
    const loop = () => {
      // When tweening, we need to update our tween value at periodic
      // intervals between our start and end time. Conceptually, this
      // is like `setInterval(onFrame, x)`, where an update would occur
      // every `x` ms. However, we aren't in control of how often the
      // brower can paint. If `x` is too small, we are making multiple
      // paints in between paints. If `x` is too large, we are making too
      // few. `requestAnimationFrame` comes to the rescue, calling it
      // callback before every repaint rather than set intervals.
      raf = requestAnimationFrame(onFrame);
    };
    const onStart = () => {
      timerStop = setTimeout(() => {
        // Stop the propagation of raf calls
        cancelAnimationFrame(raf);
        setTweenValue(value);
      }, ms);
      start = Date.now();
      loop();
    };
    const timerDelay = setTimeout(onStart, delay);

    return () => {
      clearTimeout(timerStop);
      clearTimeout(timerDelay);
      cancelAnimationFrame(raf);
    };
  }, [value, prevValue, ms, delay]);

  return tweenValue;
};

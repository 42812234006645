import { useTween } from "react-use";
import numeral from "numeral";
import styled from "styled-components";

import { ScoreGradeRange } from "api/generated/data-contracts";
import { getScoreGradeRange } from "utils/scoreGradeRange";
import { layout, typography } from "utils/style";

import Chart from "./Chart";
import { default as IndicatorIcon } from "./Indicator";

const Container = styled.div(layout.margin(8, 8, 0, 8), {
  width: 236,
  height: 236,
  position: "relative",
  marginBottom: -40,
});

const IndicatorContainer = styled.div<{ rotation: number }>(
  layout.fullWidth,
  layout.fullHeight,
  ({ rotation }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    transform: `rotate(${rotation}deg)`,
    transformOrigin: "center center",
  }),
);

const Indicator = styled(IndicatorIcon)({
  width: 14,
  height: 14,
  position: "absolute",
  top: -30,
});

const Icon = styled.div<{ rotation: number }>(
  layout.flexCenter,
  ({ rotation, theme }) => ({
    width: 40,
    height: 40,
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.colors.offWhite,
    borderRadius: "50%",
    boxShadow: theme.boxShadows.soft,
    transform: `rotate(${rotation}deg)`,
    position: "absolute",
    top: -10,
  }),
);

const Progress = styled.div(
  layout.fullWidth,
  layout.fullHeight,
  layout.flexCenter,
  {
    position: "absolute",
    top: 0,
    left: 0,
  },
);

export interface Props {
  className?: string;
  score: number;
  scoreGrades: ScoreGradeRange[];
}

// In degrees.
const ZERO_ROTATION = -110;
const FULL_ROTATION = 110;

const Score = ({ className, score: scoreProp = 0, scoreGrades }: Props) => {
  const t = useTween("inOutCubic", 1000);
  // According to the quiz spec, the score should never be above 0.9999.
  // We clamp the value just to be safe.
  // ---
  // The smallest number we want to represent is 0.01%. `useTween` sometimes
  // has extremely small values returned, which will cause our `numeral`
  // library to return "NaN". This looks odd, which is why we clamp
  // the values here.
  const score = Math.max(
    Math.min(Math.max(scoreProp * t || 0, 0), 0.9999),
    0.0001,
  );
  const rotation = (FULL_ROTATION - ZERO_ROTATION) * score + ZERO_ROTATION;

  const percent = numeral(score * 100)
    .format("0[.]00")
    .split(".");
  const wholePercent = percent[0];
  const decimalPercent = percent[1] ?? undefined;

  const scoreGradeRange = getScoreGradeRange(score, scoreGrades);
  const scoreGradeRangeColor = scoreGradeRange?.gradeColor ?? "black";
  const scoreGradeRangeEmoji = scoreGradeRange?.gradeEmoji ?? "🏡";

  return (
    <div className={className}>
      <Container>
        <Chart score={score} scoreGrades={scoreGrades} />
        <Progress>
          <div css={{ display: "flex" }}>
            <span
              css={[
                typography({ variant: "display2" }),
                { lineHeight: "3rem" },
              ]}
            >
              {wholePercent}
            </span>
            {decimalPercent && score >= 0.9 && (
              <span
                css={[
                  {
                    marginTop: "auto",
                    marginLeft: 1,
                    fontFamily: "Gotham Bold",
                    fontSize: 24,
                  },
                ]}
              >
                .{decimalPercent}
              </span>
            )}
          </div>
        </Progress>
        <IndicatorContainer rotation={rotation}>
          <Indicator fill={scoreGradeRangeColor} />
          <Icon rotation={-rotation}>
            <span role="img" aria-label="Emoji">
              {scoreGradeRangeEmoji}
            </span>
          </Icon>
        </IndicatorContainer>
      </Container>
    </div>
  );
};

export default Score;

import ReactGA from "react-ga";
import styled from "styled-components";

import {
  PurchaseAssessmentSummary,
  ScoreFactor,
  ScoreGradeRange,
} from "api/generated/data-contracts";
import { Pill } from "components/base";
import { ArrowDownward, ArrowUpward, Launch } from "components/base/Icon";
import { Theme } from "theme/default";
import { button, layout, typography } from "utils/style";

const ScoreFactorBase = styled.div({
  marginBottom: -50,
});

const ScoreFactorTitle = styled.div(
  typography({ variant: "heading3" }),
  ({ theme }) => ({
    display: "flex",
    marginBottom: theme.spacing[7],
  }),
);

const NegativeScoreTitle = styled.div(({ theme }) => ({
  maxWidth: 190,

  [theme.media.above.sm]: {
    maxWidth: "none",
  },
}));

const CallToActionsBase = styled.div(layout.flexVertical, {
  alignItems: "center",
  background:
    "linear-gradient(2.12deg, #FFFFFF 50.09%, rgba(255, 255, 255, 0) 98.31%)",
  position: "sticky",
  paddingTop: 80,
  paddingBottom: 20,
  bottom: 0,
});

const callToActionStyles = ({ theme }: Theme) => ({
  width: "100%",

  [theme.media.above.sm]: {
    width: "max-content",
  },
});

const CallToActionPrimary = styled(ReactGA.OutboundLink)(
  button({ variant: "primary" }),
  callToActionStyles,
);

const CallToActionSecondary = styled(ReactGA.OutboundLink)(
  callToActionStyles,
  typography({ variant: "callToAction" }),
  layout.padding(6, 0),
  {
    textTransform: "uppercase",
    textAlign: "center",
    display: "block",
  },
);

interface Props {
  scoreGradeRange: ScoreGradeRange;
  summary: PurchaseAssessmentSummary;
}

const PurchaseResults = ({ scoreGradeRange, summary }: Props) => {
  const primaryCallToActionTitle = scoreGradeRange?.primaryCtaText;
  const primaryCallToActionUrl =
    scoreGradeRange?.primaryCtaUrl ?? "https://lower.com";
  const secondaryCallToActionTitle = scoreGradeRange?.secondaryCtaText;
  const secondaryCallToActionUrl =
    scoreGradeRange?.secondaryCtaUrl ?? "https://lower.com";

  const renderScoreFactor = (
    scoreFactor: ScoreFactor,
    type: "pro" | "con" = "pro",
  ) => (
    <div css={layout.flexStart}>
      <div css={layout.margin(0, 4, 0, 0)}>
        {type === "pro" ? (
          <ArrowUpward color="green" />
        ) : (
          <ArrowDownward color="pink" />
        )}
      </div>
      <div css={layout.flexVertical}>
        <div
          css={[typography({ variant: "heading4" }), layout.margin(0, 0, 2, 0)]}
        >
          {scoreFactor.name}
        </div>
        <div css={[typography({ variant: "body" }), { opacity: 0.6 }]}>
          {scoreFactor.description}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ScoreFactorBase>
        <div>
          <ScoreFactorTitle>
            {summary.positiveScoreFactorsTitle}
            <Pill css={{ marginLeft: "auto", marginTop: 4 }} variant="green">
              Pros
            </Pill>
          </ScoreFactorTitle>
          <ul
            css={[layout.spacedChildrenVertical(7), layout.margin(0, 0, 7, 0)]}
          >
            {summary.positiveScoreFactors?.map((scoreFactor, index) => {
              return <li key={index}>{renderScoreFactor(scoreFactor)}</li>;
            })}
          </ul>
        </div>
        <div>
          <ScoreFactorTitle>
            <NegativeScoreTitle>
              {summary.negativeScoreFactorsTitle}
            </NegativeScoreTitle>
            <Pill css={{ marginLeft: "auto", marginTop: 4 }} variant="pink">
              Cons
            </Pill>
          </ScoreFactorTitle>
          <ul
            css={[layout.spacedChildrenVertical(7), layout.margin(0, 0, 7, 0)]}
          >
            {summary.negativeScoreFactors?.map((scoreFactor, index) => {
              return (
                <li key={index}>{renderScoreFactor(scoreFactor, "con")}</li>
              );
            })}
          </ul>
        </div>
      </ScoreFactorBase>
      <CallToActionsBase>
        <CallToActionPrimary
          eventLabel={primaryCallToActionUrl}
          to={primaryCallToActionUrl}
          target="_blank"
        >
          {primaryCallToActionTitle}
          <Launch css={layout.margin(0, 0, 0, 1)} size={14} />
        </CallToActionPrimary>
        <CallToActionSecondary
          eventLabel={secondaryCallToActionUrl}
          to={secondaryCallToActionUrl}
          target="_blank"
        >
          {secondaryCallToActionTitle}
        </CallToActionSecondary>
      </CallToActionsBase>
    </>
  );
};

export default PurchaseResults;

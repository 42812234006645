import React, { forwardRef } from "react";
import { ChangeEvent, useState } from "react";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import styled from "styled-components";

import { DEFAULT_PLACEHOLDER } from "components/base/ShortTextInput";
import TextAreaAutosize from "components/base/TextAreaAutosize";
import { typography } from "utils/style";
import { VariantPerBreakpoint } from "utils/style/typography";

const Base = styled.div({
  display: "block",
});

const Unit = styled.div(({ theme }) => ({
  flex: "0 0 auto",
  opacity: 0.6,
  alignSelf: "end",
  marginLeft: `${theme.spacing[1]}px`,
  ...typography({ variant: { sm: "heading6", md: "heading4" } })({ theme }),
}));

export interface Props extends Omit<TextareaAutosizeProps, "onChange"> {
  // TextareaAutosize has typed value as a string | number | string[]. We are only concerned about the number case.
  value?: number;
  modifier?: (value: number) => string;
  reverseModifier?: (value: string) => number;
  className?: string;
  unit?: string;
  onChange: (value: number | undefined) => void;
}

const INTL_NUMBER_FORMAT = new Intl.NumberFormat("en-US", {
  // This includes decimals. Set to an arbitrarily large value
  maximumSignificantDigits: 20,
});

const parseNumber = (stringNumber: string) => {
  return parseFloat(stringNumber.replace(new RegExp(",", "g"), ""));
};

const formatNumber = (value: number) => {
  return INTL_NUMBER_FORMAT.format(value);
};

/**
 * This component renders a textarea element which resizes its height based on the content. On mobile, font size is
 * dynamic based on the amount of characters the input value have. This is done to keep the characters on a single
 * line, though if the text does extend the boundary of the input box, it will wrap to the next row.
 */
const UnitInput = forwardRef(
  (
    {
      value,
      unit,
      modifier = formatNumber,
      reverseModifier = parseNumber,
      onChange,
      placeholder = DEFAULT_PLACEHOLDER,
      ...rest
    }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>,
  ) => {
    // This component accepts a value of type number and updates that value with
    // the `onChange` handler. More specifically, a number value comes in, we format
    // it for display (e.g. 1000 = 1,000), and then reformat the string back to number
    // in the `onChange` handler. This works well for most cases, however, breaks down
    // for cases like "0.", "0.0", and "0.10". In the first two cases, javascript
    // interprets these as just `0`, while with the last one the trailing zero simply
    // gets removed. But if a user is typing, these are completely valid intermediate states.
    // To handle this case, we keep some local state `trailingDecimal` to track the intermediate
    // values. Note: we don't just `<input type="number".../>` because this does not allow
    // use to format the number and we can't wrap characters like other input fields.
    const [trailingDecimal, setTrailingDecimal] = useState<
      string | undefined
    >();
    let responsiveTypography: VariantPerBreakpoint = {
      sm: "display2",
      lg: "display1",
    };
    // This is very subtle, but TextAutoResize only acts like
    // a controlled component if we don't pass it in an undefined
    // value here. Thus, when our value is undefined, we pass in
    // an empty string.
    const displayValue = value !== undefined ? modifier(value) : "";
    const valueLength = !displayValue
      ? placeholder.length
      : displayValue.length;
    if (valueLength > 5) {
      responsiveTypography.sm = "heading1";
    }

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      let targetVal = e.target.value;

      if (targetVal == undefined || targetVal?.length === 0) {
        onChange(undefined);
        return;
      }

      // Handle the case where trailing zeros appear after a non-zero
      // decimal value. E.g. 0.01000. This regex capture "000"
      const trailingZero = targetVal.match(/\.\d*[123456789]+(0+)$/);

      // Handle the initial decimal and any trailing zeros (when decimal
      // value is the equivalent to zero). E.g "0.", "1.00". This regex captures
      // ".0" and ".00" respectively.
      const trailingDecimalZero = targetVal.match(/\d+(\.0*)$/);
      if (trailingZero || trailingDecimalZero) {
        setTrailingDecimal(trailingDecimalZero?.[1] ?? trailingZero?.[1]);
      } else {
        // if no match, then we have a number that can be transformed between
        // string and number without loss.
        setTrailingDecimal(undefined);
      }

      // if the value is a non number, ignore it.
      if (targetVal.match(/[^0-9,.]/g)) {
        return;
      }

      const newValue = reverseModifier(targetVal);

      // catch some formatting edge cases
      if (isNaN(newValue)) {
        return;
      }

      // Javascript supports number up until Number.MAX_SAFE_INTEGER.
      // Beyond that, numbers get rounded in unexpected ways.
      // So we set Number.MAX_SAFE_INTEGER as our max limit and
      // ignore updates that are larger than this. This will be
      // an issue for people making $9,007,199,254,740,991 a year
      // of if the Lower Team asks questions like "What's the distance,
      // in inches, from the earth to the sun?"
      if (newValue > Number.MAX_SAFE_INTEGER) {
        return;
      }
      onChange(newValue);
    };

    return (
      <Base>
        <TextAreaAutosize
          onChange={handleTextAreaChange}
          value={
            trailingDecimal ? `${displayValue}${trailingDecimal}` : displayValue
          }
          css={[
            typography({
              variant: responsiveTypography,
              color: "midnightBlue",
            }),
            { width: "100%" },
          ]}
          placeholder={placeholder}
          ref={ref}
          {...rest}
        />
        {unit && <Unit>/ {unit}</Unit>}
      </Base>
    );
  },
);

export default UnitInput;

import React from "react";
import { Dialog as DialogBase } from "@headlessui/react";
import styled from "styled-components";

import { Button } from "components/base";
import { Close } from "components/base/Icon";
import Transition from "components/base/Transition";
import { transition } from "utils/style";
import { buildMirroredTransition, fade } from "utils/style/transition";

export const modalAppear = buildMirroredTransition({
  transition: {
    transition: "transform 350ms ease",
  },

  start: {
    transform: `translate3d(0, 100%, 0);`,
  },

  end: {
    transform: "translate3d(0, 0, 0);",
  },
});

const Dialog = styled(DialogBase)<any>({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  // centers the modal on desktop
  display: "flex",
  alignItems: "center",
});

const MOBILE_HEIGHT = "95%";
const TABLET_HEIGHT = "65%";

const Container = styled.div(({ theme }) => ({
  position: "absolute",
  overflow: "hidden",
  backgroundColor: theme.colors.white,
  left: 0,
  right: 0,
  height: MOBILE_HEIGHT,
  ...modalAppear,
  transform: "translate3d(0,0,0)",
  boxShadow: theme.boxShadows.modal,
  borderTopLeftRadius: 32,
  borderTopRightRadius: 32,
  boxSizing: "border-box",
  bottom: 0,
  [theme.media.above.sm]: {
    height: TABLET_HEIGHT,
    ...modalAppear,
  },
  [theme.media.above.lg]: {
    width: "70vw",
    maxWidth: 1024,
    margin: "0 auto",
    // Max height will be the view port height with 80px of vertical margin outside the modal
    maxHeight: `calc(100vh - ${2 * theme.spacing[10]}px)`,
    height: 540,
    boxShadow: theme.boxShadows.soft,
    borderRadius: 32,
    bottom: "unset",
    ...transition.fadeUp(),
  },
}));

const Overlay = styled(Dialog.Overlay)(({ theme }) => ({
  backgroundColor: theme.colors.offWhite,
  position: "absolute",
  opacity: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));

const CloseButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: `${theme.spacing[4]}px`,
  right: `${theme.spacing[4]}px`,
}));

interface Props {
  isOpen: boolean;
  /**
   * Called when the modal is closed, either through the `isOpen` prop
   * or via the modal close button.
   */
  onClose?: () => void;
  /**
   * Called after the close transition is complete
   */
  onCloseFinish?: () => void;
  children: React.ReactNode;
  /** className applied to the modal container */
  className?: string;
}

const Modal = ({
  isOpen,
  onClose = () => {},
  onCloseFinish = () => {},
  children,
  className,
}: Props) => {
  return (
    <div>
      <Transition show={isOpen} afterLeave={onCloseFinish}>
        <Dialog open={isOpen} onClose={onClose}>
          <Transition.Child css={fade()}>
            <Overlay />
          </Transition.Child>
          <Transition.Child>
            <Container className={className}>
              <CloseButton
                aria-label="Close"
                variant="circleSecondary"
                onClick={onClose}
              >
                <Close />{" "}
              </CloseButton>
              <div css={{ overflowY: "auto", height: "100%" }}>{children}</div>
            </Container>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Modal;

import { useEffect, useState } from "react";

const useTimeoutState = <T = boolean>(
  ttl: number,
  initVal: T = true as any,
  endVal: T = false as any,
) => {
  const [val, setVal] = useState<T>(initVal);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVal(endVal);
    }, ttl);

    return () => {
      clearTimeout(timeout);
    };
  }, [endVal, ttl]);

  return val;
};

export default useTimeoutState;

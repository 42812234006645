import React, { forwardRef } from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";

import { Theme } from "theme/default";
import { layout, typography } from "utils/style";

interface TrackState {
  index: number;
  value: number | readonly number[];
}

interface StyleProps extends Theme {
  index: number;
}

const StyledSlider = styled(ReactSlider)({
  ...layout.fullWidth,
  height: 24,
});

const StyledThumb = styled.div(({ theme }: StyleProps) => ({
  borderRadius: "50%",
  cursor: "grab",
  width: 24,
  height: 24,
  backgroundColor: theme.colors.primaryBlue,
  boxShadow: "0px 14px 36px rgba(0, 0, 0, 0.15)",
}));

// TODO: Can't figure out how to type `props`.
// @ts-ignore
const Thumb = (props) => <StyledThumb {...props} />;

const StyledTrack = styled.div(({ index, theme }: StyleProps) => ({
  top: 10,
  bottom: 0,
  borderRadius: 32,
  height: 4,
  backgroundColor:
    index === 0 ? theme.colors.primaryBlue : theme.colors.lightBlue,
}));

// TODO: Can't figure out how to type `props`.
// @ts-ignore
const Track = (props, state: TrackState) => (
  <StyledTrack {...props} index={state.index} />
);

export interface Props {
  min: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
  value?: number;
  valueSuffix?: string;
  className?: string;
}
// the react-slider types don't make it easy to specify the type of the
// ref. Using any as an escape hatch. We need the ref in order to manually
// call 'resize' on the Slider when it is used in element that are transitioned
// into.
const Slider = forwardRef<any, Props>(
  ({ min, max, step, onChange, value, valueSuffix, className }: Props, ref) => {
    return (
      <div css={layout.flexVertical} className={className}>
        <span css={typography({ variant: { md: "display1", sm: "display2" } })}>
          {value && value >= max ? `${value}+` : value}
        </span>
        <span
          css={[
            typography({
              variant: { md: "heading3", sm: "heading6" },
              color: "midnightBlue",
            }),
            { opacity: 0.5, marginBottom: 48 },
          ]}
        >
          {valueSuffix}
        </span>
        <StyledSlider
          ref={ref}
          min={min}
          max={max}
          step={step}
          // Slider supports multiple values (multiple thumbs), but we don't
          // utilize this functionality because we restrict the value to be a `number`
          // Slider typing should handle this, however, it does not, so we ignore.
          //@ts-ignore
          onChange={onChange}
          renderThumb={Thumb}
          renderTrack={Track}
          value={value}
        />
      </div>
    );
  },
);

export default Slider;

import { rem } from "polished";

export const variants = [
  "body",
  "bodySmallItalic",
  "bodyBold",
  "bodyAccent",
  "callToAction",
  "tidbit",
  "bodyLarge",
  "bodyLargeBold",
  "heading1",
  "heading2",
  "heading3",
  "heading4",
  "heading5",
  "heading6",
  "display1",
  "display2",
  "quiz",
] as const;

export type fontVariants = typeof variants[number];

const GothamBook = "Gotham Book";
const GothamBold = "Gotham Bold";
const GothamMedium = "Gotham Medium";
const Merriweather = "Merriweather";

const allFamilies = [GothamBook, GothamBold, GothamMedium, Merriweather];
type allFontFamilies = typeof allFamilies[number];

export const fontFamilies: { [K in fontVariants]: allFontFamilies } = {
  body: GothamBook,
  bodySmallItalic: GothamBook,
  bodyBold: GothamBold,
  bodyAccent: Merriweather,
  callToAction: GothamBold,
  tidbit: GothamBold,
  bodyLarge: GothamBook,
  bodyLargeBold: GothamBold,
  heading1: GothamBold,
  heading2: GothamBold,
  heading3: GothamBold,
  heading4: GothamMedium,
  heading5: GothamMedium,
  heading6: GothamMedium,
  display1: GothamBold,
  display2: GothamBold,
  quiz: GothamBold,
} as const;

export const fontSizes: { [K in fontVariants]: string } = {
  body: rem(14),
  bodySmallItalic: rem(12),
  bodyBold: rem(14),
  bodyAccent: rem(14),
  callToAction: rem(12),
  tidbit: rem(12),
  bodyLarge: rem(16),
  bodyLargeBold: rem(16),
  heading1: rem(40),
  heading2: rem(32),
  heading3: rem(26),
  heading4: rem(18),
  heading5: rem(16),
  heading6: rem(15),
  display1: rem(128),
  display2: rem(72),
  quiz: rem(22),
} as const;

export const fontWeights: { [K in fontVariants]: "bold" | "normal" | number } =
  {
    body: 400,
    bodySmallItalic: 400,
    bodyBold: 400,
    bodyAccent: 400,
    callToAction: 400,
    tidbit: 400,
    bodyLarge: 400,
    bodyLargeBold: 400,
    heading1: 400,
    heading2: 400,
    heading3: 400,
    heading4: 400,
    heading5: 400,
    heading6: 400,
    display1: 400,
    display2: 400,
    quiz: 400,
  } as const;

export const fontStyles: { [K in fontVariants]: string } = {
  body: "normal",
  bodySmallItalic: "italic",
  bodyBold: "normal",
  bodyAccent: "italic",
  callToAction: "normal",
  tidbit: "normal",
  bodyLarge: "normal",
  bodyLargeBold: "normal",
  heading1: "normal",
  heading2: "normal",
  heading3: "normal",
  heading4: "normal",
  heading5: "normal",
  heading6: "normal",
  display1: "normal",
  display2: "normal",
  quiz: "normal",
} as const;

export const fontLineHeights: { [K in fontVariants]: string } = {
  body: rem(22),
  bodySmallItalic: rem(14.4),
  bodyBold: rem(22),
  bodyAccent: rem(17.6),
  callToAction: rem(12),
  tidbit: rem(24),
  bodyLarge: rem(24),
  bodyLargeBold: rem(24),
  heading1: rem(42),
  heading2: rem(36),
  heading3: rem(32),
  heading4: rem(22),
  heading5: rem(22),
  heading6: rem(22),
  display1: rem(128),
  display2: rem(80),
  quiz: rem(26),
};

export const fontLetterSpacing: { [K in fontVariants]: "normal" | number } = {
  body: "normal",
  bodySmallItalic: -0.2,
  bodyBold: "normal",
  bodyAccent: -0.29,
  callToAction: 1,
  tidbit: "normal",
  bodyLarge: "normal",
  bodyLargeBold: "normal",
  heading1: -0.56,
  heading2: -0.89,
  heading3: -0.4,
  heading4: -0.5,
  heading5: -0.25,
  heading6: -0.25,
  display1: -8,
  display2: -5,
  quiz: -0.45,
};

export const fonts = {
  families: fontFamilies,
  letterSpacing: fontLetterSpacing,
  lineHeights: fontLineHeights,
  sizes: fontSizes,
  styles: fontStyles,
  weights: fontWeights,
};

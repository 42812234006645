/* global google */
import { useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
interface GeocoderData {
  city: string;
  state: string;
  lat: number;
  lng: number;
}

interface GeocoderError {
  message: string;
}

/**
 * Using a US zipcode, will return the city/state and lat/lng.
 */
const useGeocoder = (zip: number | string) => {
  const loader = new Loader({
    apiKey: `${API_KEY}`,
    version: "weekly",
  });

  const [data, setData] = useState<GeocoderData | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setData(undefined);
    setError(undefined);
    setLoading(true);

    try {
      await loader.load();
      const geocoder = new google.maps.Geocoder();

      /**
       * N.B.: Considerable time has been spent trying to handle the case
       * where the Google Map API throws an exception within its callback.
       * As far as we can tell, we can't catch this. When it happens, it's
       * a catastrophic error. The browser needs a full refresh.
       *
       * This case should rarely happen, if ever, but when development
       * was happening we had it for referrer errors.
       *
       * More resources:
       * https://developers.google.com/maps/documentation/javascript/events#auth-errors
       * https://stackoverflow.com/questions/3677783/is-it-possible-to-catch-exceptions-thrown-in-a-javascript-async-callback/17254338
       */
      const { results } = await geocoder.geocode({ address: `${zip},US` });

      if (results && results[0]) {
        const locality = results[0].address_components.find((comp) =>
          comp.types.includes("locality"),
        );
        const administrativeArea = results[0].address_components.find((comp) =>
          comp.types.includes("administrative_area_level_1"),
        );
        const city = locality ? locality.long_name : "Unknown";
        const state = administrativeArea
          ? administrativeArea.short_name
          : "Unknown";
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        const data = {
          city,
          state,
          lat,
          lng,
        };

        setData(data);
        setError(undefined);
      } else {
        setError("No results found.");
      }
    } catch (e) {
      setError((e as GeocoderError).message);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetch: fetchData };
};

export default useGeocoder;

import React from "react";
import { createPortal } from "react-dom";
import { Dialog as DialogBase } from "@headlessui/react";
import styled, { CSSObject } from "styled-components";

import Button from "components/base/Button";
import { Close, ExpandLess } from "components/base/Icon";
import PulseButton from "components/base/PulseButton";
import Transition from "components/base/Transition";
import { useDelayedSpring } from "hooks/useDelayedSpring";
import { Theme } from "theme/default";
import { layout } from "utils/style";
import { buildMirroredTransition, fade } from "utils/style/transition";

const DRAWER_HEADER_HEIGHT = 100;
// below the fold is the white area below the unexpanded drawer which is
// normally not visible. However, when we use the spring animation to pop
// show the drawer for the first time, this empty area will appear while
// the animation "springs" into action.
const DRAWER_HEADER_BELOW_FOLD = 100;

export const drawerAppear = buildMirroredTransition({
  transition: {
    transition: "all 350ms ease",
  },

  start: {
    transform: `translate3d(0, calc(100% - ${DRAWER_HEADER_HEIGHT}px), 0);`,
  },

  end: {
    transform: "translate3d(0, 0, 0);",
  },
});

const Dialog = styled(DialogBase)<any>({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
});

const drawerPadding = ({ theme }: Theme) => ({
  ...layout.padding(5, 5, 0, 6)({ theme }),
  [theme.media.above.sm]: {
    ...layout.padding(5, 6, 0, 10)({ theme }),
  },
});

const Container = styled.div<{ $height: string }>(({ theme, $height }) => ({
  position: "absolute",
  overflow: "hidden",
  backgroundColor: theme.colors.white,
  left: 0,
  right: 0,
  height: $height,
  transform: "translate3d(0,0,0)",
  boxShadow: theme.boxShadows.modal,
  borderTopLeftRadius: "32px",
  borderTopRightRadius: "32px",
  bottom: 0,
}));

const Content = styled.div(drawerPadding, ({ theme }) => ({
  overflowY: "auto",
  height: `calc(100% - ${DRAWER_HEADER_HEIGHT}px)`,
  paddingTop: theme.spacing[0],
}));

type HeaderVariant = "expanded" | "closed";
interface HeaderBaseProps {
  translateY?: number;
  variant: HeaderVariant;
}
const HeaderBase = styled.div.attrs(({ translateY = 0 }: HeaderBaseProps) => ({
  style: {
    transform: `translateY(${translateY}px)`,
  },
}))<HeaderBaseProps>(drawerPadding, ({ theme, variant }) => {
  const commonCss: CSSObject = {
    display: "flex",
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: "32px",
    borderTopRightRadius: "32px",
    boxSizing: "border-box",
  };
  const closedVariant: CSSObject = {
    ...commonCss,
    position: "fixed",
    bottom: -DRAWER_HEADER_BELOW_FOLD,
    left: 0,
    right: 0,
    overflow: "hidden",
    height: DRAWER_HEADER_HEIGHT + DRAWER_HEADER_BELOW_FOLD,
    boxShadow: theme.boxShadows.modal,
    paddingBottom: DRAWER_HEADER_BELOW_FOLD + theme.spacing[6],
    [theme.media.above.sm]: {
      paddingBottom: DRAWER_HEADER_BELOW_FOLD + theme.spacing[6],
    },
  };

  const expandedVariant: CSSObject = {
    ...commonCss,
    height: DRAWER_HEADER_HEIGHT,
    boxShadow: "none",
  };

  return variant === "expanded" ? expandedVariant : closedVariant;
});

const Overlay = styled(Dialog.Overlay)(({ theme }) => ({
  backgroundColor: theme.colors.offWhite,
  position: "absolute",
  opacity: 0.6,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));

interface HeaderProps {
  children: React.ReactNode;
  onToggle: () => void;
  pulse?: boolean;
  variant: HeaderVariant;
  translateY?: number;
  className?: string;
}

const Header = ({
  children,
  pulse,
  variant,
  onToggle,
  translateY,
  className,
}: HeaderProps) => (
  <HeaderBase variant={variant} className={className} translateY={translateY}>
    <div css={{ flex: 1 }}>{children}</div>
    {variant === "closed" ? (
      <PulseButton
        css={{ flex: "0 0 auto" }}
        enabled={pulse}
        onClick={onToggle}
        aria-label="Open"
      >
        <ExpandLess />
      </PulseButton>
    ) : (
      <Button
        css={{ flex: "0 0 auto" }}
        onClick={onToggle}
        variant="circleSecondary"
        aria-label="Close"
      >
        <Close />
      </Button>
    )}
  </HeaderBase>
);

export interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  spring?: boolean;
  springDelay?: number;
  pulse?: boolean;
  height?: string;
  onOpen?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
}
const Drawer = ({
  header,
  children,
  height = "95%",
  spring,
  springDelay = 0,
  pulse,
  isOpen = false,
  onOpen = () => {},
  onClose = () => {},
}: Props) => {
  const springValue = useDelayedSpring({
    enabled: spring,
    delayMs: springDelay,
    startValue: DRAWER_HEADER_HEIGHT,
    endValue: 0,
    tension: 20,
  });

  const handleToggle = () => {
    if (!isOpen) {
      onOpen();
    } else {
      onClose();
    }
  };
  return (
    <div>
      {createPortal(
        <Header
          translateY={spring ? springValue : 0}
          pulse={pulse}
          variant="closed"
          onToggle={handleToggle}
        >
          {header}
        </Header>,
        document.body,
      )}
      <Transition show={isOpen}>
        <Dialog open={isOpen} onClose={handleToggle}>
          <Transition.Child css={fade()}>
            <Overlay />
          </Transition.Child>
          <Transition.Child css={drawerAppear}>
            <Container $height={height}>
              <Header variant="expanded" onToggle={handleToggle}>
                {header}
              </Header>
              <Content>{children}</Content>
            </Container>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Drawer;

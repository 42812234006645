/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Answer,
  AssessmentDetails,
  CreateAssessmentDetailsCommand,
  PurchaseAssessmentSummary,
  RefinanceAssessmentSummary,
  ScoreGradeRange,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Assessments<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name EmailOnlySubmissionCreate
   * @request POST:/assessments/{id}/emailOnlySubmission
   * @response `200` `any` Success
   */
  emailOnlySubmissionCreate = (id: string, params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/assessments/${id}/emailOnlySubmission`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name AssessmentsDetail
   * @request GET:/assessments/{id}
   * @response `200` `AssessmentDetails` Success
   */
  assessmentsDetail = (id: string, params: RequestParams = {}) =>
    this.request<AssessmentDetails, any>({
      path: `/assessments/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name QuestionsAnswerDelete
   * @request DELETE:/assessments/{id}/questions/{questionKey}/answer
   * @response `200` `void` Success
   */
  questionsAnswerDelete = (
    id: string,
    questionKey: string,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/assessments/${id}/questions/${questionKey}/answer`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name QuestionsAnswerDetail
   * @request GET:/assessments/{id}/questions/{questionKey}/answer
   * @response `200` `Answer` Success
   */
  questionsAnswerDetail = (
    id: string,
    questionKey: string,
    params: RequestParams = {},
  ) =>
    this.request<Answer, any>({
      path: `/assessments/${id}/questions/${questionKey}/answer`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name QuestionsAnswerUpdate
   * @request PUT:/assessments/{id}/questions/{questionKey}/answer
   * @response `200` `any` Success
   */
  questionsAnswerUpdate = (
    id: string,
    questionKey: string,
    data: Answer,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/assessments/${id}/questions/${questionKey}/answer`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name ScoreGradeRangesDetail
   * @request GET:/assessments/{id}/scoreGradeRanges
   * @response `200` `(ScoreGradeRange)[]` Success
   */
  scoreGradeRangesDetail = (id: string, params: RequestParams = {}) =>
    this.request<ScoreGradeRange[], any>({
      path: `/assessments/${id}/scoreGradeRanges`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name SubmissionCreate
   * @request POST:/assessments/{id}/submission/{sendEmail}
   * @response `200` `any` Success
   */
  submissionCreate = (
    id: string,
    sendEmail: boolean,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/assessments/${id}/submission/${sendEmail}`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name PurchaseSummaryDetail
   * @request GET:/assessments/{id}/purchaseSummary
   * @response `200` `PurchaseAssessmentSummary` Success
   */
  purchaseSummaryDetail = (id: string, params: RequestParams = {}) =>
    this.request<PurchaseAssessmentSummary, any>({
      path: `/assessments/${id}/purchaseSummary`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name RefinanceSummaryDetail
   * @request GET:/assessments/{id}/refinanceSummary
   * @response `200` `RefinanceAssessmentSummary` Success
   */
  refinanceSummaryDetail = (id: string, params: RequestParams = {}) =>
    this.request<RefinanceAssessmentSummary, any>({
      path: `/assessments/${id}/refinanceSummary`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AssessmentsApi
   * @name AssessmentsCreate
   * @request POST:/assessments
   * @response `201` `any` Success
   */
  assessmentsCreate = (
    data: CreateAssessmentDetailsCommand,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/assessments`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}

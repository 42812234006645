import { useGoogleMaps } from "react-hook-google-maps";

import { layout } from "utils/style";

export interface Props {
  lat: number;
  lng: number;
}

const Map = ({ lat, lng }: Props) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  if (!apiKey) {
    throw new Error("Missing Google Maps API Key.");
  }

  const { ref } = useGoogleMaps(apiKey, {
    center: { lat, lng },
    zoom: 13,
    disableDefaultUI: true,
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
  });

  return <div ref={ref} css={[layout.fullWidth, layout.fullHeight]} />;
};

export default Map;

import React from "react";
import styled from "styled-components";

import { typography } from "utils/style";

import { TooltipProps } from "./Tooltip.types";

export const borderRadius = 16;

type ArrowProps = { placement: string };

export const Base = styled.div(({ theme }) => {
  return {
    backgroundColor: theme.colors.midnightBlue,
    color: theme.colors.white,
    borderRadius,
    padding: theme.spacing[6],
    maxWidth: 236,
  };
});

export const Arrow = styled.div<ArrowProps>(({ theme, placement }) => {
  const background = theme.colors.midnightBlue;
  const arrowSize = 9;
  // We are using a CSS triangle to render the arrow. Depending on the border styles it will
  // change which direction the arrow is pointing. We position the arrow outside of the box using
  // the negative positioning values.
  let placementStyles = {};

  if (placement.match(/^top/)) {
    placementStyles = {
      borderColor: `${background} transparent transparent transparent`,
      borderWidth: `${arrowSize}px ${arrowSize}px 0 ${arrowSize}px`,
      bottom: -arrowSize,
    };
  } else if (placement.match(/^bottom/)) {
    placementStyles = {
      borderColor: `transparent transparent ${background} transparent`,
      borderWidth: `0 ${arrowSize}px ${arrowSize}px ${arrowSize}px`,
      top: -arrowSize,
    };
  }

  return {
    width: 0,
    height: 0,
    borderStyle: "solid",

    ...placementStyles,
  };
});

/**
 * The Tooltip component represents the **visual tooltip**. It should be rendered inside of a TooltipController.
 */
const Tooltip = ({ className, children, arrow, placement }: TooltipProps) => {
  let child = children;

  // Allow us to easily and consistently render a plain text child
  if (typeof children === "string") {
    child = (
      <span
        css={typography({ variant: "body", color: "white" })}
        aria-live="polite"
      >
        {children}
      </span>
    );
  }

  return (
    <Base className={className}>
      {child}
      <Arrow
        ref={arrow.setRef}
        style={arrow.styles}
        aria-hidden
        placement={placement}
      />
    </Base>
  );
};

export default Tooltip;

import { breakpoints, media } from "theme/default/breakpoints";

import { boxShadows } from "./boxShadows";
import { categoryTheme } from "./category";
import { colors } from "./colors";
import { fonts, fontVariants } from "./fonts";
import { spacing } from "./spacing";

const theme = {
  categoryTheme,
  colors,
  fonts,
  spacing,
  breakpoints,
  media,
  boxShadows,
} as const;

export interface Theme {
  theme: typeof theme;
}

export type CategoryTheme = keyof typeof categoryTheme;
export type Colors = keyof typeof colors;
export type Spacings = keyof typeof spacing;
export type FontVariants = fontVariants;
export type Breakpoints = keyof typeof breakpoints;
export type BoxShadows = keyof typeof boxShadows;

export default theme;

import { ScoreGradeRange } from "api/generated/data-contracts";

/**
 * The API uses 0-100 for their scores, but we use 0 - 1.0 for ours, so this
 * aligns all the scores with our formatting.
 *
 * This also ensures that `minScore`/`maxScore` always have a value.
 */
export const formatScoreGrades = (scoreGrades: ScoreGradeRange[]) => {
  return scoreGrades.map((scoreGrade: ScoreGradeRange) => {
    return {
      ...scoreGrade,
      minScore: (scoreGrade.minScore ?? 0) / 100,
      maxScore: (scoreGrade.maxScore ?? 100) / 100,
    };
  });
};

/**
 * Given a score range between 0 and 1.0, it will return the ScoreGradeRange that
 * fits within the score range.
 */
export const getScoreGradeRange = (
  score: number,
  scoreGradesRanges: ScoreGradeRange[],
) => {
  const formattedScoreGradeRanges = formatScoreGrades(scoreGradesRanges);
  return formattedScoreGradeRanges.find((scoreGrade) => {
    return score >= scoreGrade.minScore && score < scoreGrade.maxScore;
  });
};

import { useState } from "react";
import { useTimeoutFn } from "react-use";
import useSpring from "react-use/lib/useSpring";

/**
 * Applies a spring function for values between a start and end point over
 * and unspecified period of time, re-rendering on each update.  If a delay
 * is given, the triggering of the spring to be delayed for that period of time.
 *
 * If not enabled, the spring will not be triggered and return the startVal.
 */
export const useDelayedSpring = ({
  enabled,
  delayMs = 0,
  startValue,
  endValue,
  friction,
  tension,
}: {
  enabled?: boolean;
  delayMs?: number;
  startValue: number;
  endValue: number;
  friction?: number;
  tension?: number;
}) => {
  const [springAnchor, setSpringAnchor] = useState(startValue);
  const springValue = useSpring(springAnchor, tension, friction);
  useTimeoutFn(() => {
    if (enabled) setSpringAnchor(endValue);
  }, delayMs);
  return springValue;
};

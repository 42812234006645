import { layout } from "utils/style";

export interface Props {
  message?: string;
  className?: string;
}

const DEFAULT_MSG =
  "There was an error retrieving data. Please try again later.";

const GenericError = ({ className, message = DEFAULT_MSG }: Props) => (
  <div
    className={className}
    css={[layout.flexCenter, layout.fullHeight, layout.padding(6)]}
  >
    {message}
  </div>
);

export default GenericError;

import styled from "styled-components";

import { Button as ButtonBase, Transition } from "components/base";
import { layout, typography } from "utils/style";
import { fadeUp } from "utils/style/transition";

import FiveStarReviewsBlurb from "./FiveStarReviewsBlurb";

const Base = styled.div(
  layout.flexVertical,
  layout.fullHeight,
  ({ theme }) => ({
    justifyContent: "space-between",
    paddingTop: 80,

    [theme.media.above.sm]: {
      flexDirection: "row",
      padding: 0,
    },
  }),
);

const Title = styled.h2(typography({ variant: "heading3" }), ({ theme }) => ({
  marginBottom: theme.spacing[4],

  span: {
    color: theme.colors.primaryBlue,
  },

  [theme.media.above.sm]: {
    maxWidth: 310,
  },
}));

const Content = styled.p(
  typography({ variant: { sm: "body", md: "bodyLarge" } }),
  ({ theme }) => ({
    [theme.media.above.sm]: {
      maxWidth: 310,
    },
  }),
);

const ButtonWrapper = styled.div(({ theme }) => ({
  position: "absolute",
  left: theme.spacing[6],
  right: theme.spacing[6],
  bottom: theme.spacing[10],

  [theme.media.above.sm]: {
    maxWidth: 400,
    left: 48,
    right: 48,
  },
  [theme.media.above.lg]: {
    left: 90,
    right: 0,
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  ...layout.fullWidth,

  [theme.media.above.sm]: {
    width: "auto",
  },
}));

const ContentContainer = styled.div(
  layout.paddingHorizontal(6),
  ({ theme }) => ({
    [theme.media.above.sm]: {
      paddingLeft: 48,
      paddingTop: 24,
    },

    [theme.media.above.lg]: {
      paddingLeft: 80,
      paddingTop: 56,
    },
  }),
);

interface Props {
  onNextClick: () => void;
}

const StartingModal = ({ onNextClick }: Props) => {
  return (
    <Base>
      <ContentContainer>
        <Title>
          The home advice you need to make <span>money moves</span>.
        </Title>
        <Transition show={true} appear={true} css={fadeUp(400, 400, 400)}>
          <Content>
            Renters get a HomeReadiness Score. Homeowners get a RefiReadiness
            score. Either way, know you&apos;re making a good decision in buying
            your first home, refinancing your current home, or even taking cash
            out. Ready? Let&apos;s go.
          </Content>
        </Transition>
      </ContentContainer>
      <FiveStarReviewsBlurb animationDelay={400} />
      <ButtonWrapper>
        <Transition show={true} appear={true} css={fadeUp(800, 400, 400)}>
          <Button variant="primary" onClick={onNextClick}>
            Next
          </Button>
        </Transition>
      </ButtonWrapper>
    </Base>
  );
};

export default StartingModal;

import React from "react";
import styled from "styled-components";

import * as Icons from "components/base/Icon";
import ProgressBar from "components/base/ProgressBar";
import { useTweenPrevious } from "hooks/useTweenPrevious";
import theme, { CategoryTheme } from "theme/default";
import prettyPercent from "utils/prettyPercent";
import { layout, typography } from "utils/style";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  categoryTheme?: CategoryTheme;
  percent: number;
  title: string;
  className?: string;
  tweenDuration?: number;
}

const TRANSITION = "all 100ms ease-in-out";

/**
 * Styled components for the Category are created using a "desktop-first"
 * pattern which is opposite of other components using a "mobile-first"
 * pattern. This is because we want to add the ability for tablet+ screens
 * to control the size of the category element using the variant prop.
 */
const Base = styled.div(layout.flexVertical, ({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: 18,
  boxShadow: theme.boxShadows.card,
  minHeight: 120,
  padding: theme.spacing[4],
  transition: TRANSITION,

  [theme.media.above.sm]: {
    minHeight: 180,
  },
}));

const IconWrapper = styled.div<{
  categoryTheme: CategoryTheme;
}>(layout.flexCenter, ({ categoryTheme, theme }) => ({
  backgroundColor: theme.categoryTheme[categoryTheme].secondaryColor,
  borderRadius: "50%",
  color: theme.categoryTheme[categoryTheme].primaryColor,
  height: 40,
  transition: TRANSITION,
  width: 40,

  [`& > *`]: {
    width: `18px!important`,
    height: `18px!important`,
    transition: TRANSITION,
  },

  [theme.media.above.sm]: {
    width: 60,
    height: 60,

    [`& > *`]: {
      width: `24px!important`,
      height: `24px!important`,
    },
  },
}));

const Category = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      categoryTheme = "AboutYellowEnum",
      percent,
      title,
      tweenDuration = 0,
      ...rest
    }: Props,
    ref,
  ) => {
    const tweenPercent = useTweenPrevious(percent, tweenDuration);
    const Icon = Icons[theme.categoryTheme[categoryTheme].icon];

    return (
      <Base {...rest} ref={ref}>
        <IconWrapper categoryTheme={categoryTheme}>
          <Icon />
        </IconWrapper>
        <div css={[layout.flexVertical, { marginTop: "auto" }]}>
          <div css={[layout.flexStart, { marginBottom: 8 }]}>
            <div
              css={typography({
                variant: {
                  sm: "tidbit",
                  md: "heading4",
                },
              })}
            >
              {title}
            </div>
            <div
              css={[
                typography({ variant: "bodySmallItalic" }),
                { marginLeft: "auto", marginTop: "auto" },
              ]}
            >
              {prettyPercent(tweenPercent)}%
            </div>
          </div>
          <ProgressBar
            percent={percent}
            transitionDuration={tweenDuration}
            categoryTheme={categoryTheme}
          />
        </div>
      </Base>
    );
  },
);

export default Category;

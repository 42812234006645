import { transparentize } from "polished";
import styled from "styled-components";

import { CategoryTheme } from "theme/default";
import prettyPercent from "utils/prettyPercent";
import { layout } from "utils/style";

const Base = styled.div<{ categoryTheme: CategoryTheme }>(({ theme }) => {
  return {
    backgroundColor: transparentize(0.5, theme.colors.lightGrey),
    borderRadius: 100,
    height: 4,
    transition: "background 0.5s ease-in-out",
  };
});

const Bar = styled.div<Props>(
  ({ categoryTheme, percent, transitionDuration, theme }) => {
    return {
      backgroundColor: theme.categoryTheme[categoryTheme].primaryColor,
      borderRadius: 100,
      height: "100%",
      maxWidth: "100%",
      minWidth: 0,
      transition: `width ${transitionDuration ?? 250}ms ease-in-out`,
      width: `calc(100% * ${percent})`,
    };
  },
);

interface Props {
  categoryTheme: CategoryTheme;
  percent: number;
  transitionDuration?: number;
  className?: string;
}

const ProgressBar = ({
  percent,
  transitionDuration,
  categoryTheme,
  className,
}: Props) => (
  <Base categoryTheme={categoryTheme} className={className}>
    <Bar
      transitionDuration={transitionDuration}
      percent={percent}
      categoryTheme={categoryTheme}
    />
    <span css={layout.visuallyHidden}>
      {prettyPercent(percent)} percent complete
    </span>
  </Base>
);

export default ProgressBar;

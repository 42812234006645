import React, { useState } from "react";
import { usePopper } from "react-popper";
import { Popover } from "@headlessui/react";
import styled from "styled-components";

import Tooltip, { borderRadius } from "./Tooltip";
import { ControllerProps } from "./Tooltip.types";
import { PopoverPanelProps } from "./Tooltip.types";

// ====================================
// HeadlessUI Styled Wrappers
// ====================================

// HeadlessUI + StyledComponents + TypeScript do not play well with eachother, and I can't figure out
// how to get the proptypes from HeadlessUI to pass them to our StyledComponent.

const PopoverButton = styled(Popover.Button)({});
const PopoverPanel = styled(Popover.Panel)<PopoverPanelProps>({
  // Hide the tooltip if the anchor scrolls off screen
  "&[data-popper-reference-hidden='true']": {
    visibility: "hidden",
    pointerEvents: "none",
  },
});

// We don't need to do any fancy typing with PopverWrapper since it doesn't accept any custom props
const PopoverWrapper = styled(Popover)<{ $open: boolean }>(({ $open }) => {
  if ($open) {
    return {
      [`& ${PopoverPanel}`]: {
        display: "block",
      },
    };
  } else {
    return {
      [`& ${PopoverPanel}`]: {
        display: "none",
      },
    };
  }
});

type LocationConfig = {
  arrowPadding: number;
  offset: [number, number];
};

/**
 * Render a Tooltip and an Anchor that will show and hide the Tooltip. This component handles its own
 * state for open/close state when isStatic is now set to true. It will reposition the tooltip if it is trying to be rendered off screen
 * and will follow the anchor element while scrolling.
 */
const TooltipController = ({
  offset,
  location = "bottom",
  forwardedAs,
  anchor,
  children,
  open,
  isStatic = false,
  className,
}: ControllerProps) => {
  const [isSmall, setIsSmall] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const popperConfig: LocationConfig = {
    // Setting `arrowPadding` to 16 makes it so the arrow won't scroll past the tooltip body if
    // the tooltip scrolls off the edge of the screen. Note: we use this in combination with
    // preventOverflow.tetherOffset to ensure that the arrow stays centred on small, single line tooltips
    arrowPadding: borderRadius,
    offset: [0, 11 + (offset ?? 0)],
  };

  const { styles, attributes, state } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: "fixed",
      modifiers: [
        {
          name: "arrow",
          options: {
            element: arrowElement,
            /**
             * # From popper docs:
             * If you don't want the arrow to reach the very edge of the popper (this is common
             * if your popper has rounded corners using border-radius), you can apply some padding to it.
             * ------------------------
             * Small tooltips are only a single line, so if we applied padding the arrow would look super
             * janky because it wouldn't actually touch the tooltip (try tweaking the value if you're curious)
             */
            padding: popperConfig.arrowPadding,
          },
        },
        {
          name: "offset",
          options: {
            /**
             * Offset allows us to manually move the tooltip in relation to the anchor. `offset` is
             * a tuple with two elements.
             *
             * The first element of the tuple will move the tooltip parallel to the anchor. So in the
             * example of a tooltip that is positioned to the `top` of the anchor it changing the first
             * value will move the tooltip to the left or right.
             *
             * The second element in the tuple will move the tooltip perpendicular to the anchor. If
             * the tooltip is positioned to the `top` of the anchor changing the second value will
             * result in the tooltip moving up and down: away from/towards the anchor.
             */
            offset: popperConfig.offset,
          },
        },
        {
          name: "preventOverflow",
          options: {
            // Set the tetherOffset to 16 so that the arrow won't overlap with the border radius.
            // To see the effect try setting it to 0 and scroll a **single line** right or left tooltip
            // off the bottom/top of the screen.
            tetherOffset: borderRadius,
            // Give the tooltip some padding so it doesn't touch the edge of the screen.
            altAxis: true,
            padding: 16,
          },
        },
      ],
      // The default location to try rendering the tooltip to
      placement: location,
    },
  );

  // We need to measure the popper element to figure out if it's a one line tooltip or not. If it's
  // a one-liner we need to tweak the position of the arrow.
  const setPopoverRef = (ref: HTMLDivElement | null) => {
    setPopperElement(ref);
    const height = ref?.getBoundingClientRect().height;
    if (height) {
      setIsSmall(height < 50);
    }
  };

  // We want to support Anchor children that are functions that accept a `setRef` so that we can
  // have fine grained control over which part of the Anchor the tooltip focuses on.
  let anchorChild;
  const ariaAttrs = { "aria-haspopup": true };

  if (typeof anchor === "function") {
    anchorChild = anchor(setReferenceElement);
    anchorChild = React.cloneElement(anchorChild, ariaAttrs);
  } else {
    anchorChild = React.cloneElement(anchor, {
      ...ariaAttrs,
      ref: setReferenceElement,
    });
  }

  return (
    <PopoverWrapper forwardedAs={forwardedAs} $open={isStatic ? open : true}>
      {anchorChild}
      <PopoverPanel
        ref={setPopoverRef}
        style={{ ...styles.popper, zIndex: 1 }}
        {...attributes.popper}
        focus
        static={isStatic}
      >
        <Tooltip
          className={className}
          arrow={{ styles: styles.arrow, setRef: setArrowElement }}
          placement={state?.placement || location}
          isSmall={isSmall}
        >
          {children}
        </Tooltip>
      </PopoverPanel>
    </PopoverWrapper>
  );
};

TooltipController.Anchor = PopoverButton;

export default TooltipController;

import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import didYouKnowBgLg from "assets/images/DidYouKnowBgLg.svg";
import didYouKnowBgMd from "assets/images/DidYouKnowBgMd.svg";
import didYouKnowBgSm from "assets/images/DidYouKnowBgSm.svg";
import { Transition } from "components/base";
import { Info } from "components/base/Icon";
import RichText from "components/base/RichText";
import { layout, typography } from "utils/style";
import { fade, fadeUp } from "utils/style/transition";

const Base = styled.div<{ variant: StepFooterVariants; opacity?: number }>(
  layout.flexVertical,
  ({ theme, variant, opacity = 1 }) => {
    const responsiveActionable =
      variant === "actionableInterstitial"
        ? ({
            [theme.media.above.sm]: {
              position: "absolute",
              marginLeft: "auto",
              backgroundImage: `url(${didYouKnowBgMd})`,
              backgroundPosition: "bottom right",
              right: 0,
              width: 434,
              height: 361,
            },

            [theme.media.above.lg]: {
              backgroundImage: `url(${didYouKnowBgLg})`,
              height: 396,
              width: 521,
            },
          } as const)
        : {};

    return {
      pointerEvents: "none",
      transition: "100ms opacity ease-in",
      opacity,
      alignItems: "center",
      backgroundImage: `url(${didYouKnowBgSm})`,
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      bottom: 0,
      [theme.media.below.md]: {
        marginTop: 24,
        left: 0,
        right: 0,
      },
      ...responsiveActionable,
    };
  },
);

const Content = styled.div<{ variant: StepFooterVariants }>(
  layout.flexVertical,
  ({ theme, variant }) => ({
    alignItems: "center",

    marginTop: 70,
    paddingBottom: 86,

    [theme.media.above.sm]:
      variant === "actionableInterstitial"
        ? {
            marginTop: "auto",
            marginLeft: "auto",
            marginRight: theme.spacing[9],
            paddingBottom: 70,
          }
        : {},
  }),
);

const Title = styled.h3<{ variant: StepFooterVariants }>(
  typography({
    variant: "heading6",
    color: "primaryBlue",
  }),
  ({ theme, variant }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing[4],

    [theme.media.above.sm]:
      variant === "actionableInterstitial"
        ? {
            marginTop: "auto",
          }
        : {},
  }),
);

const Body = styled(RichText)<{ variant: StepFooterVariants }>(
  typography({ variant: { sm: "heading4", md: "heading3", xl: "heading2" } }),
  ({ theme, variant }) => ({
    margin: `0 15%`,
    textAlign: "center",

    [theme.media.above.sm]: {
      margin: 0,
      maxWidth: variant === "actionableInterstitial" ? 260 : 598,
    },

    [theme.media.above.lg]: {
      maxWidth: variant === "actionableInterstitial" ? 315 : 598,
    },
  }),
);

const ScrollOverlay = styled.div<{
  ratioVisible?: number;
}>(({ ratioVisible = 1 }) => ({
  position: "fixed",
  bottom: 0,
  width: "100%",
  height: 50,
  // as the ratioVisible becomes greater, we fade the effect of the linear gradient overlay.
  opacity: 1 - ratioVisible,
  background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,${0}) 100%)`,
}));

type StepFooterVariants = "question" | "actionableInterstitial";
export interface Props {
  animationDelay?: number;
  title?: string;
  body?: string;
  variant?: StepFooterVariants;
  className?: string;
}

const Footer = ({
  title,
  body,
  animationDelay = 0,
  variant = "actionableInterstitial",
  className,
}: Props) => {
  const { ref, entry } = useInView({
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  });
  // We use the interactionRatio as a basis for the opacity we apply
  // to the footer when it is not completely visible, clamping it to
  // have a minimum opacity of 0.6. This, combined with the linear
  // gradient in `ScrollOverlay`, provide the "scroll to see more" effect
  // on the footer when not completely visible.
  let opacity = Math.max(0.6, entry?.intersectionRatio || 0);

  return (
    <Transition appear={true} show={true} css={fade(animationDelay)}>
      <Base opacity={opacity} ref={ref} variant={variant} className={className}>
        <Content variant={variant}>
          <Transition.Child css={fadeUp(animationDelay + 400)}>
            <Title variant={variant}>
              <Info size={16} color="primaryBlue" css={{ marginRight: 4 }} />
              {title ?? "Did you know?"}
            </Title>
          </Transition.Child>
          <Transition.Child css={fadeUp(animationDelay + 400, 1, 400)}>
            <Body variant={variant} html={body || ""} />
          </Transition.Child>
        </Content>
        <ScrollOverlay ratioVisible={entry?.intersectionRatio} />
      </Base>
    </Transition>
  );
};

export default Footer;

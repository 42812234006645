import React, { forwardRef } from "react";
import { TextareaAutosizeProps } from "react-textarea-autosize";

import TextAreaAutosize from "components/base/TextAreaAutosize";
import { typography } from "utils/style";
import { VariantPerBreakpoint } from "utils/style/typography";

export interface Props extends TextareaAutosizeProps {
  // TextareaAutosize has typed value as a string | number | string[]. We are only concerned about the string case.
  value?: string;
  className?: string;
}

export const DEFAULT_PLACEHOLDER = "type here";

/**
 * This component renders a textarea element which resizes its height based on the content. On mobile, font size is
 * dynamic based on the amount of characters the input value have. This is done to keep the characters on a single
 * line, though if the text does extend the boundary of the input box, it will wrap to the next row.
 */
const ShortTextInput = forwardRef(
  (
    { value, placeholder = DEFAULT_PLACEHOLDER, ...rest }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>,
  ) => {
    let responsiveTypography: VariantPerBreakpoint = {
      sm: "heading1",
      md: "display2",
      xl: "display1",
    };

    const valueLength = !value ? placeholder.length : value.length;

    if (valueLength >= 18) {
      responsiveTypography = {
        sm: "heading3",
        md: "heading2",
        xl: "heading1",
      };
    } else if (valueLength >= 12) {
      responsiveTypography = {
        sm: "heading2",
        md: "heading1",
        xl: "display2",
      };
    }

    return (
      <TextAreaAutosize
        {...rest}
        ref={ref}
        css={[
          typography({
            variant: responsiveTypography,
            color: "midnightBlue",
          }),
          { width: "100%" },
        ]}
        placeholder={placeholder}
        value={value}
      />
    );
  },
);

export default ShortTextInput;

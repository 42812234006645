import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Answer } from "api/generated/data-contracts";
import { UnitInput as UnitInputBase } from "components/base";
import AdvanceButton from "components/pages/CategoryQuiz/components/AdvanceButton";
import QuestionContainer from "components/pages/CategoryQuiz/components/QuestionContainer";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import QuestionError from "components/pages/CategoryQuiz/components/QuestionError";
import QuestionText from "components/pages/CategoryQuiz/components/QuestionText";
import { NumericAnswerType, NumericQuestionType } from "types/api-extensions";

export const UnitInput = styled(UnitInputBase)(({ theme }) => ({
  marginTop: 60,
  [theme.media.above.sm]: {
    marginTop: 100,
  },
  [theme.media.above.lg]: {
    marginTop: 160,
  },
}));

export interface Props {
  question: NumericQuestionType;
  answer?: NumericAnswerType;
  onSubmit: (answer: Answer) => void;
  isSubmitting?: boolean;
  className?: string;
  footer?: React.ReactNode;
}

/**
 * Questions handle mutations and validations
 * Mutation stores in cache. onSubmit called when API
 * called successfully
 */
const NumericInputQuestion = ({
  question,
  answer,
  onSubmit,
  isSubmitting,
  className,
  footer,
}: Props) => {
  const { minAmount, maxAmount, placeholder, unitLabel, defaultValue } =
    question.numericConfiguration;
  const [error, setError] = useState<string | undefined>();
  const [value, setValue] = useState<number | undefined | null>(
    answer?.answerNumeric ?? defaultValue,
  );

  const thisInputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async () => {
    // Check config validations
    // is Required
    if (!value) {
      setError("Required");
      return;
    }

    if (minAmount && minAmount > value) {
      setError(`Value must be greater than ${minAmount - 1}`);
      return;
    }

    if (maxAmount && maxAmount < value) {
      setError(`Value must be less than ${maxAmount + 1}`);
      return;
    }

    onSubmit({ answerNumeric: value });
  };

  useEffect(() => {
    setTimeout(() => {
      if (thisInputRef.current) {
        thisInputRef.current.focus();
      }
    }, 50);
  }, []);

  return (
    <QuestionContainer>
      <QuestionContent>
        <form
          id="question-form"
          onSubmit={(e) => {
            handleSubmit();
            e.preventDefault();
          }}
          className={className}
        >
          <QuestionText
            html={question.text}
            description={question.description}
          />
          <UnitInput
            unit={unitLabel ?? undefined}
            placeholder={placeholder ?? undefined}
            value={value ?? undefined}
            onChange={setValue}
            ref={thisInputRef}
            onKeyDown={(e) => {
              if (e.code == "Enter" && e.shiftKey == false) {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
          {error && <QuestionError>{error}</QuestionError>}
        </form>
      </QuestionContent>
      <AdvanceButton
        form="question-form"
        type="submit"
        loading={isSubmitting}
        sticky={!!footer}
      />
      {footer}
    </QuestionContainer>
  );
};

export default NumericInputQuestion;

import { CSSObject } from "styled-components";

import { Breakpoints, Colors, FontVariants, Theme } from "theme/default";

export type VariantPerBreakpoint = Partial<Record<Breakpoints, FontVariants>>;
export interface TypographyProps {
  color?: Colors;
  variant?: FontVariants | VariantPerBreakpoint;
}

export interface TextProps {
  color?: Colors;
  variant?: FontVariants;
}

const getTextStyles = ({
  color = "text",
  variant = "body",
  theme,
}: Theme & TextProps) => ({
  color: theme.colors[color] || "inherit",
  fontFamily: theme.fonts.families[variant],
  fontSize: theme.fonts.sizes[variant],
  fontStyle: theme.fonts.styles[variant],
  fontWeight: theme.fonts.weights[variant],
  letterSpacing: theme.fonts.letterSpacing[variant],
  lineHeight: theme.fonts.lineHeights[variant],
});

/**
 * The typography utility accepts standard text variant as well as a responsive variant object.
 * This object can contain keys that are associated with our breakpoint ranges and values that of the type `FontVariants`.
 * Variants specified at a breakpoint will be applid to that range AND above, unless a larger breakpoint is specified, in which
 * case it overrides the lower breakpoint. For example, if `{ sm: 'body', xl: 'heading1' }` was given, then the `body` style
 * be applied right up until the lower limit of `xl`, at which point the text would change to `heading`.
 *
 * @param color: Colors
 * @param variant: FontVariants
 * returns a theme function that returns a style object for typography
 */
export const typography =
  ({ color, variant }: TypographyProps = { color: "text", variant: "body" }) =>
  ({ theme }: Theme) => {
    if (typeof variant === "object") {
      const getFontVariants = (breakpoint: Breakpoints) =>
        variant[breakpoint]
          ? getTextStyles({ color, variant: variant[breakpoint], theme })
          : null;

      const sm = getFontVariants("sm");
      const md = getFontVariants("md");
      const lg = getFontVariants("lg");
      const xl = getFontVariants("xl");
      let styles: CSSObject = {};
      if (sm) {
        styles = {
          ...sm,
        };
      }
      if (md) {
        styles = {
          ...styles,
          [theme.media.above.sm]: md,
        };
      }
      if (lg) {
        styles = {
          ...styles,
          [theme.media.above.md]: lg,
        };
      }
      if (xl) {
        styles = {
          ...styles,
          [theme.media.above.lg]: xl,
        };
      }
      return styles;
    } else {
      return getTextStyles({ color, variant, theme });
    }
  };

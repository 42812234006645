import { useEffect, useMemo, useState } from "react";
import { QueryStatus } from "react-query";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import styled, { useTheme } from "styled-components";

import {
  AssessmentDetails,
  Category as CategoryType,
} from "api/generated/data-contracts";
import { useQueryAssessment } from "api/hooks";
import LogoImg from "assets/images/logo.png";
import { Drawer, Loader } from "components/base";
import GenericError from "components/base/GenericError";
import { Results } from "components/pages";
import { getCategoryProgress } from "components/pages/Dashboard/utils";
import { useAssessmentContext } from "providers/AssessmentProvider";
import reportError from "utils/reportError";
import { layout, typography } from "utils/style";

import CategoryGrid from "./components/CategoryGrid";
import CompletePill from "./components/CompletePill";
import FloatingResults from "./components/FloatingResults";
import { default as TitleBase } from "./components/Title";

const Base = styled.div(layout.fullWidth, layout.fullHeight, ({ theme }) => ({
  backgroundColor: theme.colors.offWhite,
}));

const DashboardWrapper = styled.div<{ resultsOpen: boolean }>(
  layout.fullHeight,
  ({ resultsOpen, theme }) => ({
    backgroundColor: theme.colors.offWhite,
    overflow: "auto",
    padding: theme.spacing[8],
    position: "fixed",
    transition: resultsOpen ? "none" : "width 100ms ease-in-out",
    transitionDelay: resultsOpen ? "0ms" : "150ms",
    width: resultsOpen ? "50%" : "100%",
  }),
);

const CategoriesContainer = styled.div<{ resultsOpen: boolean }>(
  ({ resultsOpen, theme }) => ({
    margin: "0 auto",

    [theme.media.above.sm]: {
      maxWidth: 556,
      transition: "transform 200ms ease-in-out",
      transform: resultsOpen ? "translateX(-20px)" : "translateX(0px)",
    },
  }),
);

const Title = styled(TitleBase)(({ theme }) => ({
  marginBottom: theme.spacing[8],
  minHeight: 120,
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "column",

  [theme.media.above.sm]: {
    maxWidth: 420,
  },
}));

const Nav = styled.div(({ theme }) => ({
  display: "flex",
  marginBottom: 66,

  [theme.media.above.sm]: {
    marginBottom: 138,
  },
}));

const Logo = styled.img.attrs({ src: LogoImg })(({ theme }) => ({
  width: 28,
  height: 28,

  [theme.media.above.sm]: {
    width: 44,
    height: 44,
  },
}));

const sortCategories = (categories: CategoryType[]) => {
  return categories.sort((a, b) => a.sequence! - b.sequence!);
};

const getTotalProgress = (categories: CategoryType[]) => {
  const categoriesProgress = categories.map(getCategoryProgress);
  const totalProgress =
    categoriesProgress.reduce((total, p) => total + p, 0) / categories.length;
  return totalProgress;
};

interface DrawerHeaderProps {
  isOpen?: boolean;
  spring?: boolean;
  springDelay?: number;
}

const DrawerContainer = styled.div(({ theme }) => ({
  opacity: 0.6,
  marginLeft: "auto",
  marginRight: theme.spacing[4],
}));

const DrawerHeaderBase = styled.div<{ isOpen?: boolean }>(
  layout.flexCenterHorizontal,
  ({ isOpen, theme }) => ({
    height: isOpen ? "auto" : "100%",
    marginTop: isOpen ? theme.spacing[3] : 0,
  }),
);

const DrawerHeader = ({
  isOpen,
  spring,
  springDelay = 0,
}: DrawerHeaderProps) => {
  return (
    <DrawerHeaderBase isOpen={isOpen}>
      <div css={typography({ variant: "heading5" })}>Your Results</div>
      {!isOpen && (
        <DrawerContainer>
          <CompletePill spring={spring} springDelay={springDelay} />
        </DrawerContainer>
      )}
    </DrawerHeaderBase>
  );
};

const ResultsWrapper = styled.div<{ resultsOpen: boolean }>(
  ({ resultsOpen, theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    width: "50%",
    minHeight: resultsOpen ? "100%" : "none",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.colors.offWhite,
  }),
);

export interface Props {
  data?: AssessmentDetails | undefined;
  error: Error | null;
  status: QueryStatus;
  animateOnUpdate?: boolean;
  setAnimateOnUpdate?: (val: boolean) => void;
}

const UPDATE_DELAY_MS = 1000;

export const Dashboard = ({
  data,
  error,
  status,
  animateOnUpdate,
  setAnimateOnUpdate = () => {},
}: Props) => {
  const { breakpoints } = useTheme();
  const [resultsOpen, setResultsOpen] = useState(false);
  const categories = useMemo(
    () => sortCategories(data && data.categories ? data.categories : []),
    [data],
  );
  const updateDelayMs = animateOnUpdate ? UPDATE_DELAY_MS : 0;
  const tweenDuration = animateOnUpdate ? updateDelayMs : 0;
  const isDesktop = useMediaQuery(
    `only screen and (min-width: ${breakpoints.xl.min}px)`,
  );

  /**
   * If the user is resizing their window, we want to close the floating
   * results window on non-desktop resolutions.
   */
  useEffect(() => {
    if (!isDesktop) {
      setResultsOpen(false);
    }
  }, [isDesktop]);

  if (status === "loading") {
    return (
      <Base css={layout.flexCenter}>
        <Loader variant="throbber" />
      </Base>
    );
  }

  if (status === "error" || !data) {
    reportError(`Error fetching assessment. ${JSON.stringify(error)}`);
    return <GenericError />;
  }

  const totalProgress = getTotalProgress(categories);
  return (
    <>
      <Base>
        <DashboardWrapper resultsOpen={isDesktop && resultsOpen}>
          <Nav>
            <Logo />
          </Nav>
          <CategoriesContainer resultsOpen={resultsOpen}>
            <Title
              delayUpdate={updateDelayMs}
              assessment={data!}
              progress={totalProgress}
            />
            <CategoryGrid
              tweenDuration={tweenDuration}
              categories={categories}
            />
          </CategoriesContainer>
          {totalProgress === 1 && !isDesktop && (
            <Drawer
              isOpen={resultsOpen}
              spring={animateOnUpdate}
              springDelay={updateDelayMs}
              pulse={animateOnUpdate}
              onOpen={() => {
                setResultsOpen(true);
                setAnimateOnUpdate(false);
              }}
              onClose={() => setResultsOpen(false)}
              header={
                <DrawerHeader
                  isOpen={resultsOpen}
                  spring={animateOnUpdate}
                  springDelay={updateDelayMs}
                />
              }
            >
              <Results quizType={data.quizType!} />
            </Drawer>
          )}
        </DashboardWrapper>
      </Base>
      {totalProgress === 1 && isDesktop && (
        <ResultsWrapper resultsOpen={resultsOpen}>
          <FloatingResults
            isOpen={resultsOpen}
            onOpen={() => {
              setResultsOpen(true);
              setAnimateOnUpdate(false);
            }}
            onClose={() => setResultsOpen(false)}
            quizType={data.quizType!}
            pulse={animateOnUpdate}
            spring={animateOnUpdate}
            springDelay={updateDelayMs}
          />
        </ResultsWrapper>
      )}
    </>
  );
};

const DashboardContainer = () => {
  const { id } = useAssessmentContext();
  const { data, error, status } = useQueryAssessment<AssessmentDetails>(id);
  const location = useLocation();
  // believe it or not, this is the way to type state in react router 6
  // https://github.com/remix-run/react-router/pull/7326#issuecomment-626418225
  const state = location.state as { oldAssessment?: AssessmentDetails };
  const [assessment, setAssessment] = useState<AssessmentDetails | undefined>();
  const [animateOnUpdate, setAnimateOnUpdate] = useState(false);
  useEffect(() => {
    if (
      state?.oldAssessment?.categories &&
      data?.categories &&
      getTotalProgress(state?.oldAssessment.categories) !==
        getTotalProgress(data.categories)
    ) {
      setAssessment(state?.oldAssessment);
      setAnimateOnUpdate(true);
      const timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
        setAssessment(data);
      }, 0);
      return () => {
        clearTimeout(timeout);
      };
    } else if (data) {
      setAssessment(data);
    }
  }, [data, state?.oldAssessment, status]);

  return (
    <Dashboard
      animateOnUpdate={animateOnUpdate}
      setAnimateOnUpdate={setAnimateOnUpdate}
      data={assessment}
      error={error}
      status={!assessment && status === "success" ? "loading" : status}
    />
  );
};

export default DashboardContainer;

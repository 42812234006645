import { Category, QuestionMultipleChoice } from "api/generated/data-contracts";
import { Option } from "components/base/Select/SelectOption";

export const mapChoicesToOptions = (
  choices: QuestionMultipleChoice[],
  multiAnswer: boolean,
  includeImages: boolean,
): Option[] => {
  return choices.map((choice) => {
    // removes null typing
    const imageUrl = choice.imageUrl ?? undefined;
    return {
      body: choice.description ?? undefined,
      title: choice.display,
      type: multiAnswer ? "check" : "radio",
      value: choice.key,
      imgSrc: includeImages ? imageUrl : undefined,
    };
  });
};

export const defaultChoiceKeys = (choices: QuestionMultipleChoice[]) =>
  choices
    .filter((choice) => !!choice.isDefaultSelection)
    .map((choice) => choice.key);

export const getStartStepIndex = (category?: Category | null): number => {
  let numQuestions = 0,
    numAnswers = 0,
    firstUnansweredQuestionIndex: number | undefined,
    firstQuestionIndex: number | undefined;

  category?.steps?.forEach((step, index) => {
    if (step.stepType === "QuestionEnum") {
      numQuestions++;
      firstQuestionIndex = firstQuestionIndex ?? index;
      if (!step?.answer) {
        firstUnansweredQuestionIndex = firstUnansweredQuestionIndex ?? index;
      }
      if (step.answer) {
        numAnswers++;
      }
    }
  });

  if (numAnswers === 0) {
    // Indicates they haven't started this section, so step to 0
    return 0;
  } else if (numQuestions === numAnswers) {
    // If completed, start at the first question, skipping over any interstitial
    // steps
    return firstQuestionIndex ?? 0;
  } else {
    // If we got here and have an unanswered question, start from there
    return firstUnansweredQuestionIndex ?? 0;
  }
};

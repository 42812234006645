import { useEffect } from "react";

import AdvanceButton from "components/pages/CategoryQuiz/components/AdvanceButton";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import AssociatedFieldsRichText from "components/partials/AssociatedFieldsRichText";
import { GenericInterstitialStep } from "types/api-extensions";
import { typography } from "utils/style";

const handleEnter = (cb: () => void) => (e: KeyboardEvent) => {
  if (e.code == "Enter") {
    e.preventDefault();
    cb();
  }
};
export interface Props {
  /**
   * Amount of time, in milliseconds, for the generic interstial element to call onDone
   */
  step: GenericInterstitialStep;
  onDone: () => void;
}

/**
 * Display rich text (html) delivered from the API for a time period specified by ttl.
 * When the timeout is complete, onDone is triggered. The expectation is that the controlling
 * component advances to the next step when onDone is triggered.
 */
const GenericInterstitial = ({ step, onDone }: Props) => {
  useEffect(() => {
    const handler = handleEnter(onDone);
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [onDone]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      onDone();
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [onDone, step]);
  return (
    <>
      <QuestionContent css={{ maxWidth: 420 }}>
        <AssociatedFieldsRichText
          html={step.interstitialScreen.body || ""}
          css={typography({ variant: "heading3", color: "black" })}
        />
      </QuestionContent>
      <AdvanceButton loading={true} onClick={onDone} />
    </>
  );
};

export default GenericInterstitial;

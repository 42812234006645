import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Answer } from "api/generated/data-contracts";
import { ShortTextInput } from "components/base";
import { Pencil } from "components/base/Icon";
import AdvanceButton from "components/pages/CategoryQuiz/components/AdvanceButton";
import QuestionContainer from "components/pages/CategoryQuiz/components/QuestionContainer";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import QuestionError from "components/pages/CategoryQuiz/components/QuestionError";
import QuestionText from "components/pages/CategoryQuiz/components/QuestionText";
import useGeocoder from "hooks/useGeocoder";
import { ZipAnswerType, ZipQuestionType } from "types/api-extensions";
import { layout, typography } from "utils/style";

import Map from "./Map";

const NUMBER_REGEX = /^[0-9]*$/;

const IconWrapper = styled.button(({ theme }) => ({
  marginLeft: "auto",

  [theme.media.above.sm]: {
    marginLeft: theme.spacing[2],
  },
}));

const InputWrapper = styled.div(({ theme }) => ({
  marginTop: 60,
  [theme.media.above.sm]: {
    marginTop: 130,
  },
  [theme.media.above.lg]: {
    marginTop: 160,
  },
}));

const City = styled.div(typography({ color: "midnightBlue" }), ({ theme }) => ({
  marginTop: theme.spacing[2],
  marginBottom: theme.spacing[10],
}));

const MapWrapper = styled.div(layout.fullWidth, {
  flexGrow: 1,
  position: "relative",
});

const MapGradient = styled.div(layout.fullWidth, layout.fullHeight, {
  position: "absolute",
  top: 0,
  background:
    "linear-gradient(180deg, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 95%)",
});

export interface Props {
  question: ZipQuestionType;
  answer?: ZipAnswerType;
  onSubmit: (answer: Answer) => void;
  isSubmitting?: boolean;
  className?: string;
  footer?: React.ReactNode;
}

const ZipCode = ({
  question,
  answer,
  onSubmit,
  isSubmitting,
  footer,
}: Props) => {
  const { placeholder, defaultValue } = question.zipConfiguration;
  const [mapView, setMapView] = useState(false);
  const [zip, setZip] = useState(answer?.answerText ?? defaultValue ?? "");
  const [validZip, setValidZip] = useState(false);
  const { data, loading, error: geocoderError, fetch } = useGeocoder(zip);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value: string = e.target.value;

    if (NUMBER_REGEX.test(value) && value.length <= 5) {
      setZip(value);
    }
  };

  const goToZipView = () => {
    setMapView(false);
  };

  const handleOnClick = async () => {
    if (mapView) {
      onSubmit({ answerText: zip });
    } else {
      fetch();
    }
  };

  const thisInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (NUMBER_REGEX.test(zip) && zip.length === 5) {
      setValidZip(true);
    } else {
      setValidZip(false);
    }
  }, [zip]);

  // Move to the map screen once we have the data we need.
  useEffect(() => {
    if (data?.city && data?.state) {
      setMapView(true);
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (thisInputRef.current) {
        thisInputRef.current.focus();
      }
    }, 50);
  }, []);

  return (
    <div css={[layout.flexVertical, layout.fullHeight]}>
      {mapView && data ? (
        <>
          <QuestionContent css={{ flex: "0 0 auto" }}>
            <div css={[layout.flexCenterHorizontal]}>
              <span css={typography({ variant: "heading2" })}>{zip}</span>
              <IconWrapper onClick={goToZipView} aria-label="Change zip code">
                <Pencil color="primaryBlue" />
              </IconWrapper>
            </div>
            <City>
              {data?.city}, {data?.state}
            </City>
          </QuestionContent>

          <MapWrapper>
            <Map lat={data?.lat} lng={data?.lng} />
            <MapGradient />
          </MapWrapper>
          <AdvanceButton
            disabled={!validZip}
            loading={loading || isSubmitting}
            aria-label={"Next"}
            icon={"check"}
            onClick={handleOnClick}
          />
        </>
      ) : (
        <QuestionContainer>
          <QuestionContent>
            <QuestionText
              html={question.text}
              description={question.description}
            />

            <InputWrapper>
              <ShortTextInput
                inputMode="numeric"
                onChange={handleOnChange}
                value={zip}
                placeholder={placeholder ?? "00000"}
                ref={thisInputRef}
                onKeyDown={(e) => {
                  if (e.code == "Enter" && e.shiftKey == false) {
                    e.preventDefault();
                    handleOnClick();
                  }
                }}
              />
            </InputWrapper>
            {geocoderError && (
              <QuestionError>
                That zip code is invalid. Please enter a different one.
              </QuestionError>
            )}
          </QuestionContent>
          <AdvanceButton
            disabled={!validZip}
            loading={loading || isSubmitting}
            aria-label={"Confirm"}
            icon={"arrow"}
            onClick={handleOnClick}
            sticky={!!footer}
          />
          {footer}
        </QuestionContainer>
      )}
    </div>
  );
};

export default ZipCode;

export const transitionKeys = {
  enter: "enter",
  enterFrom: "enterFrom",
  enterTo: "enterTo",
  leave: "leave",
  leaveFrom: "leaveFrom",
  leaveTo: "leaveTo",
} as const;

type BuildTransitionRecord = Record<keyof typeof transitionKeys, CSS>;

/**
 * A convenience function for building the transition css states that work
 * with our Transition component
 */
export const buildTransition = (cssMap: BuildTransitionRecord) => ({
  [`&.${transitionKeys.enter}`]: cssMap.enter,
  [`&.${transitionKeys.leave}`]: cssMap.leave,
  [`&.${transitionKeys.enterFrom}`]: cssMap.enterFrom,
  [`&.${transitionKeys.enterTo}`]: cssMap.enterTo,
  [`&.${transitionKeys.leaveTo}`]: cssMap.leaveTo,
  [`&.${transitionKeys.leaveFrom}`]: cssMap.leaveFrom,
});

type MirroredTransitionRecord = {
  transition: CSS;
  start: CSS;
  end: CSS;
};
/**
 * Similar to buildTransition, but assumes that the enter and leaving
 * transitions are just the opposite of one another.
 */
export const buildMirroredTransition = (cssMap: MirroredTransitionRecord) =>
  buildTransition({
    enter: cssMap.transition,
    leave: cssMap.transition,
    enterFrom: cssMap.start,
    leaveTo: cssMap.start,
    enterTo: cssMap.end,
    leaveFrom: cssMap.end,
  });

/* Shared transitions */

export const fade = (delayMs = 0, endOpacity = 1, duration = 350) =>
  buildMirroredTransition({
    transition: {
      transition: `opacity ${duration}ms ease`,
      transitionDelay: `${delayMs}ms`,
    },
    start: {
      opacity: 0,
    },
    end: {
      opacity: endOpacity,
    },
  });

export const fadeDown = buildMirroredTransition({
  transition: {
    transition: "opacity 300ms ease, transform 350ms ease",
  },
  start: {
    opacity: 0,
    transform: `translate3d(0, -16px, 0)`,
  },
  end: {
    opacity: 1,
    transform: "translate3d(0, 0, 0)",
  },
});

export const fadeUp = (
  delayMs = 0,
  opacityDurationMs = 300,
  transformDurationMs = 350,
  upDistance = 20,
) =>
  buildMirroredTransition({
    transition: {
      transition: `opacity ${opacityDurationMs}ms ease, transform ${transformDurationMs}ms ease`,
      transitionDelay: `${delayMs}ms`,
    },
    start: {
      opacity: 0,
      transform: `translate3d(0, ${upDistance}px, 0)`,
    },
    end: {
      opacity: 1,
      transform: `translate3d(0, 0, 0)`,
    },
  });

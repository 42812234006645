import React, { forwardRef } from "react";
import { TextareaAutosizeProps } from "react-textarea-autosize";

import { DEFAULT_PLACEHOLDER } from "components/base/ShortTextInput";
import TextAreaAutosize from "components/base/TextAreaAutosize";
import { typography } from "utils/style";
import { VariantPerBreakpoint } from "utils/style/typography";

export interface Props extends TextareaAutosizeProps {
  // TextareaAutosize has typed value as a string | number | string[]. We are only concerned about the string case.
  value?: string;
  className?: string;
}

/**
 * This component renders a textarea element which resizes its height based on the content. Font size is
 * responsive.
 */
const LongTextInput = forwardRef(
  (
    { value, placeholder = DEFAULT_PLACEHOLDER, ...rest }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const responsiveTypography: VariantPerBreakpoint = {
      sm: "heading2",
      md: "heading1",
    };

    return (
      <TextAreaAutosize
        {...rest}
        css={[
          typography({
            variant: responsiveTypography,
            color: "midnightBlue",
          }),
          { width: "100%" },
        ]}
        value={value}
        ref={ref}
        placeholder={placeholder}
      />
    );
  },
);

export default LongTextInput;

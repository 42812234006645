import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import LogoImg from "assets/images/logo.png";
import StartingBg from "assets/images/StartingBg.png";
import { Button, Modal } from "components/base";
import { layout, typography } from "utils/style";

import StartingModal from "./StartingModal";

const Base = styled.div(
  layout.padding(0, 6),
  layout.flexVertical,
  ({ theme }) => ({
    height: "100%",
    overflow: "auto",
    position: "relative",
    backgroundImage: `url(${StartingBg})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",

    [theme.media.above.sm]: {
      backgroundSize: "initial",
    },
  }),
);

const Content = styled.div(({ theme }) => ({
  [theme.media.above.md]: {
    padding: "0 185px",
  },
}));

const Logo = styled.img.attrs({
  src: LogoImg,
  alt: "Lower Mortgage logo",
})(({ theme }) => ({
  width: 32,
  height: 32,

  [theme.media.above.sm]: {
    width: 44,
    height: 44,
  },
}));

const LogoWrapper = styled.div(({ theme }) => ({
  paddingTop: 32,
  paddingBottom: 60,

  [theme.media.above.sm]: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  [theme.media.above.md]: {
    paddingBottom: 120,
  },
}));

const Title = styled.h1(
  typography({ variant: { sm: "heading1", md: "display2" } }),
  ({ theme }) => ({
    [theme.media.above.sm]: {
      maxWidth: 500,
    },
  }),
);

const Subtitle = styled.div(
  typography({ variant: { sm: "bodyLarge" } }),
  ({ theme }) => ({
    ...layout.padding(4, 0, 10, 0)({ theme }),
    opacity: 0.6,

    [theme.media.above.sm]: {
      ...layout.padding(4, 0, 8, 0)({ theme }),
      maxWidth: 380,
    },
  }),
);

const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "& > *:first-child": {
    marginBottom: theme.spacing[4],
  },

  [theme.media.above.sm]: {
    flexDirection: "row",
    maxWidth: 420,

    "& > *:first-child": {
      marginRight: theme.spacing[4],
    },
  },
}));

const Starting = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  const handleGetStarted = () => {
    setModalOpen(true);
  };

  const handleNextClick = () => {
    navigate({ pathname: "/quiz-selector", search });
  };

  return (
    <Base>
      <Content>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Title>Take the quiz. Get instant home advice.</Title>
        <Subtitle>
          Whether you&apos;re buying your first home or on your fifth--know
          exactly what to do with your biggest asset.
        </Subtitle>
        <ButtonContainer>
          <Button variant="primary" onClick={handleGetStarted}>
            Get Started
          </Button>
        </ButtonContainer>
      </Content>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <StartingModal onNextClick={handleNextClick} />
      </Modal>
    </Base>
  );
};

export default Starting;

import styled from "styled-components";

const QuestionContent = styled.div(({ theme }) => ({
  padding: theme.spacing[6],
  paddingTop: 40,
  boxSizing: "content-box",
  flex: 1,
  [theme.media.above.sm]: {
    padding: 48,
    paddingTop: 60,
  },
  [theme.media.above.lg]: {
    paddingTop: 80,
    paddingLeft: 185,
    maxWidth: 700,
  },
}));

export default QuestionContent;

import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { Answer } from "api/generated/data-contracts";
import AdvanceButton from "components/pages/CategoryQuiz/components/AdvanceButton";
import QuestionContainer from "components/pages/CategoryQuiz/components/QuestionContainer";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import QuestionError from "components/pages/CategoryQuiz/components/QuestionError";
import QuestionText from "components/pages/CategoryQuiz/components/QuestionText";
import { EmailQuestionType, ShortTextAnswerType } from "types/api-extensions";

import { ShortTextInput as ShortTextInputStyled } from "./ShortTextQuestion";

export interface Props {
  question: EmailQuestionType;
  answer?: ShortTextAnswerType;
  onSubmit: (answer: Answer) => void;
  isSubmitting?: boolean;
  className?: string;
  footer?: React.ReactNode;
}

// Very basic email validation. The purpose is to check if the user makes a simple mistake
// and not to check if the email is actually valid, which would require sending an email.
const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const EmailQuestion = ({
  question,
  answer,
  onSubmit,
  isSubmitting,
  className,
  footer,
}: Props) => {
  const { placeholder, defaultValue } = question.emailConfiguration;
  const [error, setError] = useState<string | undefined>();
  const [value, setValue] = useState<string | undefined | null>(
    answer?.answerText ?? defaultValue,
  );

  const thisInputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async () => {
    // Check config validations
    // is Required
    if (!value) {
      setError("Required");
      return;
    }

    if (!validateEmail(value)) {
      setError(`Please enter a valid email address`);
      return;
    }

    onSubmit({ answerText: value });
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      if (thisInputRef.current) {
        thisInputRef.current.focus();
      }
    }, 50);
  }, []);

  return (
    <QuestionContainer>
      <QuestionContent>
        <form
          id="question-form"
          onSubmit={(e) => {
            handleSubmit();
            e.preventDefault();
          }}
          className={className}
        >
          <QuestionText
            html={question.text}
            description={question.description}
          />
          <ShortTextInputStyled
            placeholder={placeholder ?? undefined}
            value={value ?? undefined}
            onChange={handleChange}
            ref={thisInputRef}
            onKeyDown={(e) => {
              if (e.code == "Enter" && e.shiftKey == false) {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
          {error && <QuestionError>{error}</QuestionError>}
        </form>
      </QuestionContent>
      <AdvanceButton
        form="question-form"
        type="submit"
        loading={isSubmitting}
        sticky={!!footer}
      />
      {footer}
    </QuestionContainer>
  );
};

export default EmailQuestion;

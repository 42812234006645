import styled from "styled-components";

import Pill from "components/base/Pill";
import { useDelayedSpring } from "hooks/useDelayedSpring";

interface PillProps {
  translateY?: number;
}

// Originally, I had this as `styled(Pill).attrs`, avoiding
// the extra wrapping div in the component. However, styled
// components was not passing this down to the DOM element,
// so I went the this route.
// Also note that we are using a `style` attr here because
// the update on `translateY`, if done in the typical
// styed component way triggers a warning that over
// 200 classes were generated.
const Base = styled.div.attrs((props: PillProps) => {
  return {
    style: {
      transform: `translateY(${props.translateY}px)`,
    },
  };
})<PillProps>``;

interface Props {
  spring?: boolean;
  springDelay?: number;
}

const CompletePill = ({ spring, springDelay = 0 }: Props) => {
  const pillSpringValue = useDelayedSpring({
    enabled: spring,
    delayMs: springDelay + 100,
    startValue: 50,
    endValue: 0,
    tension: 70,
  });

  return (
    <Base translateY={spring ? pillSpringValue : 0}>
      <Pill variant="green">Complete!</Pill>
    </Base>
  );
};

export default CompletePill;

import { forwardRef } from "react";
import DOMPurify from "dompurify";
import styled from "styled-components";

import { Theme } from "theme/default";
import { layout, typography } from "utils/style";

const Base = styled.div(({ theme }: Theme) => ({
  ...layout.spacedChildrenVertical(4)({ theme }),
  [theme.media.above.lg]: {
    ...layout.spacedChildrenVertical(6)({ theme }),
  },
  "& em": {
    color: theme.colors.primaryBlue,
    fontStyle: "normal",
  },
  "& b": {
    fontWeight: 600,
  },
  "& p": {
    ...typography({
      variant: {
        sm: "body",
        md: "bodyLarge",
      },
      color: "transparentText",
    })({ theme }),
  },
  "& h1": {
    ...typography({ variant: "heading3", color: "black" })({ theme }),
  },
}));

export interface Props {
  html: string;
  templateValues?: Record<
    string,
    string | boolean | string[] | number | undefined
  >;
  className?: string;
}

/**
 * Applies a set of agreed upon styles for rich text returned from the lower backend.
 * Content is sanitized before display. Currently only the `em`, `p`, `b`, and `h1` tags
 * are styled explicitly. `html` can contain "{{}}" fields for interpolation with `templateValues`
 * Example: html: "<p>{{FirstNameEnum}}</p>" and templateValues: {{FirstNameEnum: "Chris"}} would
 * yield "<p>Chris</p>"
 */
const RichText = forwardRef<HTMLDivElement, Props>(
  ({ html, className, templateValues = {} }: Props, ref) => {
    let templatedHtml = html.replace(/{{(.*?)}}/g, (_match, innerMatch) => {
      return templateValues?.[innerMatch]?.toString() ?? "";
    });
    const sanitizedHTML = DOMPurify.sanitize(templatedHtml, {
      USE_PROFILES: { html: true },
    });
    return (
      <Base
        ref={ref}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        className={className}
      />
    );
  },
);

export default RichText;

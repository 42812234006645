import { css, keyframes } from "styled-components";

const shimmerKeyframes = keyframes`
0% {
  background-position: -1000px 0;
}
100% {
  background-position: 1000px 0;
}
`;

/**
 * Produces the shimmer effect in the background of an element.
 * Use in combination with the css prop.
 * E.g. `<img css={shimmer(2, 2) />`
 */
export const shimmer = (
  secondsPerIteration: number,
  iterations: number | "infinite",
) => css`
  animation: ${shimmerKeyframes} ${secondsPerIteration}s ${iterations} linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`;

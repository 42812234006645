import React from "react";
import {
  MdArrowBack as arrowBack,
  MdArrowDownward as arrowDownward,
  MdArrowForward as arrowForward,
  MdArrowUpward as arrowUpward,
  MdCheck as check,
  MdClose as close,
  MdCreate as create,
  MdExpandLess as expandLess,
  MdExpandMore as expandMore,
  MdHelpOutline as help,
  MdInfoOutline as info,
  MdLaunch as launch,
  MdLock as lock,
  MdOutlineMailOutline as email,
  MdSaveAlt as save,
  MdThumbDownOffAlt as thumbsDown,
  MdThumbUpOffAlt as thumbsUp,
} from "react-icons/md";

import { ReactComponent as alignBottom } from "assets/icons/AlignBottom.svg";
import { ReactComponent as circles } from "assets/icons/Circles.svg";
import { ReactComponent as heart } from "assets/icons/Heart.svg";
import { ReactComponent as house } from "assets/icons/House.svg";
import { ReactComponent as pencil } from "assets/icons/PencilSimple.svg";
import { ReactComponent as user } from "assets/icons/User.svg";
import theme, { Colors } from "theme/default";

export interface Props {
  className?: string;
  color?: Colors;
  size?: number | string;
  style?: React.CSSProperties;
}

const createIconComponent =
  (Icon: React.ElementType) =>
  ({ color: colorProp, size = 24, className, style }: Props) => {
    const color = colorProp ? theme.colors[colorProp] : undefined;

    const icon = (
      <Icon
        style={{
          color,
          width: size,
          height: size,
          ...style,
        }}
        className={className}
      />
    );

    return icon;
  };

export const AlignBottom = createIconComponent(alignBottom);
export const ArrowBack = createIconComponent(arrowBack);
export const ArrowDownward = createIconComponent(arrowDownward);
export const ArrowForward = createIconComponent(arrowForward);
export const ArrowUpward = createIconComponent(arrowUpward);
export const Check = createIconComponent(check);
export const Circles = createIconComponent(circles);
export const Close = createIconComponent(close);
export const Create = createIconComponent(create);
export const Email = createIconComponent(email);
export const ExpandLess = createIconComponent(expandLess);
export const ExpandMore = createIconComponent(expandMore);
export const Heart = createIconComponent(heart);
export const Help = createIconComponent(help);
export const House = createIconComponent(house);
export const Info = createIconComponent(info);
export const Launch = createIconComponent(launch);
export const Lock = createIconComponent(lock);
export const Pencil = createIconComponent(pencil);
export const Save = createIconComponent(save);
export const ThumbsDown = createIconComponent(thumbsDown);
export const ThumbsUp = createIconComponent(thumbsUp);
export const User = createIconComponent(user);

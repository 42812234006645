import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { useMutationAssessment, useQueryQuizSelector } from "api/hooks";
import { Loader } from "components/base";
import { MultipleChoiceSingleAnswerQuestionBase } from "components/pages/CategoryQuiz/components/steps/MultipleChoiceSingleAnswerQuestion";
import { useAssessmentContext } from "providers/AssessmentProvider";
import { useToast } from "providers/ToastProvider";
import reportError from "utils/reportError";
import { layout } from "utils/style";

import Footer from "../CategoryQuiz/components/Footer";

const BaseCenter = styled.div(
  layout.flexCenter,
  layout.fullWidth,
  layout.fullHeight,
);

const QuizSelector = () => {
  const [value, setValue] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const { data, status } = useQueryQuizSelector();
  const [searchParams] = useSearchParams();
  const mutation = useMutationAssessment();
  const navigate = useNavigate();
  const { updateId } = useAssessmentContext();
  const { showToast } = useToast();

  const handleOnSubmit = () => {
    const choices = data?.data?.choices;
    if (!choices) {
      return;
    }

    const choice = choices.find((c) => c.key === value);
    const quizKey = choice?.quizKey;

    // is Required
    if (!value) {
      setError("Required");
      return;
    }

    if (quizKey) {
      const utmSource = searchParams.get("utm_source");
      const utmMedium = searchParams.get("utm_medium");

      mutation.mutate(
        { quizKey, utmSource, utmMedium },
        {
          onSuccess: ({ data: id }) => {
            updateId(id);
            navigate("/dashboard");
          },
          onError: () => {
            reportError(
              `Failed to create an assessment after choosing a quiz type. ${JSON.stringify(
                {
                  quizKey,
                },
              )}`,
            );
            showToast({
              title: "Error",
              body: "There was an error submitting your results. Please try again.",
              variant: "error",
            });
          },
        },
      );
    }
  };

  if (status === "loading") {
    return (
      <BaseCenter>
        <Loader />
      </BaseCenter>
    );
  }

  if (status === "error") {
    return <BaseCenter>There was an error. Please try again later.</BaseCenter>;
  }
  let footer;
  if (
    data?.data?.footerConfiguration &&
    !!data?.data?.footerConfiguration?.title &&
    !!data?.data?.footerConfiguration?.body
  ) {
    footer = (
      <Footer
        variant="question"
        title={data?.data?.footerConfiguration?.title || undefined}
        body={data?.data?.footerConfiguration?.body || undefined}
      />
    );
  }

  const handleChange = (value: string) => {
    setValue(value);
  };
  return (
    <>
      {data?.data && (
        <MultipleChoiceSingleAnswerQuestionBase
          text={data?.data.text}
          description={data?.data.description}
          choices={data?.data.choices || []}
          // not explicitly set by the quizSelect endpoint
          // but let's assume we include images if there
          // are imgSrc's in the url.
          includeImages
          error={error}
          value={value}
          onChange={handleChange}
          onSubmit={handleOnSubmit}
          isSubmitting={mutation.isLoading}
          footer={footer}
        />
      )}
    </>
  );
};

export default QuizSelector;

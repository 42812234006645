import styled from "styled-components";

import { Info } from "components/base/Icon";
import Tooltip from "components/base/Tooltip";
import AssociatedFieldsRichTextBase, {
  Props as AssociatedFieldsRichTextProps,
} from "components/partials/AssociatedFieldsRichText";
import { typography } from "utils/style";

const AssociatedFieldsRichText = styled(AssociatedFieldsRichTextBase)(
  ({ theme }) => ({
    marginRight: "auto",
    [theme.media.above.sm]: {
      marginRight: theme.spacing[2],
    },
  }),
);

export interface Props extends AssociatedFieldsRichTextProps {
  description?: string | null;
  className?: string;
}

const QuestionText = ({ html, description, className }: Props) => (
  <div css={[{ display: "flex" }]} className={className}>
    <AssociatedFieldsRichText
      html={html}
      css={typography({ variant: "quiz" })}
    />
    {description && (
      <Tooltip
        anchor={
          <Tooltip.Anchor>
            <Info size={20} color="primaryBlue" />
          </Tooltip.Anchor>
        }
      >
        {description}
      </Tooltip>
    )}
  </div>
);

export default QuestionText;

const BASE_UNIT = 4;

export const spacing = {
  0: 0,
  1: BASE_UNIT, // base
  2: BASE_UNIT * 2, // condensed
  3: BASE_UNIT * 3,
  4: BASE_UNIT * 4, // standard,
  5: BASE_UNIT * 5,
  6: BASE_UNIT * 6, // gutter
  7: BASE_UNIT * 7,
  8: BASE_UNIT * 8, // expanded,
  9: BASE_UNIT * 9,
  10: BASE_UNIT * 10,
} as const;

import { darken, transparentize } from "polished";
import styled from "styled-components";

import ButtonBase, { Props as ButtonProps } from "components/base/Button";
import { ThumbsDown, ThumbsUp } from "components/base/Icon";
import { getButtonBoxShadow } from "utils/style/button";

const BUTTON_SIZE = 80;

interface StyledProps extends ButtonProps {
  $variant: "accept" | "reject";
}
const Button = styled(ButtonBase)<StyledProps>(({ theme, $variant }) => {
  const variantColor =
    $variant === "accept" ? theme.colors.accentTeal : theme.colors.pink;
  const baseBoxShadow = getButtonBoxShadow({ theme });
  return {
    backgroundColor: variantColor,
    height: BUTTON_SIZE,
    width: BUTTON_SIZE,
    "&:disabled": {
      backgroundColor: transparentize(0.2, variantColor),
    },
    "&:not(:disabled)": {
      "&:hover, &.hover": {
        backgroundColor: darken(0.14, variantColor),
      },

      "&:focus, &.focus": {
        boxShadow: `0 0 0 4px ${transparentize(
          0.4,
          variantColor,
        )}, ${baseBoxShadow}`,
      },
    },
  };
});

export const AcceptButton = (
  props: Omit<ButtonProps, "children" | "variant">,
) => (
  <Button {...props} variant="circle" $variant="accept">
    <ThumbsUp />
  </Button>
);

export const RejectButton = (
  props: Omit<ButtonProps, "children" | "variant">,
) => (
  <Button {...props} variant="circle" $variant="reject">
    <ThumbsDown />
  </Button>
);

import { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";

import { Answer, Step as StepType } from "api/generated/data-contracts";
import { useMutationQuestionResponse } from "api/hooks";
import { Transition } from "components/base";
import EmailQuestion from "components/pages/CategoryQuiz/components/steps/EmailQuestion";
import GenericInterstitial from "components/pages/CategoryQuiz/components/steps/GenericInterstitial";
import LongTextQuestion from "components/pages/CategoryQuiz/components/steps/LongTextQuestion";
import MultipleChoiceMultiAnswerQuestion from "components/pages/CategoryQuiz/components/steps/MultipleChoiceMultiAnswerQuestion";
import MultipleChoiceSingleAnswerQuestion from "components/pages/CategoryQuiz/components/steps/MultipleChoiceSingleAnswerQuestion";
import ShortTextQuestion from "components/pages/CategoryQuiz/components/steps/ShortTextQuestion";
import SliderQuestion from "components/pages/CategoryQuiz/components/steps/SliderQuestion";
import SwipeQuestion from "components/pages/CategoryQuiz/components/steps/SwipeQuestion";
import ZipCodeQuestion from "components/pages/CategoryQuiz/components/steps/ZipCodeQuestion";
import useTimeoutState from "hooks/useTimeoutState";
import { useToast } from "providers";
import { useAssessmentContext } from "providers/AssessmentProvider";
import {
  isActionableInterstitialStep,
  isBooleanQuestion,
  isEmailQuestion,
  isGenericInterstitialStep,
  isLongTextQuestion,
  isMultipleChoiceMultiAnswerQuestion,
  isMultipleChoiceSingleAnswerQuestion,
  isNumericQuestion,
  isShortTextQuestion,
  isSliderQuestion,
  isSwipeQuestion,
  isZipQuestion,
} from "types/api-extensions";
import reportError from "utils/reportError";
import { fade } from "utils/style/transition";

import Footer from "./Footer";
import ActionableInterstitialModal from "./steps/ActionableInterstitialModal";
import BooleanQuestion from "./steps/BooleanQuestion";
import NumericQuestion from "./steps/NumericQuestion";

const renderQuestion = (
  step: StepType,
  onSubmit: (answer?: Answer) => void,
  isSubmitting?: boolean,
) => {
  let footer;
  if (
    step?.footerConfiguration &&
    !!step?.footerConfiguration?.title &&
    !!step?.footerConfiguration?.body
  ) {
    footer = (
      <Footer
        variant="question"
        title={step?.footerConfiguration?.title || undefined}
        body={step?.footerConfiguration?.body || undefined}
      />
    );
  }
  if (isShortTextQuestion(step)) {
    return (
      <ShortTextQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
        footer={footer}
      />
    );
  } else if (isLongTextQuestion(step)) {
    return (
      <LongTextQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
        footer={footer}
      />
    );
  } else if (isSliderQuestion(step)) {
    return (
      <SliderQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
        footer={footer}
      />
    );
  } else if (isZipQuestion(step)) {
    return (
      <ZipCodeQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
        footer={footer}
      />
    );
  } else if (isMultipleChoiceMultiAnswerQuestion(step)) {
    return (
      <MultipleChoiceMultiAnswerQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
        footer={footer}
      />
    );
  } else if (isMultipleChoiceSingleAnswerQuestion(step)) {
    return (
      <MultipleChoiceSingleAnswerQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
        footer={footer}
      />
    );
  } else if (isSwipeQuestion(step)) {
    return (
      <SwipeQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
      />
    );
  } else if (isEmailQuestion(step)) {
    return (
      <EmailQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
        footer={footer}
      />
    );
  } else if (isBooleanQuestion(step)) {
    return (
      <BooleanQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
      />
    );
  } else if (isNumericQuestion(step)) {
    return (
      <NumericQuestion
        question={step.question}
        answer={step.answer}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        key={step.question.key}
      />
    );
  }

  // if we reach here, a step is being passed that
  // we cannot handle
  reportError(
    `The API passed a Question of type "${step?.question?.questionType}" that cannot be processed by the client app`,
  );
};

const renderStep = (
  step: StepType,
  onDone: (answer?: Answer) => void,
  isSubmitting?: boolean,
) => {
  if (isGenericInterstitialStep(step)) {
    return <GenericInterstitial step={step} onDone={onDone} />;
  } else if (step.stepType === "QuestionEnum") {
    return renderQuestion(step, onDone, isSubmitting);
  }
  reportError(
    `The API passed a Step of type "${step?.stepType}" that cannot be processed by the client app`,
  );
};

export interface Props {
  step: StepType;
  categoryKey?: string;
  onDone: () => void;
  onCancel: () => void;
}

const Step = ({ step, categoryKey, onDone, onCancel }: Props) => {
  const [show, setShow] = useState(true);
  const { id: assessmentId } = useAssessmentContext();
  const { isLoading, mutate } = useMutationQuestionResponse(
    assessmentId,
    categoryKey,
    step.question?.key,
  );
  const { showToast } = useToast();

  useEffect(() => {
    if (step.question?.key) {
      ReactGA.event({
        category: "Quiz Step",
        action: "Viewed question",
        label: step.question?.key,
      });
    }
  }, [step.question?.key]);

  const handleDone = useCallback(
    (answer?: Answer) => {
      if (answer) {
        mutate(answer, {
          onSuccess: () => {
            setShow(false);
          },
          onError: () => {
            reportError(
              `Failed to submit answer for question. ${JSON.stringify({
                assessmentId,
                questionKey: step?.question?.key,
                answer,
              })}`,
            );
            showToast({
              title: "Something went wrong",
              body: "An unexpected error occurred. Please try again.",
              variant: "error",
            });
          },
        });
      } else {
        setShow(false);
      }
    },
    [assessmentId, mutate, showToast, step?.question?.key],
  );

  // Transition wasn't working with React TextArea Autosize,
  // which is what we use internally for the ShortTextInput, LongTextInput,
  // and ValueInput components.
  // https://github.com/Andarist/react-textarea-autosize/issues/48 outlines
  // some of the potential issues.
  // To work around here is to allow React TextArea Autosize to do its initial
  // setup and then showing the step in the next step. `unmount = false` ensures
  // that the rendering occurs before attempting to start the animation, while
  // `tick` will be set to true in the next event loop
  const tick = useTimeoutState(0, false, true);
  return (
    <>
      {!isActionableInterstitialStep(step) && (
        <Transition
          appear={true}
          show={show && tick}
          unmount={false}
          css={[
            fade(0, 1, 500),
            {
              flex: 1,
            },
          ]}
          afterLeave={() => {
            onDone();
          }}
        >
          {renderStep(step, handleDone, isLoading)}
        </Transition>
      )}

      {isActionableInterstitialStep(step) && (
        <ActionableInterstitialModal
          step={step}
          onDone={() => onDone()}
          onClose={() => onCancel()}
        />
      )}
    </>
  );
};

export default Step;

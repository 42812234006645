import styled from "styled-components";

import { Category } from "api/generated/data-contracts";
import { Button, Pill, RichText } from "components/base";
import { CategoryTheme } from "theme/default";
import { layout, typography } from "utils/style";

import CategoryIcon from "./CategoryIcon";
import Confetti from "./Confetti";

const Base = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: theme.colors.white,
  overflow: "hidden",

  [theme.media.above.sm]: {
    backgroundColor: theme.colors.white,
  },

  [theme.media.above.lg]: {
    flexDirection: "row",
  },
}));

const Header = styled.header(({ theme }) => ({
  flexBasis: 284,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginBottom: 64,
  position: "relative",

  [theme.media.above.sm]: {
    flexBasis: 365,
  },

  [theme.media.above.lg]: {
    marginBottom: 0,
    flexBasis: 553,
    height: "100vh",
    overflow: "hidden",
  },
}));

const Content = styled.div(({ theme }) => ({
  flex: 1,

  [theme.media.above.sm]: {
    display: "flex",
    justifyContent: "center",
  },

  [theme.media.above.lg]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Card = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  ...layout.padding(0, 6, 2, 6)({ theme }),

  [theme.media.above.sm]: {
    ...layout.padding(10)({ theme }),
    width: 370,
    borderRadius: 24,
    boxShadow: theme.boxShadows.soft,
    height: "min-content",
    marginBottom: theme.spacing[6],
  },

  [theme.media.above.lg]: {
    marginTop: 0,
  },
}));

const NextDescriptionRichText = styled(RichText)(({ theme }) => ({
  "& p": {
    ...typography({ variant: "body", color: "black" })({ theme }),
  },
}));

const ButtonGroup = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical(2),
  ({ theme }) => ({
    [theme.media.above.sm]: {
      marginTop: 64,
    },
  }),
);

const Circle = styled.div<{
  categoryTheme: CategoryTheme;
}>(({ theme, categoryTheme }) => ({
  position: "absolute",
  height: "140vw",
  width: "140vw",
  bottom: 0,
  borderRadius: "50%",
  backgroundColor: theme.categoryTheme[categoryTheme].secondaryColor,
  opacity: 0.7,

  [theme.media.above.lg]: {
    height: "200vh",
    width: "200vh",
    right: 0,
    top: "-50vh",
  },
}));

const Title = styled.h1(typography({ variant: "heading1" }), ({ theme }) => ({
  marginTop: 12,

  [theme.media.above.lg]: {
    marginTop: 24,
  },
}));

const Subtitle = styled.div<{
  categoryTheme: CategoryTheme;
}>(typography({ variant: "heading6" }), ({ theme, categoryTheme }) => ({
  marginTop: 4,
  color: theme.categoryTheme[categoryTheme].primaryColor,
}));

export interface Props {
  category: Category;
  nextCategory?: Category;
  onContinueToNext?: () => void;
  onTakeMeHome?: () => void;
  className?: string;
}

const Phasing = ({
  category,
  nextCategory,
  onContinueToNext = () => {},
  onTakeMeHome = () => {},
  className,
}: Props) => {
  // categories should really always have a categoryTheme, but with the way they are type
  // we need to safeguard and set a default.
  const categoryTheme = category?.categoryTheme || "AboutYellowEnum";
  const nextTitle = nextCategory ? (
    nextCategory.title
  ) : (
    <>
      Your Results <span aria-hidden>🎉</span>
    </>
  );
  const nextDescription = nextCategory ? (
    <NextDescriptionRichText html={nextCategory?.description || ""} />
  ) : (
    <div
      css={[
        layout.spacedChildrenVertical(2),
        typography({ variant: "body", color: "black" }),
      ]}
    >
      <p>
        We&apos;ve come a long way to get here, so we&apos;re excited to share
        your home readiness and more!
      </p>
      <p>
        We&apos;ll point you in the right direction to take the next step.
        <span aria-hidden>✨</span>
      </p>
    </div>
  );
  const nextButtonTitle = nextCategory ? nextCategory.title : "Results";

  return (
    <Base className={className}>
      <Header>
        <Circle categoryTheme={categoryTheme} />
        <CategoryIcon categoryTheme={categoryTheme} />
        <Title>{category?.title || ""}</Title>
        <Subtitle categoryTheme={categoryTheme}>Complete!</Subtitle>
        <Confetti categoryTheme={categoryTheme} />
      </Header>
      <Content>
        <Card>
          <div css={{ display: "flex", justifyContent: "space-between" }}>
            <h2 css={typography({ variant: "heading3" })}>{nextTitle}</h2>
            <Pill
              css={{ flexShrink: 0, height: "fit-content", marginLeft: 8 }}
              variant="blue"
            >
              Up Next
            </Pill>
          </div>
          <div css={{ flex: 1, marginTop: 16 }}>{nextDescription}</div>

          <ButtonGroup>
            <Button
              onClick={onContinueToNext}
              css={{ textTransform: "uppercase" }}
            >
              Continue to {nextButtonTitle}
            </Button>
            <Button
              onClick={onTakeMeHome}
              css={{
                border: "none",
                boxShadow: "none",
                background: "transparent",
                textTransform: "uppercase",
              }}
              variant="secondary"
            >
              Take Me Home
            </Button>
          </ButtonGroup>
        </Card>
      </Content>
    </Base>
  );
};

export default Phasing;

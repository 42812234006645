import numeral from "numeral";
/**
 * Given a number between 0 and 1, it will convert it to a whole
 * number.
 *
 * If the number is greater than 1 or less than 0, it will be clamped.
 *
 * e.g. 0.47 => 47
 */
const prettyPercent = (number: number) => {
  const n = Math.min(Math.max(number, 0), 1);
  return numeral(n * 100).format("0");
};

export default prettyPercent;

import React from "react";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Route,
  Routes,
  unstable_HistoryRouter as Router,
} from "react-router-dom";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { ThemeProvider } from "styled-components";

import { AssessmentDetails } from "api/generated/data-contracts";
import { useQueryAssessment } from "api/hooks";
import GenericError from "components/base/GenericError";
import { Dashboard, NotFound, Starting } from "components/pages";
import CategoryQuiz from "components/pages/CategoryQuiz/CategoryQuiz";
import QuizSelector from "components/pages/Starting/QuizSelector";
import { ToastProvider } from "providers";
import AssessmentProvider, {
  useAssessmentContext,
} from "providers/AssessmentProvider";
import defaultTheme from "theme/default";

import { reactPlugin } from "./AppInsights";
import browserHistory from "./browserHistory";

import "styles/global.css";

/**
 * This tells Typescript that we have defined our own theme.
 */
declare module "styled-components" {
  type Theme = typeof defaultTheme;
  export interface DefaultTheme extends Theme {}
}

// Create a client
const queryClient = new QueryClient();

const RequireAssessment = ({ children }: { children: React.ReactNode }) => {
  const { id, reset } = useAssessmentContext();

  // This fetches the assessment. If we get a 404, it means the assessment has expired.
  // So we delete our reference to the expired assessment id. Which will cause a redirect
  // to `/`
  useQueryAssessment<AssessmentDetails>(id, {
    onError: (error) => {
      //@ts-ignore error occurs at runtime, so we don't know
      // for sure it will be an axios error. However, this is
      // safe access.
      if (error?.response?.status === 404) {
        reset();
      }
    },
  });
  if (!id) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastProvider>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary
            onError={() => (
              <GenericError message="An unexpect error occurred. Please refresh your browser and try again." />
            )}
            appInsights={reactPlugin}
          >
            <QueryClientProvider client={queryClient}>
              <AssessmentProvider>
                <Router history={browserHistory}>
                  <Routes>
                    <Route path="/" element={<Starting />} />
                    <Route path="/quiz-selector" element={<QuizSelector />} />
                    <Route
                      path="/dashboard"
                      element={
                        <RequireAssessment>
                          <Dashboard />
                        </RequireAssessment>
                      }
                    />
                    <Route
                      path="category/:categoryId"
                      element={
                        <RequireAssessment>
                          <CategoryQuiz />
                        </RequireAssessment>
                      }
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Router>
              </AssessmentProvider>
            </QueryClientProvider>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;

import { CSSObject } from "styled-components";

import { Theme } from "theme/default";
import { layout, typography } from "utils/style";

export const variants = ["question", "form"] as const;

export type InputVariants = typeof variants[number];

export type InputProps = { variant: InputVariants };

export const input =
  ({ variant }: InputProps = { variant: "form" }) =>
  ({ theme }: Theme): CSSObject => {
    if (variant === "question") {
      return {
        ...typography({ variant: "heading1" })({ theme }),
        backgroundColor: theme.colors.white,
        border: "none",
        caretColor: theme.colors.primaryBlue,
        "::placeholder": {
          opacity: 0.1,
          color: "inherit",
        },
      };
    } else if (variant === "form") {
      return {
        ...layout.paddingVertical(4)({ theme }),
        ...layout.paddingHorizontal(6)({ theme }),
        ...typography({ variant: "bodyLarge", color: "midnightBlue" })({
          theme,
        }),
        border: `1px solid ${theme.colors.lightGrey}`,
        backgroundColor: theme.colors.white,
        borderRadius: "60px",
        boxSizing: "border-box",
        "::placeholder": {
          opacity: 0.1,
          color: "inherit",
        },
      };
    }
    return {};
  };

import { useCallback } from "react";
import styled from "styled-components";

import { Answer, QuestionMultipleChoice } from "api/generated/data-contracts";
import SwipeMultiSelectBase from "components/base/SwipeMultiSelect";
import QuestionContent from "components/pages/CategoryQuiz/components/QuestionContent";
import QuestionText from "components/pages/CategoryQuiz/components/QuestionText";
import {
  MultipleChoiceAnswerType,
  SwipeQuestionType,
} from "types/api-extensions";

const mapChoicesToOptions = (choices: QuestionMultipleChoice[]) =>
  choices.map((choice) => ({
    label: choice.display,
    value: choice.key,
    imgSrc: choice.imageUrl ?? "",
  }));

const SwipeMultiSelect = styled(SwipeMultiSelectBase)(({ theme }) => ({
  marginTop: theme.spacing[8],
  [theme.media.above.sm]: {
    marginTop: 100,
  },
  [theme.media.above.lg]: {
    marginTop: 80,
  },
}));

export interface Props {
  question: SwipeQuestionType;
  answer?: MultipleChoiceAnswerType;
  onSubmit: (answer: Answer) => void;
  isSubmitting?: boolean;
  className?: string;
}

const SwipeQuestion = ({ question, onSubmit, className }: Props) => {
  const { choices, selectedMeaning, rejectedMeaning } =
    question.multipleChoiceConfiguration;

  const handleSubmit = useCallback(
    (values: string[]) => {
      onSubmit({ answerMultipleChoice: values });
    },
    [onSubmit],
  );

  return (
    <div css={{ paddingBottom: 24 }}>
      <QuestionContent
        className={className}
        css={{ maxWidth: "inherit", paddingBottom: 0 }}
      >
        <QuestionText html={question.text} description={question.description} />
      </QuestionContent>
      <SwipeMultiSelect
        acceptedMeaning={selectedMeaning ?? undefined}
        rejectedMeaning={rejectedMeaning ?? undefined}
        options={mapChoicesToOptions(choices)}
        onComplete={handleSubmit}
      />
    </div>
  );
};

export default SwipeQuestion;

import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { appInsights } from "AppInsights";

const reportError = (message: string, customProperties?: ICustomProperties) =>
  appInsights.trackException(
    {
      error: new Error(message),
    },
    customProperties,
  );

export default reportError;

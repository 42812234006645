import { forwardRef } from "react";
import styled from "styled-components";

import ButtonBase, { Props as ButtonProps } from "components/base/Button";
import { ArrowForward, Check } from "components/base/Icon";

const OFFSET = 120;
const MOBILE_BOTTOM = 32;
const TABLET_BOTTOM = 56;
const DESKTOP_BOTTOM = 90;
const Base = styled.div<{ sticky: boolean }>(({ theme, sticky }) => {
  if (sticky) {
    return {
      position: "sticky",
      bottom: MOBILE_BOTTOM + OFFSET,
      [theme.media.above.sm]: {
        bottom: TABLET_BOTTOM + OFFSET,
      },
      [theme.media.above.lg]: {
        bottom: DESKTOP_BOTTOM + OFFSET,
      },
    };
  }
});

const Button = styled(ButtonBase)<{ sticky: boolean }>(({ theme, sticky }) => ({
  position: "absolute",
  width: 64,
  height: 64,
  right: theme.spacing[8],
  bottom: sticky ? -OFFSET : MOBILE_BOTTOM,
  [theme.media.above.sm]: {
    width: 77,
    height: 77,
    right: 56,
    bottom: sticky ? -OFFSET : TABLET_BOTTOM,
  },
  [theme.media.above.lg]: {
    right: 195,
    bottom: sticky ? -OFFSET : DESKTOP_BOTTOM,
  },
}));

export interface Props extends ButtonProps {
  icon?: "arrow" | "check";
  sticky?: boolean;
}

const AdvanceButton = forwardRef<HTMLButtonElement, Props>(
  ({ icon = "arrow", sticky = false, ...rest }: Props, ref) => (
    <Base sticky={sticky}>
      <Button
        sticky={sticky}
        ref={ref}
        {...rest}
        variant="circle"
        aria-label={rest["aria-label"] ?? "Submit"}
      >
        {icon === "arrow" ? <ArrowForward /> : <Check />}
      </Button>
    </Base>
  ),
);

export default AdvanceButton;

import { useTheme } from "styled-components";

import { ScoreGradeRange } from "api/generated/data-contracts";
import { formatScoreGrades } from "utils/scoreGradeRange";

const CIRCLE_RADIUS = 46;
const TOTAL_CIRCUMFERENCE = 2 * Math.PI * CIRCLE_RADIUS;
// This represents the part of the circle we can see.
const CIRCUMFERENCE = TOTAL_CIRCUMFERENCE * 0.648;

// This gap is the space between segments on the circle.
const GAP = 6;

interface Props {
  className?: string;
  score: number;
  scoreGrades: ScoreGradeRange[];
}

const Chart = ({ className, score, scoreGrades }: Props) => {
  const theme = useTheme();

  const scoreGradeRanges = formatScoreGrades(scoreGrades);

  // This converts a ScoreGrade into a total percent of the radial graph.
  const scoreGradePercents = scoreGradeRanges.map(
    (scoreGrade) => scoreGrade.maxScore - scoreGrade.minScore,
  );

  // Given an index, will return the percentage used so far.
  const getPreviousPercents = (index: number) => {
    let sum = 0;
    for (let i = 0; i < index; i++) {
      sum += scoreGradePercents[i];
    }
    return sum;
  };

  return (
    <div
      className={className}
      css={{ width: 236, height: 170, overflow: "hidden" }}
    >
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g transform="rotate(-205 50 50)">
          <circle
            id="circle"
            cx="50"
            cy="50"
            r={CIRCLE_RADIUS}
            fill="transparent"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {scoreGradeRanges.map((scoreGrade, index) => {
            const min = scoreGrade.minScore;
            const max = scoreGrade.maxScore;

            // First element needs to use half the gap.
            const gap = index === 0 ? GAP / 2 : GAP;

            const color = scoreGrade.gradeColor ?? "black";

            // 999 is sufficiently large enough to prevent showing
            // more dashes. This allows us to show one dash only.
            const strokeDasharray = `${
              CIRCUMFERENCE * scoreGradePercents[index] - gap
            } 999`;

            return (
              <use
                key={index}
                href="#circle"
                stroke={
                  score >= min && score < max ? color : theme.colors.lightGrey
                }
                strokeDasharray={strokeDasharray}
                strokeDashoffset={
                  index > 0
                    ? -(CIRCUMFERENCE * getPreviousPercents(index) + GAP / 2)
                    : 0
                }
              />
            );
          })}
        </g>
      </svg>
    </div>
  );
};

export default Chart;

import { useEffect, useState } from "react";
import { UseQueryResult } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { AssessmentDetails, Category } from "api/generated/data-contracts";
import { useQueryAssessment, useQueryCategories } from "api/hooks";
import LogoImg from "assets/images/logo.png";
import { Button, Loader, Transition } from "components/base";
import GenericError from "components/base/GenericError";
import { ArrowBack, Close } from "components/base/Icon";
import ProgressBar from "components/base/ProgressBar";
import Step from "components/pages/CategoryQuiz/components/Step";
import { useAssessmentContext } from "providers/AssessmentProvider";
import reportError from "utils/reportError";
import { layout } from "utils/style";
import { fade } from "utils/style/transition";

import Phasing from "./components/Phasing/Phasing";
import { getStartStepIndex } from "./utils";

const Header = styled.div(({ theme }) => ({
  height: 56,
  flexShrink: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  padding: `0 ${theme.spacing[4]}px`,
  [theme.media.above.sm]: {
    padding: `0 ${theme.spacing[7]}px`,
    height: 80,
  },
}));

const NavButton = styled(Button)({
  border: "none",
  height: 30,
  width: 30,
});

const Logo = styled.img.attrs({ src: LogoImg })(({ theme }) => ({
  height: 32,
  width: 32,
  [theme.media.below.md]: {
    display: "none",
  },
}));

const Progress = styled(ProgressBar)({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  height: 2,
});

const Base = styled.div({
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

export type Props = Pick<
  UseQueryResult<Category[] | null | undefined, Error>,
  "data" | "status" | "error"
> & {
  categoryId: string;
  onTakeMeHome?: () => void;
  onContinueToNextCategory?: (nextCatKey?: string) => void;
};

export const CategoryQuiz = ({
  categoryId,
  data: categories,
  error,
  status,
  onTakeMeHome = () => {},
  onContinueToNextCategory = () => {},
}: Props) => {
  const [showPhasing, setShowPhasing] = useState(false);

  let category: Category | undefined | null,
    nextCategory: Category | undefined | null;
  const catIndex = categories?.findIndex((cat) => cat.key === categoryId);
  category = catIndex !== undefined ? categories?.[catIndex] : undefined;
  nextCategory =
    catIndex !== undefined ? categories?.[catIndex + 1] : undefined;

  const steps = category
    ? category.steps.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0))
    : null;

  const [currentStepIndex, setCurrentStepIndex] = useState(() =>
    getStartStepIndex(category),
  );

  if (status === "loading") {
    return (
      <div css={[layout.flexCenter, { height: "100%" }]}>
        <Loader />
      </div>
    );
  }

  if (status === "error") {
    reportError(`Error fetching categories. ${JSON.stringify(error)}`);
    return <GenericError />;
  }

  const stepsProgressPercent = category
    ? (currentStepIndex + 1) / category.steps.length
    : 0;

  const exitQuiz = () => {
    onTakeMeHome();
  };

  const goBack = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    } else {
      exitQuiz();
    }
  };

  const goForward = () => {
    if (steps && currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      setShowPhasing(true);
    }
  };

  return (
    <>
      {!showPhasing && (
        <Base>
          <Header>
            <NavButton
              variant="circleSecondary"
              aria-label="Go to previous step"
              onClick={goBack}
            >
              <ArrowBack />
            </NavButton>
            <Logo />
            <NavButton
              variant="circleSecondary"
              aria-label="Exit quiz"
              onClick={exitQuiz}
            >
              <Close />
            </NavButton>
            <Progress
              categoryTheme={category?.categoryTheme || "AboutYellowEnum"}
              percent={stepsProgressPercent}
            />
          </Header>
          {steps && (
            <Step
              key={steps[currentStepIndex].key}
              categoryKey={category?.key}
              step={steps[currentStepIndex]}
              onDone={goForward}
              onCancel={goBack}
            />
          )}
        </Base>
      )}
      {category && showPhasing && (
        <Transition
          appear={true}
          show={showPhasing}
          css={[fade(0, 1, 500), { height: "100%" }]}
        >
          <Phasing
            key={category.key}
            category={category}
            nextCategory={nextCategory}
            onContinueToNext={() => onContinueToNextCategory(nextCategory?.key)}
            onTakeMeHome={onTakeMeHome}
          />
        </Transition>
      )}
    </>
  );
};

const CategoryQuizContainer = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const { id: assessmentId } = useAssessmentContext();
  const response = useQueryCategories(assessmentId);
  const { data } = useQueryAssessment<AssessmentDetails>(assessmentId);
  const [initAssessment, setInitAssessment] = useState<
    AssessmentDetails | undefined
  >();

  useEffect(() => {
    if (data && initAssessment === undefined) {
      setInitAssessment(data);
    }
  }, [data, initAssessment]);

  const handleTakeMeHome = () => {
    navigate(`/dashboard`, { state: { oldAssessment: initAssessment } });
  };

  const handleContinueToNextCategory = (nextCategoryKey?: string) => {
    if (nextCategoryKey) {
      // Since we aren't remounting this component
      // we need to update the intialAssessment to the
      // current one.
      setInitAssessment(data);
      navigate(`/category/${nextCategoryKey}`);
    } else {
      handleTakeMeHome();
    }
  };

  return (
    <CategoryQuiz
      key={categoryId}
      categoryId={categoryId || ""}
      data={response.data}
      error={response.error}
      status={response.status}
      onTakeMeHome={handleTakeMeHome}
      onContinueToNextCategory={handleContinueToNextCategory}
    />
  );
};

export default CategoryQuizContainer;

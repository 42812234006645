import React, { useState } from "react";
import TinderCardBase from "react-tinder-card";
import { useWindowWidth } from "@react-hook/window-size";
import styled from "styled-components";

import { Theme } from "theme/default";
import { layout, typography } from "utils/style";
import { shimmer } from "utils/style/animations";

const OPACITY_IN_QUEUE = 0.7;
const PENDING_ACCEPT_TRANSITION_DURATION = "300ms";
const FILTER_TRANSITION = `filter ${PENDING_ACCEPT_TRANSITION_DURATION} ease-in-out`;
const BASE_TRANSITION = `transform 500ms ease-in-out, opacity 500ms ease-in-out, ${FILTER_TRANSITION}`;
const DRAGGING_TRANSIITON = `transform 0ms, ${FILTER_TRANSITION}`;

export type CardState =
  // complete: When the card has left the screen
  | "complete"
  // pendingComplete: When the user has dragged the card across the boundary that would trigger the accept or reject action
  | "pendingComplete"
  // active: When the card is at the top of the queue. It can be in a dragging or non-dragging state.
  | "active"
  // pendingActive: When the active card becomes 'pendingComplete', then 'next' card becomes 'pendingActive'
  | "pendingActive"
  // next: The state before going into pendingActive
  | "next"
  // queued: Everything else that is not active
  | "queued";

export type CardStyleProps = {
  $state: CardState;
  $isDragging: boolean;
};

export const cardSize = ({ theme }: Theme) => ({
  width: 295,
  maxWidth: "78vw",
  height: 340,
  [theme.media.above.sm]: {
    width: 320,
    height: 380,
  },
  [theme.media.above.lg]: {
    width: 420,
    height: 380,
  },
});

export const CardImage = styled.img<Omit<CardStyleProps, "$isDragging">>(
  ({ $state }) => {
    let stateCss = {};
    if (
      $state === "queued" ||
      $state === "next" ||
      $state === "pendingActive"
    ) {
      stateCss = {
        opacity: OPACITY_IN_QUEUE,
      };
    } else if ($state === "pendingComplete") {
      stateCss = {
        filter: "brightness(75%)",
      };
    }
    return {
      transition: BASE_TRANSITION,
      objectFit: "cover",
      overflow: "hidden",
      borderTopRightRadius: 32,
      borderTopLeftRadius: 32,
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
      flex: 1,
      ...stateCss,
    };
  },
);

export const CardStack = styled.div(cardSize, {
  display: "grid",
  gridTemplateColumns: "1fr",
  justifyItems: "center",
  position: "relative",
});

export const CardContent = styled.div<Omit<CardStyleProps, "$isDragging">>(
  typography({ variant: "heading2" }),
  layout.padding(7, 8),
  { transition: BASE_TRANSITION },
  ({ $state }) =>
    $state === "queued" || $state === "next" || $state === "pendingActive"
      ? { opacity: OPACITY_IN_QUEUE }
      : {},
);

interface CardWrapperProp extends Omit<CardStyleProps, "$isDragging"> {
  /**
   * number between 0 and 1
   */
  $edgeClosestFactor?: number;
}

export const CardWrapper = styled.div<CardWrapperProp>(
  cardSize,
  ({ $state, $edgeClosestFactor = 0.3 }) => {
    return {
      userSelect: "none",
      position: "absolute",
      zIndex: $state === "complete" ? -1 : 1,
      transition: `transform 20ms ease-in-out, filter 20ms ease-in-out`,
      transform:
        $state === "pendingComplete"
          ? `scale(${1 + $edgeClosestFactor * 0.2})`
          : "none",
      filter:
        $state === "pendingComplete" && $edgeClosestFactor
          ? `blur(${4 * $edgeClosestFactor}px)`
          : "none",
    };
  },
);

const TinderCard = styled(TinderCardBase)<CardStyleProps>(
  cardSize,
  layout.flexVertical,
  ({ theme, $state, $isDragging }) => {
    const transition = $isDragging ? DRAGGING_TRANSIITON : BASE_TRANSITION;
    const stateCss = {
      queued: {
        transform: "scale(0.8) translate(0px, 17%)",
        boxShadow: "none",
      },
      next: {
        transform: "scale(0.8) translate(0px, 17%)",
        boxShadow: theme.boxShadows.soft2,
      },
      pendingActive: {
        boxShadow: theme.boxShadows.soft2,
      },
      pendingComplete: {},
      complete: {},
      active: {},
    };

    return {
      willChange: "transform",
      transition: transition,
      backgroundColor: theme.colors.white,
      position: "absolute",
      borderRadius: 32,
      boxShadow: theme.boxShadows.soft,
      ...stateCss[$state],
    };
  },
);

// React Tinder Card defines this type in its index.d.ts, but does not
// expose it for use to use :shrug:
export interface ReactTinderCardAPI {
  swipe(dir?: Direction): Promise<void>;
  restoreCard(): Promise<void>;
}
export type Direction = "left" | "right" | "up" | "down";
interface ReactTinderCardProps {
  flickOnSwipe?: boolean;
  swipeThreshold?: number;
  onSwipe?: (direction: Direction) => void;
  onCardLeftScreen?: (direction: Direction) => void;
  onSwipeRequirementFulfilled?: (direction: Direction) => void;
  onSwipeRequirementUnfulfilled?: () => void;
  rotateMultiplier?: number;
  className?: string;
}

interface Props extends ReactTinderCardProps {
  state: CardState;
  imgSrc: string;
  label: string;
}

const Card = React.forwardRef<ReactTinderCardAPI, Props>(
  ({ imgSrc, label, state, className, ...rest }: Props, ref) => {
    const [isDragging, setIsDragging] = useState(false);
    const windowWidth = useWindowWidth();
    const [closeToWindowEdge, setCloseToWindowEdge] = useState<
      number | undefined
    >();

    const handleStartMove = () => {
      setIsDragging(true);
    };
    const handleEndMove = () => {
      setIsDragging(false);
    };
    const handleMove = (xCoord: number) => {
      if (isDragging) {
        const decimalFraction =
          Math.abs(xCoord - windowWidth / 2) / (windowWidth / 2);
        // round to 2 decimal place means less updates to the UI
        const roundedVal = Math.round(decimalFraction * 100) / 100;
        setCloseToWindowEdge(roundedVal);
      }
    };

    return (
      <CardWrapper
        className={className}
        $state={state}
        $edgeClosestFactor={closeToWindowEdge}
        onTouchStart={handleStartMove}
        onMouseDown={handleStartMove}
        onMouseUp={handleEndMove}
        onTouchEnd={handleEndMove}
        onMouseMove={(e) => {
          handleMove(e.clientX);
        }}
        onTouchMove={(e) => {
          handleMove(e.targetTouches[0].clientX);
        }}
      >
        <TinderCard
          ref={ref}
          $isDragging={isDragging}
          $state={state}
          rotateMultiplier={0}
          preventSwipe={["up", "down"]}
          swipeRequirementType="position"
          {...rest}
        >
          <CardImage
            $state={state}
            src={imgSrc}
            // Set `alt` to empty string, as this is mostly a decorative image
            alt=""
            role="presentation"
            // Only shimmer background for 2 interations. The image should be loaded by this point.
            css={shimmer(2, 2)}
          />
          <CardContent $state={state}>{label}</CardContent>
        </TinderCard>
      </CardWrapper>
    );
  },
);

export default Card;

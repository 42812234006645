import ReactGA from "react-ga";
import styled from "styled-components";

import { RefinanceAssessmentSummary } from "api/generated/data-contracts";
import { button, layout, typography } from "utils/style";

const Scenario = styled.div(({ theme }) => ({
  margin: "0 auto 75px auto",
  maxWidth: 500,

  [theme.media.above.sm]: {
    marginBottom: 120,
  },

  // Above tablet size we arrive back to a "phone width" when the
  // results "float" on the right side of the screen.
  [theme.media.above.lg]: {
    marginBottom: 75,
  },
}));

const ScenarioTitle = styled.div(
  typography({ variant: "heading3" }),
  ({ theme }) => ({
    maxWidth: 238,

    [theme.media.above.sm]: {
      maxWidth: "none",
    },
  }),
);

const ScenarioProperties = styled.ul(layout.paddingVertical(6));

const ScenarioProperty = styled.li(
  typography({ variant: "bodyLarge" }),
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing[4],
  }),
);

const Divider = styled.div({
  height: 2,
  margin: "0 auto",
  background:
    "linear-gradient(to right, rgba(28, 28, 30, 0), rgba(28, 28, 30, 0.1), rgba(28, 28, 30, 0))",
});

const Summary = styled.div(layout.paddingVertical(8), {
  textAlign: "center",
});

const SummaryValue = styled.div(
  typography({ variant: "heading2" }),
  ({ theme }) => ({
    marginBottom: theme.spacing[4],
  }),
);

const CallToAction = styled(ReactGA.OutboundLink)(
  button({ variant: "primary" }),
  layout.fullWidth,
  ({ theme }) => ({
    [theme.media.above.sm]: {
      width: "auto",
    },
  }),
);

interface Props {
  summary: RefinanceAssessmentSummary;
}

const RefinanceResults = ({ summary }: Props) => {
  return (
    <div css={{ paddingBottom: 1 }}>
      {summary.refinanceScenarios?.map((scenario, index) => (
        <Scenario key={index}>
          <ScenarioTitle>{scenario.title}</ScenarioTitle>
          <ScenarioProperties>
            {scenario.refinanceSummaryProperties?.map((property, index) => (
              <ScenarioProperty key={index}>
                <div>{property.propertyDescription}</div>
                <div css={typography({ variant: "heading5" })}>
                  {property.propertyValue}
                </div>
              </ScenarioProperty>
            ))}
          </ScenarioProperties>
          <Divider />
          <Summary>
            <SummaryValue>{scenario.summaryValueText}</SummaryValue>
            <div>{scenario.summaryValueDescription}</div>
          </Summary>
          {scenario.ctaUrl && (
            <div css={layout.flexCenter}>
              <CallToAction
                eventLabel={scenario.ctaUrl}
                to={scenario.ctaUrl}
                target="_blank"
              >
                {scenario.ctaText}
              </CallToAction>
            </div>
          )}
        </Scenario>
      ))}
    </div>
  );
};

export default RefinanceResults;

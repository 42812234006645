import { Answer, AssessmentDetails } from "api/generated/data-contracts";

const updateCachedAssessment = (
  assessment: AssessmentDetails,
  categoryKey: string,
  questionKey: string,
  answer: Answer,
) => {
  // this performs a deep copy
  let updatedAssessment: AssessmentDetails = JSON.parse(
    JSON.stringify(assessment),
  );
  const category = updatedAssessment?.categories?.find(
    (category) => category.key === categoryKey,
  );

  if (!category) {
    throw new Error(
      `Failed to find category key(${categoryKey}) when updating the cache.`,
    );
  }

  const step = category.steps?.find(
    (step) => step?.question?.key === questionKey,
  );

  if (!step) {
    throw new Error(
      `Failed to find question key(${questionKey}) when updating the cache.`,
    );
  }
  // find returns the step by reference, so by mutating this step
  // we are mutating the one in updatedAssessment.
  step.answer = answer;

  return updatedAssessment;
};

export default updateCachedAssessment;

import { useState } from "react";
import styled from "styled-components";

import Transition from "components/base/Transition";
import useTimeoutState from "hooks/useTimeoutState";
import { layout, typography } from "utils/style";
import { buildTransition } from "utils/style/transition";

// Does a fadeDown on enter, but fade opacity on leave.
const fadeDownAndFade = buildTransition({
  enter: {
    transition: "opacity 300ms ease, transform 350ms ease",
  },
  leave: {
    transition: "opacity 300ms ease, transform 350ms ease",
  },
  enterFrom: {
    opacity: 0,
    transform: `translate3d(0, -16px, 0)`,
  },
  enterTo: {
    opacity: 1,
    transform: "translate3d(0, 0, 0)",
  },
  leaveFrom: {
    opacity: 1,
    transform: "translate3d(0, 0, 0)",
  },
  leaveTo: {
    opacity: 0,
  },
});

interface StyleProps {
  $visibilityHidden: boolean;
}

const FeedbackMessage = styled.div<StyleProps>(
  typography({ variant: "body", color: "transparentText" }),
  layout.padding(2, 6),
  layout.spacedChildrenHorizontal(2),
  ({ theme, $visibilityHidden = false }) => ({
    borderRadius: 40,
    backgroundColor: theme.colors.offWhite,
    visibility: $visibilityHidden ? "hidden" : "inherit",
  }),
);

export type Props = {
  /**
   * Message to display.
   */
  message: string;
  show: boolean;
};

/**
 * Display a message with an enter and leave transition, timing out after 2 seconds. When the toast has timed out
 * it will have its visibility set to `hidden` and thus continue to occupy space previously taken.
 */
const FeedbackToast = ({ message, show }: Props) => {
  // reset boolean state after timeout period
  const showToast = useTimeoutState(2000, true, false);
  const [toastTransitionDone, setToastTransitionDone] = useState(false);
  return (
    //  This is a bit tricky. The desired effect here is to:
    //  - fadeDown when the component first appears
    //  - after 2 seconds, fade out, but instead of unmounting
    // the component, continue to render it with visibility hidden
    // so that we can maintain the space of the element and not shift
    // the undo button.
    // When you use `unmount = false` with HeadlessUI's `Transition`, it
    // sets the `display` to `none`, but we want `display: block; visibility: hidden`.
    // To do this, we set some extra state (toastTransitionDone) to hide the element
    // after the transition is finished and for display to be `block` using `!important`
    <Transition
      unmount={false}
      appear
      show={show && showToast}
      afterLeave={() => setToastTransitionDone(true)}
      css={[fadeDownAndFade, { display: "block !important" }]}
    >
      {show && (
        <FeedbackMessage
          aria-label={`Marked as ${message.toLowerCase()}. You can undo this.`}
          aria-live="polite"
          $visibilityHidden={toastTransitionDone}
        >
          Marked as {message.toLowerCase()}
        </FeedbackMessage>
      )}
    </Transition>
  );
};

export default FeedbackToast;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { QuizSelectorQuestion } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class QuizSelector<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags StartAssessmentApi
   * @name QuizSelectorList
   * @request GET:/quizSelector
   * @response `200` `QuizSelectorQuestion` Success
   */
  quizSelectorList = (params: RequestParams = {}) =>
    this.request<QuizSelectorQuestion, any>({
      path: `/quizSelector`,
      method: "GET",
      format: "json",
      ...params,
    });
}

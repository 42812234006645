import styled, { keyframes } from "styled-components";

import Button, { Props as ButtonProps } from "./Button";

const animation = keyframes`
  from {
    stroke-width: 4px;
    stroke-opacity: 1;
    transform: scale(0.5);
  }
  to {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1.75);
  }
`;

const Pulser = styled.svg`
  overflow: visible;
  position: absolute;
  & > circle {
    stroke: ${({ theme }) => theme.colors.primaryBlue};
    stroke-width: 2px;
    stroke-opacity: 1;
    fill: white;
    fill-opacity: 0;
    transform-origin: 50% 50%;
    animation-duration: 2s;
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation: ${animation} 2s infinite;
  }
`;

export interface Props extends Omit<ButtonProps, "variant"> {
  enabled?: boolean;
}

/**
 * A button that can animate the pulsing effect when `enabled` is set to true.
 * This button acts like the Button component with the variant set to `circle`.
 */
const PulseButton = ({ enabled, children, ...rest }: Props) => (
  <Button css={{ position: "relative" }} {...rest} variant="circle">
    {children}
    {enabled && (
      <Pulser height="100%" width="100%">
        <circle cx="50%" cy="50%" r="50%" />
      </Pulser>
    )}
  </Button>
);

export default PulseButton;

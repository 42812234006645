import styled from "styled-components";

import LogoImg from "assets/images/logo.png";
import NotFoundMd from "assets/images/NotFoundMd.png";
import NotFoundSm from "assets/images/NotFoundSm.png";
import { button, layout, typography } from "utils/style";

const Background = styled.div(layout.fullHeight, ({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${NotFoundSm})`,
  backgroundPosition: "bottom right",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",

  [theme.media.above.sm]: {
    backgroundImage: `url(${NotFoundMd})`,
    backgroundPosition: "bottom right",
    backgroundSize: "auto",
  },
}));

const Container = styled.div(layout.fullHeight, ({ theme }) => ({
  [theme.media.above.lg]: {
    maxWidth: 1030,
    width: "100%",
    margin: "0 auto",
  },
}));

const LogoWrapper = styled.div(({ theme }) => ({
  ...layout.padding(8, 8, 0)({ theme }),
  ...layout.margin(0, 0, 10, 0)({ theme }),

  [theme.media.above.sm]: {
    paddingLeft: 48,
    paddingTop: 80,
    marginBottom: 80,
  },
}));

const Logo = styled.img.attrs({ src: LogoImg })(({ theme }) => ({
  width: 28,
  height: 28,

  [theme.media.above.sm]: {
    width: 44,
    height: 44,
  },
}));

const Content = styled.div(({ theme }) => ({
  ...layout.padding(0, 5)({ theme }),
  ...layout.spacedChildrenVertical(3)({ theme }),

  [theme.media.above.sm]: {
    paddingLeft: 48,
    paddingRight: 48,
    ...layout.spacedChildrenVertical(6)({ theme }),
  },
}));

const Title = styled.h1(
  typography({ variant: { sm: "heading1", md: "display2" } }),
  ({ theme }) => ({
    maxWidth: 380,

    [theme.media.above.sm]: {
      maxWidth: 630,
    },
  }),
);

const Button = styled.a(button({ variant: "primary" }), ({ theme }) => ({
  height: 56,
  marginTop: theme.spacing[8],

  [theme.media.above.sm]: {
    marginTop: theme.spacing[10],
  },
}));

const NotFound = () => {
  return (
    <Background>
      <Container>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Content>
          <div css={typography({ variant: "heading3", color: "primaryBlue" })}>
            404
          </div>
          <Title>You got lost on your way home.</Title>
          <div css={[typography({ variant: "bodyLarge" }), { opacity: 0.8 }]}>
            It looks like this page doesn&apos;t exist.
          </div>
          <Button href="/">Get Back Home</Button>
        </Content>
      </Container>
    </Background>
  );
};

export default NotFound;

import { transparentize } from "polished";
import styled from "styled-components";

import { Check, Close } from "components/base/Icon";
import { Theme } from "theme/default";
import { layout, typography } from "utils/style";

const BORDER_RADIUS = 32;

export type ToastVariants = "success" | "error";

export interface Props {
  body: string;
  title: string;
  variant: ToastVariants;
}

type StyleProps = { variant: ToastVariants } & Theme;

const Base = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.midnightBlue,
  borderRadius: BORDER_RADIUS,
  color: theme.colors.white,
  lineHeight: "22px",
  minWidth: 280,
  width: "100%",
  wordBreak: "break-word",
  alignSelf: "flex-start",
  [theme.media.above.sm]: {
    minWidth: 520,
  },
}));

const IconContainer = styled.div<StyleProps>(({ theme, variant }) => ({
  alignItems: "center",
  backgroundColor:
    variant === "success"
      ? transparentize(0.7, theme.colors.green)
      : transparentize(0.7, theme.colors.red),
  borderBottomLeftRadius: BORDER_RADIUS,
  borderTopLeftRadius: BORDER_RADIUS,
  color: variant === "success" ? theme.colors.green : theme.colors.red,
  display: "flex",
  flexBasis: 56,
  flexShrink: 0,
  justifyContent: "center",
}));

const Title = styled.div(({ theme }) => ({
  marginBottom: theme.spacing[2],
}));

const Toast = ({ body, title, variant }: Props) => {
  return (
    <Base>
      <div css={[{ display: "flex" }]}>
        <IconContainer variant={variant}>
          {variant === "success" ? <Check /> : <Close />}
        </IconContainer>
        <div
          css={[
            layout.flexVertical,
            layout.paddingVertical(6),
            layout.paddingHorizontal(4),
          ]}
        >
          <Title
            css={[
              typography({
                color: variant === "success" ? "green" : "red",
                variant: "bodyLargeBold",
              }),
            ]}
          >
            {title}
          </Title>
          <div css={[typography({ color: "white", variant: "body" })]}>
            {body}
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Toast;

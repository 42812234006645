import { useMediaQuery } from "@react-hook/media-query";
import { useTheme } from "styled-components";

import { CategoryTheme } from "theme/default";

/**
 * Render the confetti svg for desktop and mobile, with the ability
 * to customize the confetti colours based on the category theme
 */
const Confetti = ({
  categoryTheme,
  className,
}: {
  categoryTheme: CategoryTheme;
  className?: string;
}) => {
  const theme = useTheme();
  // desktop has vertical confetti, while mobile + md/lg is horiztonal
  const isDesktop = useMediaQuery(
    `only screen and (min-width: ${theme.breakpoints.xl.min}px)`,
  );
  const primary = theme.colors.primaryBlue;
  const categoryPrimary = theme.categoryTheme[categoryTheme].primaryColor;

  return (
    <>
      {isDesktop ? (
        <svg
          className={className}
          css={{ position: "absolute", left: 0 }}
          width="117"
          height="440"
          viewBox="0 0 117 440"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="50.9883"
            y="109.435"
            width="10.29"
            height="16.7212"
            transform="rotate(-134.867 50.9883 109.435)"
            fill={categoryPrimary}
          />
          <rect
            x="-3.7002"
            y="290.645"
            width="10.29"
            height="16.7212"
            transform="rotate(-114.072 -3.7002 290.645)"
            fill={categoryPrimary}
          />
          <rect
            x="19.2905"
            y="434.936"
            width="10.29"
            height="16.7212"
            transform="rotate(-72.3694 19.2905 434.936)"
            fill={categoryPrimary}
          />
          <rect
            x="8.71436"
            y="254.769"
            width="10.29"
            height="16.7212"
            transform="rotate(-56.6784 8.71436 254.769)"
            fill={categoryPrimary}
          />
          <rect
            x="-6.43408"
            y="9.97754"
            width="10.29"
            height="16.7212"
            transform="rotate(-70.5742 -6.43408 9.97754)"
            fill={primary}
          />
          <rect
            x="79.6992"
            y="360.591"
            width="10.29"
            height="16.7212"
            transform="rotate(159.993 79.6992 360.591)"
            fill={primary}
          />
          <rect
            x="117"
            y="64.7544"
            width="10.29"
            height="16.7212"
            transform="rotate(159.993 117 64.7544)"
            fill={primary}
          />
          <rect
            x="34.6802"
            y="170.227"
            width="10.29"
            height="16.7212"
            transform="rotate(159.993 34.6802 170.227)"
            fill={primary}
          />
        </svg>
      ) : (
        <svg
          className={className}
          css={{ position: "absolute", top: 0 }}
          width="342"
          height="98"
          viewBox="0 0 342 98"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="257"
            y="46.6438"
            width="8"
            height="13"
            transform="rotate(-44.8673 257 46.6438)"
            fill={categoryPrimary}
          />
          <rect
            x="116.117"
            y="4.12549"
            width="8"
            height="13"
            transform="rotate(-24.0719 116.117 4.12549)"
            fill={categoryPrimary}
          />
          <rect
            x="3.9375"
            y="22"
            width="8"
            height="13"
            transform="rotate(17.6306 3.9375 22)"
            fill={categoryPrimary}
          />
          <rect
            x="144.009"
            y="13.7773"
            width="8"
            height="13"
            transform="rotate(33.3216 144.009 13.7773)"
            fill={categoryPrimary}
          />
          <rect
            x="334.324"
            y="2"
            width="8"
            height="13"
            transform="rotate(19.4258 334.324 2)"
            fill={primary}
          />
          <rect
            x="61.7371"
            y="68.9648"
            width="8"
            height="13"
            transform="rotate(-110.007 61.7371 68.9648)"
            fill={primary}
          />
          <rect
            x="291.737"
            y="97.9648"
            width="8"
            height="13"
            transform="rotate(-110.007 291.737 97.9648)"
            fill={primary}
          />
          <rect
            x="209.737"
            y="33.9648"
            width="8"
            height="13"
            transform="rotate(-110.007 209.737 33.9648)"
            fill={primary}
          />
        </svg>
      )}
    </>
  );
};

export default Confetti;
